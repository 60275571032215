import React, { useEffect, useState } from "react";
import { Row, Col, Table, Form, Button } from "react-bootstrap";
import { apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import moment from "moment";
import Layout from "./Layout";
import helpers from "../utils/helpers";
import obj, { headerData } from "../utils/constants";
import ReactPaginate from "react-paginate";
import { MyAccountLayout } from "./MyAccountLayout";
import NewObj from "../utils/helpers";
import { isEmpty, startCase } from "lodash";
const ProfitLossNew = () => {
  const [data, setData] = useState({});
  let currentDate = moment(new Date()).format("YYYY-MM-DD");
  let previousDate = moment(new Date()).subtract(1, "day").format("YYYY-MM-DD");
  const [filter, setFilter] = useState({
    fromPeriod: previousDate,
    toPeriod: currentDate,
    page: 1,
    limit: 50,
    eventType: "",
    eventId: "",
    betFaireType: "",
    marketId: "",
  });

  const [step, setStep] = useState(0);
  const getData = async () => {
    let api = apiPath.newprofitLoss;
    const { status, data } = await apiGet(api, filter);
    if (status == 200) {
      if (data?.success) {
        setData(data?.results);
        setStep(0);
      }
    }
  };
  const getEventTypeList = async (obj = filter) => {
    setData({});
    let api = apiPath.newprofitLossEvent;
    const { status, data } = await apiGet(api, obj);
    if (status == 200) {
      if (data?.success) {
        setData(data?.results);
        setStep(1);
      }
    }
  };
  const getEventIdList = async (obj = filter) => {
    setData({});
    let api = apiPath.matchBetId;
    const { status, data } = await apiGet(api, obj);
    if (status == 200) {
      if (data?.success) {
        setData(data?.results);
        setStep(2);
      }
    }
  };

  const getMarketTypeList = async (obj = filter) => {
    setData({});
    let api = apiPath.marketTypeList;
    const { status, data } = await apiGet(api, obj);
    if (status == 200) {
      if (data?.success) {
        setData(data?.results);
        setStep(3);
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <Layout>
      <MyAccountLayout
        type={""}
        heading={"Profit Loss"}
        isTable={step == 3 ? false : true}
        isFilter={step == 0 ? true : false}
        filter={filter}
        setFilter={setFilter}
        getData={getData}
      >
        <Table className="table-bordered">
          <thead>
            {step == 0 && (
              <tr>
                <th>Sport Name</th> <th>Profit / Loss</th> <th>Commission</th>{" "}
                <th>Total P/L</th>
              </tr>
            )}
            {step == 1 && (
              <tr>
                <th>Sport Name</th>
                <th>Event Name</th> <th>Profit / Loss</th>
                <th>Commission</th> <th>Total P/L</th>
              </tr>
            )}
            {step == 2 && (
              <tr>
                <th>Sport Name</th>
                <th>Event Name</th>
                <th>Market Name</th> <th>Result</th>
                <th>Profit / Loss</th>
                <th>Commission</th> <th>Settle Time</th>
              </tr>
            )}{" "}
            {step == 3 && (
              <tr>
                <th>Sport Name</th>
                <th>Event Name</th>
                <th>Market Name</th> <th>Selection Name</th>
                <th>Bet Type</th>
                <th>User Price</th> <th>Amount</th> <th>Profit / Loss</th>
                <th>Place Date</th>
                <th>Match Date</th>
              </tr>
            )}
          </thead>

          <tbody>
            {step == 0 ? (
              data?.length > 0 ? (
                data?.map((res) => {
                  return (
                    <tr>
                      <td
                        onClick={() => {
                          setFilter((prev) => {
                            return { ...prev, eventType: res?.eventType };
                          });
                          getEventTypeList({
                            ...filter,
                            eventType: res?.eventType,
                          });
                        }}
                        style={{
                          color: "#2789ce",
                          fontWeight: "700",
                          cursor: "pointer",
                        }}
                      >
                        {obj.betCheckObj[res?.eventType]}
                      </td>{" "}
                      <td
                        className={
                          Number(res?.pl) + Number(res?.commission) < 0
                            ? "text-danger"
                            : "text-success"
                        }
                      >
                        {Number(
                          Number(res?.pl) + Number(res?.commission)
                        ).toFixed(2)}
                      </td>{" "}
                      <td>{Number(res?.commission)}</td>{" "}
                      <td
                        className={res?.pl < 0 ? "text-danger" : "text-success"}
                      >
                        {Number(res?.pl).toFixed(2)}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <Empty />
              )
            ) : (
              ""
            )}

            {step == 1 ? (
              data?.data?.length > 0 ? (
                data?.data?.map((res) => {
                  return (
                    <tr>
                      <td>{obj.betCheckObj[res?.eventType]}</td>{" "}
                      <td
                        onClick={() => {
                          setFilter((prev) => {
                            return { ...prev, eventId: res?.eventId };
                          });
                          getEventIdList({ ...filter, eventId: res?.eventId });
                        }}
                        style={{
                          color: "#2789ce",
                          fontWeight: "700",
                          cursor: "pointer",
                        }}
                      >
                        {res?.matchName}
                      </td>{" "}
                      <td
                        className={
                          Number(res?.pl) + Number(res?.commission) < 0
                            ? "text-danger"
                            : "text-success"
                        }
                      >
                        {Number(
                          Number(res?.pl) + Number(res?.commission)
                        ).toFixed(2)}
                      </td>{" "}
                      <td>{Number(res?.commission)}</td>{" "}
                      <td
                        className={res?.pl < 0 ? "text-danger" : "text-success"}
                      >
                        {Number(res?.pl).toFixed(2)}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <Empty />
              )
            ) : (
              ""
            )}

            {step == 2 ? (
              data?.data?.length > 0 ? (
                data?.data?.map((res) => {
                  return (
                    <tr>
                      <td>{obj.betCheckObj[res?.eventType]}</td>{" "}
                      <td>{res?.matchName}</td>{" "}
                      <td
                        onClick={() => {
                          setFilter((prev) => {
                            return {
                              ...prev,
                              eventId: res?.eventId,
                              marketId: res?.marketId,
                              betFaireType: res?.betFaireType,
                              selectionId: ["sportBook", "fancy"].includes(
                                res?.betFaireType
                              )
                                ? res?.selectionId
                                : "",
                            };
                          });
                          getMarketTypeList({
                            ...filter,
                            eventId: res?.eventId,
                            marketId: res?.marketId,
                            betFaireType: res?.betFaireType,
                            selectionId: ["sportBook", "fancy"].includes(
                              res?.betFaireType
                            )
                              ? res?.selectionId
                              : "",
                          });
                        }}
                        style={{
                          color: "#2789ce",
                          fontWeight: "700",
                          cursor: "pointer",
                        }}
                      >
                        {startCase(res?.betFaireType)}
                      </td>{" "}
                      <td>-</td> <td>-</td>
                      <td>-</td>
                      <td>{NewObj.dateFormat(res?.updatedAt)}</td>
                    </tr>
                  );
                })
              ) : (
                <Empty />
              )
            ) : (
              ""
            )}
            {step == 3 ? (
              data?.length > 0 ? (
                data?.map((res) => {
                  return (
                    <tr
                      style={{
                        backgroundColor:
                          res?.betType == "lay" || res?.type == "No"
                            ? "#faa9ba"
                            : "#72bbef",
                      }}
                    >
                      <td>{obj.betCheckObj[res?.eventType]}</td>{" "}
                      <td>{res?.matchName}</td>{" "}
                      <td>{startCase(res?.market) || res?.fancyName}</td>{" "}
                      <td>{res?.teamName || res?.runnerName}</td>{" "}
                      <td>{res?.betType || res?.type}</td>
                      <td>
                        {" "}
                        {!isEmpty(res?.betType)
                          ? res?.bhav
                          : `${res?.betRun}/${res?.bhav}`}
                      </td>
                      <td>{res?.amount}</td>
                      <td>
                        {res?.type == "No" ? (
                          res?.decisionRun < res?.betRun ? (
                            <span className="text-success">
                              {NewObj.truncateDecimals(res?.profitAmount, 2)}
                            </span>
                          ) : (
                            <span className="text-danger">
                              ({NewObj.truncateDecimals(res?.loseAmount, 2)})
                            </span>
                          )
                        ) : res?.type == "Yes" ? (
                          res?.decisionRun >= res?.betRun ? (
                            <span className="text-success">
                              {NewObj.truncateDecimals(res?.profitAmount, 2)}
                            </span>
                          ) : (
                            <span className="text-danger">
                              ({NewObj.truncateDecimals(res?.loseAmount, 2)})
                            </span>
                          )
                        ) : (res?.teamSelectionWin == res?.selectionId &&
                            res?.betType == "back") ||
                          (res?.teamSelectionWin != res?.selectionId &&
                            res?.betType == "lay") ? (
                          <span id="txLiability" className="text-success">
                            {NewObj.truncateDecimals(res?.profitAmount, 2)}
                          </span>
                        ) : (
                          <span className="text-danger">
                            ({NewObj.truncateDecimals(res?.loseAmount, 2)})
                          </span>
                        )}
                      </td>
                      <td>{NewObj.dateFormat(res?.timeInserted)}</td>
                      <td>{NewObj.dateFormat(res?.updatedAt)}</td>
                    </tr>
                  );
                })
              ) : (
                <Empty />
              )
            ) : (
              ""
            )}
          </tbody>
        </Table>
      </MyAccountLayout>
    </Layout>
  );
};

export default ProfitLossNew;

const Empty = () => {
  return (
    <tr>
      <td colSpan={9}>
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "8px",
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          No Data!
        </span>
      </td>
    </tr>
  );
};
