import { startCase } from "lodash";
import React from "react";
import { Table } from "react-bootstrap";
import obj from "../../utils/helpers";

const BetListInner = ({ data, type }) => {
  return (
    <Table className="mb-0 my-bets">
      <thead>
        <tr>
          <th id="oddsHeader" className="col-bet">
            {" "}
            {startCase(type)} (Bet for)
          </th>
          <th id="oddsHeader" className="col-odd">
            {" "}
            Odds
          </th>
          <th id="runsHeader" className="col-odd">
            {" "}
            Stake
          </th>
          <th className="col-stake">
            {" "}
            {type == "back" ? "Profit" : "Libility"}
          </th>
        </tr>
      </thead>
      <tbody>
        {data?.map((res, index) => {
          return (
            <>
              <tr
                style={{ borderBottom: "0" }}
                className={`${type}-slip ${type}-slip-info`}
              >
                <td style={{ fontWeight: "500", fontSize: "10px" }} colSpan={5}>
                  Ref : {obj?.dateFormat(res?.timeInserted)}
                </td>
              </tr>
              <tr key={index + 1} className={`${res?.betTypeNew == 'sport' ? 'sport' : type}-slip`}>
                <td className="d-flex align-items-center">
                  <button
                    style={{
                      border: "none",
                      background:res?.betTypeNew == 'sport' ? '#16a660' : type == "back" ? "#72bbef" : "#dfa3b3",
                      borderRadius: "5px",
                      marginRight: "8px",
                      padding: "3px 7px",
                      fontSize: "11px",
                    }}
                  >
                    {startCase(type)}
                  </button>
                  <div className="d-flex align-items-start flex-column">
                    <strong style={{ fontSize: "12px" }}>
                      {res.teamName || res?.runnerName || res?.fancyName}
                    </strong>{" "}
                    <small style={{ fontSize: "10px" }}>
                      {res?.market || res?.fancyName || "BookMaker"}
                    </small>
                  </div>
                </td>
                <td>
                  {res?.betRun > 0 ? `${res?.betRun} / ${res.bhav}` : res.bhav}{" "}
                </td>
                <td>{res.amount}</td>
                <td>{res.profitAmount}</td>
              </tr>{" "}
            </>
          );
        })}
      </tbody>
    </Table>
  );
};

export default BetListInner;
