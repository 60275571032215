import React, { useState, useEffect } from "react";
import { apiGet, apiPost } from "../utils/apiFetch";
import helpers from "../utils/helpers";
import apiPath from "../utils/apiPath";
import { Link, useNavigate, useParams } from "react-router-dom";
import OddsListing from "../components/OddsListing";
import { useContext } from "react";
import BetSlipContext from "../context/BetSlipContext";
import AuthContext from "../context/AuthContext";
import Loader from "../assets/gif/loader.gif";
import moment from "moment";
import { isEmpty } from "lodash";
import Layout from "./Layout";
import { Button } from "react-bootstrap";
import MatchListSingle from "../components/MatchListSingle";
import CasinoSection from "./CasinoSection";

const HomeNew = () => {
  const [tab, setTab] = useState("cricket");
  let { user, inPlayScore, loginUserDetailData } = useContext(AuthContext);
  const [matches, setMatches] = useState([]);

  let userId = "";
  if (user?.user?._id) {
    userId = "&userId=" + user?.user?._id;
  }
  const getMatches = async (type) => {
    try {
      const { status, data: response_users } = await apiGet(
        `${apiPath.matchList + `?gameType=${type}` + userId}`
      );
      if (status === 200) {
        if (response_users.success) {
          inPlayScore?.length > 0
            ? setMatches(
                response_users?.results?.map((res) => {
                  let temp = inPlayScore.find(
                    (item) => item?.eventId == res?.eventId
                  );
                  return {
                    ...res,
                    score: temp?.eventId == res?.eventId ? temp?.score : "",
                    timeElapsed:
                      temp?.eventId == res?.eventId ? temp?.timeElapsed : "",
                  };
                })
              )
            : setMatches(response_users.results);

          if (response_users?.results?.length > 0) {
            getOdds(
              response_users?.results?.map((res) => {
                return res?.marketId;
              })
            );
          }
        }
      }
    } catch (error) {}
  };

  const handelAddMultiMarket = async (eventId) => {
    const result = {
      eventId: eventId,
    };

    if (user) {
      const { status, data: response_users } = await apiPost(
        apiPath.multiMarket,
        result
      );
      if (status === 200) {
        if (response_users.success) {
          getMatches(tab);
        }
      }
    }
  };

  useEffect(() => {
    if (tab !== "") {
      setMatches([]);
      getMatches(tab);
    }
  }, [tab]);

  useEffect(() => {
    if (inPlayScore?.length > 0) {
      setMatches((current) => {
        return current.map((res) => {
          let temp = inPlayScore.find((item) => item?.eventId == res?.eventId);
          return {
            ...res,
            score: temp?.eventId == res?.eventId ? temp?.score : "",
            timeElapsed: temp?.eventId == res?.eventId ? temp?.timeElapsed : "",
          };
        });
      });
    }
  }, [inPlayScore]);

  const getOdds = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getMatchOdds + `?marketId=${id?.join(",")}&multi=true`
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results?.length > 0) {
          setMatches((current) =>
            current.map((obj) => {
              let newObj = response_users?.results?.find((res) => {
                return res?.mi == obj?.marketId;
              });
              if (obj?.marketId == newObj?.mi) {
                return { ...obj, odds: newObj };
              }
              return obj;
            })
          );
        }
      }
    }
  };

  return (
    <Layout news={true}>
      <div className="col-center games_center_block sports">
        <div className="game_banner">
          <img
            src="../assets/images/kv_tennis.jpg"
            className="w-100"
            alt="No_Image"
          />
        </div>

        <div className="game_table_main_wrapper">
          <div className="game_list_filter_shape">
            <h4 style={{ fontWeight: "600" }}>Highlights</h4>
            <div>
              <Button
                onClick={() => setTab("cricket")}
                className={tab == "cricket" && "active"}
              >
                Cricket
              </Button>
              <Button
                className={tab == "soccer" && "active"}
                onClick={() => setTab("soccer")}
              >
                Soccer
              </Button>
              <Button
                className={tab == "tennis" && "active"}
                onClick={() => setTab("tennis")}
              >
                Tennis
              </Button>
            </div>
          </div>

          {matches.length > 0 ? (
            <div className="game_table_main_wrapper">
              <div className="game_table_inner">
                <ul className="slip-head d-flex">
                  <li className="col-game"></li>
                  <li className="col-matched text-end"></li>
                  <li className="col-visit text-center">1</li>
                  <li className="col-draw text-center">x</li>
                  <li className="col-home text-center">2</li>
                  <li className="col-info"></li>
                </ul>
                <div className="game-list">
                  {matches &&
                    matches.length > 0 &&
                    matches.map((item, index) => {
                      let obj = moment.duration(
                        moment(item?.eventDateTime).diff(moment(new Date()))
                      )._data;
                      let scoreNew = {};
                      return (
                        <MatchListSingle
                          item={item}
                          scoreNew={scoreNew}
                          obj={obj}
                          loginUserDetailData={loginUserDetailData}
                          handelAddMultiMarket={handelAddMultiMarket}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          ) : matches.length <= 0 ? (
            <div className="d-flex align-item-center p-2">No Match Found</div>
          ) : (
            <span className="d-flex justify-content-center align-item-center mt-2 pb-3">
              <img src={Loader} alt="No_Image" />
            </span>
          )}
        </div>

        <CasinoSection />
      </div>
    </Layout>
  );
};

export default HomeNew;
