import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import Sidebar from "../components/Sidebar";
import { apiGet, apiPost } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import { isEmpty, pick } from "lodash";
import { useForm } from "react-hook-form";
import { toast } from "wc-toast";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../assets/gif/loader.gif";
import AuthContext from "../context/AuthContext";
import Layout from "./Layout";
import { BsEye, BsEyeFill } from "react-icons/bs";
import { FaEye } from "react-icons/fa";
const Myprofile = () => {
  let { loginUserDetailData,user } = useContext(AuthContext);
  const parmas = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [password_same, set_password_same] = useState(true);
  const [isLoader, setLoader] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const changePasswordToggle = () => setChangePassword(!changePassword);
  const [rolling, setRolling] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({});

  const onSubmit = async (request) => {
    setLoader(true);
    set_password_same(true);

    if (request.newPassword !== request.confirmPassword) {
      setLoader(false);
      set_password_same(false);
    } else {
      const { status, data: response_users } = await apiPost(
        apiPath.changePassword,
        pick(request, ["oldPassword", "newPassword"])
      );
      if (status === 200) {
        if (response_users.success) {
          setLoader(false);
          setChangePassword();
          toast.success(response_users.message);
          reset();
        } else {
          setLoader(false);
          toast.error(response_users.message);
        }
      }
    }
  };

  return (
    <Layout>
      <div className="my_account_table_wrap h-100">
        <h5>Account Detail</h5>
        <div className="table-main-wrapper">
          <Table className="table_caption_style profile_table">
            <tbody>
              <tr>
                <td className="text-start" width="25%">
                  Name
                </td>
                <td className="text-start" colSpan="3">
                {user?.user?.username}
                </td>
              </tr>
              <tr>
                <td className="text-start" width="25%">
                  Commission
                </td>
                <td className="text-start" colSpan="3">
                  0
                </td>
              </tr>

              <tr>
                <td className="text-start" width="25%">
                  Rolling Commission
                </td>
                <td className="text-start" colSpan="3">
                  <FaEye onClick={() => setRolling(true)} />
                </td>
              </tr>

              <tr>
                <td className="text-start" width="25%">
                  Exposure Limi
                </td>
                <td className="text-start" colSpan="3">
                  0
                </td>
              </tr>
              <tr>
                <td className="text-start" width="25%">
                  Password
                </td>
                <td className="text-start">
                  ************{" "}
                  <Link
                    to="#"
                    style={{ marginLeft: "5px", fontWeight: "600" }}
                    className="text-decoration-none"
                    onClick={changePasswordToggle}
                  >
                    Edit <i className="fas fa-pen text-white ps-1"></i>
                  </Link>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>

      <Modal
        show={changePassword}
        onHide={changePasswordToggle}
        // className="change-status-modal p-0"
      >
        <Modal.Header
          className="p-2"
          style={{
            background: "linear-gradient(-180deg, #2E4B5E 0%, #243A48 82%)",
          }}
        >
          <Modal.Title className="h6 text-white">Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="test-status p-1 border-0">
            <Form
              className="change-password-sec"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Form.Group className="d-flex  mb-2">
                <Form.Label> Password</Form.Label>
                <div className="common-form-sec w-100">
                  <Form.Control
                    type="password"
                    placeholder="Enter New Password"
                    className={errors.newPassword ? " is-invalid " : ""}
                    {...register("newPassword", {
                      required: "Please enter new password",
                    })}
                  />
                  {errors.newPassword && errors.newPassword.message && (
                    <label className="invalid-feedback text-left">
                      {errors.newPassword.message}
                    </label>
                  )}
                </div>
              </Form.Group>
              <Form.Group className="d-flex  mb-2">
                <Form.Label>New Password Confirm</Form.Label>
                <div className="common-form-sec w-100">
                  <Form.Control
                    type="password"
                    placeholder="Confirm Password"
                    className={
                      errors.confirmPassword || password_same === false
                        ? " is-invalid "
                        : ""
                    }
                    {...register("confirmPassword", {
                      required: "Please enter confirm password",
                    })}
                  />
                  {errors.confirmPassword && errors.confirmPassword.message && (
                    <label className="invalid-feedback text-left">
                      {errors.confirmPassword.message}
                    </label>
                  )}
                  {password_same === false && errors.confirmPassword !== "" && (
                    <label className="invalid-feedback text-left">
                      Password does not match.
                    </label>
                  )}
                </div>
              </Form.Group>

              <Form.Group className="d-flex  mb-2">
                <Form.Label>Your Password</Form.Label>
                <div className="common-form-sec w-100">
                  <Form.Control
                    type="password"
                    placeholder="Enter Old Password"
                    className={errors.oldPassword ? " is-invalid " : ""}
                    {...register("oldPassword", {
                      required: "Please enter password",
                    })}
                  />
                  {errors.oldPassword && errors.oldPassword.message && (
                    <label className="invalid-feedback text-left">
                      {errors.oldPassword.message}
                    </label>
                  )}
                </div>
              </Form.Group>

              <div className="text-center mt-4">
                <Button type="submit" className="yellow-btn">
                  {isLoader ? "Loading..." : "Change"}
                </Button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={rolling}
        onHide={() => {
          setRolling(false);
        }}
      >
        <Modal.Header
          style={{
            background: "linear-gradient(-180deg, #2E4B5E 0%, #243A48 82%)",
          }}
          className="model-header"
        >
          <Modal.Title className="h6 text-white">
            Rolling Commission
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <tbody>
              <tr>
                <td className="text-starts">Fancy</td>
                <td>0</td>
              </tr>
              <tr>
                <td className="text-starts">Matka</td>
                <td>0</td>
              </tr>
              <tr>
                <td className="text-starts">Casino</td>
                <td>0</td>
              </tr>
              <tr>
                <td className="text-starts">Binary</td>
                <td>0</td>
              </tr>
              <tr>
                <td className="text-starts">Sportbook</td>
                <td>0</td>
              </tr>
              <tr>
                <td className="text-starts">Bookmaker</td>
                <td>0</td>
              </tr>{" "}
              <tr>
                <td className="text-starts">VirtualSport</td>
                <td>0</td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default Myprofile;
