import React, { useState, useEffect ,useContext} from "react";
import Trasnsparent from "../assets/images/transparent-login.gif";
import { casinoArray } from "../utils/constants";
import Layout from "./Layout";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
const LiveCasino = () => {
  const [casinoTab, setCasinoTab] = useState("");
  const [searchBox, setSearchBox] = useState(false);
  const {user,launchCasino, } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    setSearchBox(false);
    setCasinoTab("");
  }, []);
  return (
    <Layout>
      <div className="sports">
        <div className="position-relative">
          <ul class="nav nav-tabs-casino game-nav-bar menu-list-casino mt-3">
            <li class="nav-item casinoname-item text-center aaa ng-star-inserted">
              <a
                onClick={() => setCasinoTab("")}
                class={casinoTab == "" ? "nav-link active" : "nav-link"}
              >
                <div>All</div>
              </a>
            </li>
            <li class="nav-item casinoname-item text-center aaa ng-star-inserted">
              <a
                onClick={() => setCasinoTab("ourcasino")}
                class={casinoTab == "ourcasino" ? "nav-link active" : "nav-link"}
              >
                <div>our Casino</div>
              </a>
            </li>
            <li class="nav-item casinoname-item text-center aaa ng-star-inserted">
              <a
                onClick={() => setCasinoTab("international")}
                class={casinoTab == "international" ? "nav-link active" : "nav-link"}
              >
                <div>international casino</div>
              </a>
            </li>
            <li class="nav-item casinoname-item text-center aaa ng-star-inserted">
              <a
                onClick={() => setCasinoTab("virtual")}
                class={casinoTab == "virtual" ? "nav-link active" : "nav-link"}
              >
                <div>Virtual casino</div>
              </a>
            </li>
          </ul>
          <div>
            <div class="searchBox">
              <a onClick={() => setSearchBox(!searchBox)}>
                <i
                  class={`${
                    searchBox
                      ? "fas fa-times ng-star-inserted"
                      : "fas fa-search ng-star-inserted"
                  }`}
                ></i>
              </a>
            </div>
            {searchBox && (
              <div class="searchInput hideSearchBar">
                <input
                  id="CasinolobbySearch"
                  name="search"
                  placeholder="Search..."
                  type="text"
                  class="form-control ng-untouched ng-pristine ng-valid"
                />
              </div>
            )}
          </div>
        </div>
        <div className="main-con">
          <div className="row home">
            {casinoArray?.map((res) => {
              return casinoTab == "" ? (
                <div class="col col-4 col-md-3 col-sm-3 col-xl-3 pointer 11 ng-star-inserted" onClick={() => {
                                  if (!isEmpty(user)) {
                                    launchCasino({
                                      platForm: res?.platForm,
                                      gameType: res?.gameType,
                                      casinoType: res?.casinoType,
                                      isLobbyTrue: res?.isLobbyTrue,
                                      table:res?.table
                                    });
                                  } else {
                                    navigate("/login");
                                  }
                                }}>
                  <div style={{ position: "relative" }}>
                    <div class="clip"></div>
                    <div class="clip2">
                      <img
                        style={{
                          height: "110px",
                          width: "100%",
                          objectFit: "fill",
                        }}
                        src={res?.img}
                        class="casino_sprite casino-evolution"
                      />
                    </div>
                  </div>
                  <div class="casinoTitle">{res?.name}</div>
                </div>
              ) : (
                casinoTab == res?.live && (
                  <div class="col col-4 col-md-3 col-sm-3 col-xl-3 pointer 11 ng-star-inserted" onClick={() => {
                                    if (!isEmpty(user)) {
                                      launchCasino({
                                        platForm: res?.platForm,
                                        gameType: res?.gameType,
                                        casinoType: res?.casinoType,
                                        isLobbyTrue: res?.isLobbyTrue,
                                        table:res?.table
                                      });
                                    } else {
                                      navigate("/login");
                                    }
                                  }}> 
                    <div style={{ position: "relative" }}>
                      <div class="clip"></div>
                      <div class="clip2">
                        <img
                          style={{
                            height: "110px",
                            width: "100%",
                            objectFit: "fill",
                          }}
                          src={res?.img}
                          class="casino_sprite casino-evolution"
                        />
                      </div>
                    </div>
                    <div class="casinoTitle">{res?.name}</div>
                  </div>
                )
              );
            })}
          </div>
        </div>{" "}
      </div>
    </Layout>
  );
};

export default LiveCasino;
