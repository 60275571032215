import React from "react";
import Layout from "./Layout";

const Tips = () => {
  return (
    <Layout news={true}>
      <div className="bg-white" style={{borderRadius:"7px"}}>
        <div className="card-header newsHeader pt-3">NEWS</div>
        <div className="">
          <div className="mainNews p-2">
            <div className="d-flex">
              <div class="width-img">
                <img
                  class="NewsImg"
                  src="http://market.mgmopr.com/api/trader/tips/images/9d0f900a-1837-46c8-a85c-474779f8b0c7.jpg"
                />
              </div>
              <div _ngcontent-ng-c1037383007="" class="width-content">
                <div>
                  <h2 _ngcontent-ng-c1037383007="" class="news-heading">
                    La Liga: Barcelona continues perfect run with 1-0 win over
                    Getafe
                  </h2>
                  <p class="news-description mt-1">
                    {" "}
                    A first-half strike by top-scorer Robert Lewandowski earned
                    a below-strength Barcelona a 1-0 LaLiga win over lowly
                    Getafe on Wednesday to maintain its 100% record after seven
                    games. Barcelona, who rested Pedri and was without the
                    injured Marc-Andre ter Stegen, Dani Olmo, Gavi, Frenkie de
                    Jong, Ronald Araujo, Fermin Lopez and Andreas Christensen,
                    went ahead through Lewandowski’s close-range strike in the
                    19th minute after a poor mistake by Getafe goalkeeper David
                    Soria. Soria failed to hold on to a Jules Kounde cross and
                    Lewandowski, who was lurking inside the box, pounced on the
                    ball to fire home for his seventh goal of the season. ALSO
                    READ: Man City confirms Rodri ligament injury in right knee,
                    gives no time frame on absence The host dominated
                    proceedings with almost 80% of possession and 16 goal
                    attempts, but wasted numerous opportunities to win by a
                    wider margin. Soria made fine saves to deny efforts by
                    Lamine Yamal, Lewandowski and three from Raphinha, who also
                    missed a sitter in the 82nd minute, badly misfiring a header
                    from a Kounde cross. Barca almost paid a heavy price for its
                    missed chances as the visitor could have scored an equaliser
                    in the dying moments. Getafe fullback Juan Iglesias found
                    Borja Mayoral unmarked inside the box from a quick counter
                    attack, but the striker sliced his first-time effort in the
                    95th minute. Barca is top of the standings on 21 points,
                    four ahead second-placed Real Madrid. Getafe is second from
                    bottom on four points. “When you dominate a game but go only
                    one goal ahead, the opponent sees a possibility of making a
                    run for a better result and often that leads them to push
                    you harder in the last minutes,” Kounde told Movistar Plus.
                    “But we played a good, controlled game and we could have
                    scored more goals. Sometimes it happens, you can’t always
                    win with a big goal difference. Getafe are always a
                    difficult team to handle. It may have cost us more than
                    usual, but we had the chances to win the game more
                    comfortably.” Barca will now visit seventh-placed Osasuna on
                    Saturday before hosting Young Boys in the Champions League
                    next week, when it will look to rebound following a 2-1 loss
                    in its opening match at Monaco.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>{" "}
          <div className="mainNews p-2">
            <div className="d-flex">
              <div class="width-img">
                <img
                  class="NewsImg"
                  src="http://market.mgmopr.com/api/trader/tips/images/9d0f900a-1837-46c8-a85c-474779f8b0c7.jpg"
                />
              </div>
              <div _ngcontent-ng-c1037383007="" class="width-content">
                <div>
                  <h2 _ngcontent-ng-c1037383007="" class="news-heading">
                    La Liga: Barcelona continues perfect run with 1-0 win over
                    Getafe
                  </h2>
                  <p class="news-description mt-1">
                    {" "}
                    A first-half strike by top-scorer Robert Lewandowski earned
                    a below-strength Barcelona a 1-0 LaLiga win over lowly
                    Getafe on Wednesday to maintain its 100% record after seven
                    games. Barcelona, who rested Pedri and was without the
                    injured Marc-Andre ter Stegen, Dani Olmo, Gavi, Frenkie de
                    Jong, Ronald Araujo, Fermin Lopez and Andreas Christensen,
                    went ahead through Lewandowski’s close-range strike in the
                    19th minute after a poor mistake by Getafe goalkeeper David
                    Soria. Soria failed to hold on to a Jules Kounde cross and
                    Lewandowski, who was lurking inside the box, pounced on the
                    ball to fire home for his seventh goal of the season. ALSO
                    READ: Man City confirms Rodri ligament injury in right knee,
                    gives no time frame on absence The host dominated
                    proceedings with almost 80% of possession and 16 goal
                    attempts, but wasted numerous opportunities to win by a
                    wider margin. Soria made fine saves to deny efforts by
                    Lamine Yamal, Lewandowski and three from Raphinha, who also
                    missed a sitter in the 82nd minute, badly misfiring a header
                    from a Kounde cross. Barca almost paid a heavy price for its
                    missed chances as the visitor could have scored an equaliser
                    in the dying moments. Getafe fullback Juan Iglesias found
                    Borja Mayoral unmarked inside the box from a quick counter
                    attack, but the striker sliced his first-time effort in the
                    95th minute. Barca is top of the standings on 21 points,
                    four ahead second-placed Real Madrid. Getafe is second from
                    bottom on four points. “When you dominate a game but go only
                    one goal ahead, the opponent sees a possibility of making a
                    run for a better result and often that leads them to push
                    you harder in the last minutes,” Kounde told Movistar Plus.
                    “But we played a good, controlled game and we could have
                    scored more goals. Sometimes it happens, you can’t always
                    win with a big goal difference. Getafe are always a
                    difficult team to handle. It may have cost us more than
                    usual, but we had the chances to win the game more
                    comfortably.” Barca will now visit seventh-placed Osasuna on
                    Saturday before hosting Young Boys in the Champions League
                    next week, when it will look to rebound following a 2-1 loss
                    in its opening match at Monaco.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Tips;
