import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import OddsListing from "../components/OddsListing";
import { Accordion } from "react-bootstrap";
import { isEmpty } from "lodash";
import MatchListSingle from "../components/MatchListSingle";

const InplayInner = ({
  getInPlaySports,
  loginUserDetailData,
  handelAddMultiMarket,
  score,
}) => {
  console.log(getInPlaySports,'getInPlaySports')
  return (
    <div className="theme_accordian">
      <Accordion defaultActiveKey={["Cricket", "Tennis", "Soccer"]}>
        <Accordion.Item eventKey="Cricket">
          <Accordion.Header>Cricket</Accordion.Header>
          <Accordion.Body>
            <div className="game_table_main_wrapper">
              <div className="game_table_inner">
                <ul className="slip-head d-flex">
                  <li className="col-game"></li>
                  <li className="col-matched text-end"></li>
                  <li className="col-visit text-center">1</li>
                  <li className="col-draw text-center">x</li>
                  <li className="col-home text-center">2</li>
                  <li className="col-info"></li>
                </ul>

                <div className="game-list">
                  {getInPlaySports?.length > 0 ? (
                    getInPlaySports.map((matchData, matchKey) => {
                      let obj = moment.duration(
                        moment(matchData?.eventDateTime).diff(
                          moment(new Date())
                        )
                      )._data;
                      return (
                        matchData.eventType == "4" && (
                          <MatchListSingle
                            item={matchData}
                            scoreNew={score}
                            obj={obj}
                            loginUserDetailData={loginUserDetailData}
                            handelAddMultiMarket={handelAddMultiMarket}
                            type={"cricket"}
                            time={'inplay'}
                          />
                        )
                      );
                    })
                  ) : (
                    <p className="d-flex align-items-center">No Record Found</p>
                  )}
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="Tennis">
          <Accordion.Header>Tennis</Accordion.Header>
          <Accordion.Body>
            <div className="game_table_main_wrapper">
              <div className="game_table_inner">
                <ul className="slip-head d-flex">
                  <li
                    className="col-game"
                    style={{ width: "calc(63.8% - 41px)" }}
                  ></li>
                  <li className="col-matched text-end"></li>
                  <li className="col-visit text-center">1</li>
                  <li className="col-draw text-center"></li>
                  <li className="col-home text-center">2</li>
                  <li className="col-info"></li>
                </ul>

                <div className="game-list">
                  {getInPlaySports?.length > 0 ? (
                    getInPlaySports.map((matchData, matchKey) => {
                      let obj = moment.duration(
                        moment(matchData?.eventDateTime).diff(
                          moment(new Date())
                        )
                      )._data;
                      return (
                        matchData.eventType == "2" && (
                            <MatchListSingle
                            item={matchData}
                            scoreNew={score}
                            obj={obj}
                            loginUserDetailData={loginUserDetailData}
                            handelAddMultiMarket={handelAddMultiMarket}
                            type={"tennis"}
                            time={'inplay'}
                          />
                        )
                      );
                    })
                  ) : (
                    <p className="d-flex align-items-center">No Record Found</p>
                  )}
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="Soccer">
          <Accordion.Header>Soccer</Accordion.Header>
          <Accordion.Body>
            <div className="game_table_main_wrapper">
              <div className="game_table_inner">
                <ul className="slip-head d-flex">
                  <li className="col-game"></li>

                  <li className="col-matched text-end"></li>

                  <li className="col-visit text-center">1</li>
                  <li className="col-draw text-center">x</li>
                  <li className="col-home text-center">2</li>
                  <li className="col-info"></li>
                </ul>

                <div className="game-list">
                  {getInPlaySports?.length > 0 ? (
                    getInPlaySports.map((matchData, matchKey) => {
                      let obj = moment.duration(
                        moment(matchData?.eventDateTime).diff(
                          moment(new Date())
                        )
                      )._data;
                      return (
                        matchData.eventType == "1" && (
                            <MatchListSingle
                            item={matchData}
                            scoreNew={score}
                            obj={obj}
                            loginUserDetailData={loginUserDetailData}
                            handelAddMultiMarket={handelAddMultiMarket}
                            type={"soccer"}
                            time={'inplay'}
                          />
                        )
                      );
                    })
                  ) : (
                    <p className="d-flex align-items-center">No Record Found</p>
                  )}
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default InplayInner;
