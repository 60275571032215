import React, { useContext } from "react";
import helpers from "../../utils/helpers";
import { inRange, isEmpty } from "lodash";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import { FiMinus, FiPlus } from "react-icons/fi";
const RenderBetSlip = ({
  data,
  onCancel,
  type,
  handelAcceptAnyOddsCheck,
  handelSetPrice,
  handelPlace,
  details,
  betLoader,
}) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const isInputNumber = helpers.isInputNumber;
  let condition =
    inRange(
      data?.activePrice,
      data?.rangeObject.minAmount,
      data?.rangeObject.maxAmount
    ) &&
    details?.sport_setting?.status == "active" &&
    data?.rt > 0;
  return (
    <tr>
      {" "}
      <td colSpan="7" className="quick-batting-wrapper p-0">
        <div
          className="d-flex align-items-center batting-flex "
          style={
            data?.betType == "back"
              ? { backgroundColor: "#beddf4" }
              : data?.betType == "lay"
              ? { backgroundColor: "#F3DCE2" }
              : {}
          }
        >
          <div className="w-100 btn-slip">
            <button
              className="bookmakerCancelBtn w-100 fw-5"
              onClick={() => onCancel(type)}
            >
              Cancel
            </button>
          </div>

          <div className="up_down_rate  btn-slip mx-1 quick-input w-100">
            {" "}
            <button>
              <FiMinus size={20} color="#1f72ac" />
            </button>
            <input
              type="number"
              value={data?.rt}
              // onChange={(e) => handelSetPrice(e.target.value, data)}
              max={1500000}
              disabled
            />
            <button className="plus">
              <FiPlus size={20} color="#1f72ac" />
            </button>
          </div>

          <div className="up_down_rate  btn-slip mx-1 quick-input w-100">
            <button>
              <FiMinus size={20} color="#1f72ac" />
            </button>
            <input
              type="number"
              value={data?.activePrice ? data?.activePrice : ""}
              onChange={(e) => handelSetPrice(e.target.value, data)}
              max={1500000}
            />
            <button className="plus">
              <FiPlus size={20} color="#1f72ac" />
            </button>
          </div>

          <div
            className="col-send w-100"
            style={
              !condition
                ? {
                    cursor: "not-allowed",
                  }
                : {}
            }
          >
            <button
              className="bookmaker-place-bet-btn btn"
              onClick={() => {
                if (!isEmpty(user)) {
                  handelPlace();
                } else {
                  navigate("/login");
                }
              }}
              disabled={betLoader ? true : !condition}
              style={
                !condition
                  ? {
                      color: "#fff",
                      background: "black",
                    }
                  : {}
              }
            >
              Place Bets
              {/* {betLoader && (
                <span style={{ marginLeft: "10px" }}>
                  <Loader
                    Style={{
                      borderTop: "7px solid #FFB80C",
                      borderBottom: "7px solid #FFB80C",
                      width: "10px",
                      height: "10px",
                    }}
                  />
                </span>
              )} */}
            </button>
          </div>
        </div>
        <div
          className="preset_value p-2 m-0"
          style={
            data?.betType == "back"
              ? { backgroundColor: "#beddf4" }
              : data?.betType == "lay"
              ? { backgroundColor: "#F3DCE2" }
              : {}
          }
        >
          <div className="d-flex align-items-center">
            {Price?.map((money) => {
              return (
                <button
                  className="fancy-quick-btn"
                  onClick={() => handelSetPrice(money?.value, data)}
                >
                  {money?.value}
                </button>
              );
            })}
          </div>
        </div>
      </td>
    </tr>
  );
};

export default RenderBetSlip;

const Price = [
  { id: 1, value: 100 },
  { id: 2, value: 200 },
  { id: 3, value: 500 },
  { id: 4, value: 500 },
  { id: 5, value: 10000 },
  { id: 6, value: 25000 },
  { id: 7, value: 50000 },
  { id: 8, value: 100000 },
];
