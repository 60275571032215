import React, { useState } from "react";
import { Table, Button, Modal } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { apiGet } from "../../utils/apiFetch";
import apiPath from "../../utils/apiPath";
import { useEffect } from "react";
import { isEmpty } from "lodash";
const FancyBetDialog = ({ show, handleClose, object }) => {
  const { name, eventid, marketId, selectionId } = useParams();
  const [matchData, setMatchData] = useState([]);
  const [detailsData, setDetailsData] = useState([]);
  const [search_params, setSearchParams] = useState({});
  useEffect(() => {
    if (!isEmpty(object)) {
      setSearchParams({
        type: object?.name,
        eventId: object?.eventid,
        selectionId: object?.selectionId,
        marketId: object?.marketId,
      });
    }
  }, [object]);
  const getDetails = async (obj) => {
    const { status, data: response_users } = await apiGet(
      apiPath.fancyBetRunPosition,
      search_params
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results) {
          setDetailsData(response_users.results);
        }
      }
    }
  };
  useEffect(() => {
    if (!isEmpty(search_params)) {
      getDetails();
    }
  }, [search_params]);
  return (
    <Modal show={show} onHide={handleClose} centered>
      {" "}
      <Modal.Header
        className="p-2"
        style={{
          background: "black",
        }}
      >
        <Modal.Title className="h6 text-white">Book</Modal.Title>
      </Modal.Header>{" "}
      <Modal.Body>
        <Table className="table-fancy">
          <thead>
            <tr>
              <th scope="col">Run</th>
              <th scope="col">Amount</th>
            </tr>
          </thead>
          <tbody>
            {detailsData?.bets?.length > 0 &&
              detailsData?.bets?.map((res) => {
                return (
                  <>
                    <tr id="runsEx" style={{ display: "table-row" }}>
                      <td id="runs" className="col-back">
                        {res.betRun}
                      </td>
                      <td id="exposure" className="col-back">
                        {res.position >= 0 ? res.position : `(${res.position})`}
                      </td>
                    </tr>
                  </>
                );
              })}

            {detailsData?.noBet?.length > 0 &&
              detailsData?.noBet?.map((res) => {
                return (
                  <>
                    <tr id="runsEx" style={{ display: "table-row" }}>
                      <td id="runs" className="col-back">
                        {res.profitRunRange}
                      </td>
                      <td id="exposure" className="col-back">
                        {res.positionProfitAmount}
                      </td>
                    </tr>
                    <tr>
                      <td id="runs" className="col-lay">
                        {res.lossRunRange}
                      </td>
                      <td id="exposure" className="col-lay">
                        {`(- ${res.positionLoseAmount})`}
                      </td>
                    </tr>
                    <tr>
                      <td id="runs" className="col-lay">
                        {res.lossRunRange + 1}
                      </td>
                      <td id="exposure" className="col-lay">
                        {`(- ${res.positionLoseAmount})`}
                      </td>
                    </tr>
                  </>
                );
              })}
            {detailsData?.yesBet?.length > 0 &&
              detailsData?.yesBet?.map((res) => {
                return (
                  <>
                    <tr id="runsEx" style={{ display: "table-row" }}>
                      <td id="runs" className="col-back">
                        {res.profitRunRange}
                      </td>
                      <td id="exposure" className="col-back">
                        {res.positionProfitAmount}
                      </td>
                    </tr>
                    <tr>
                      <td id="runs" className="col-lay">
                        {res.lossRunRange}
                      </td>
                      <td id="exposure" className="col-lay">
                        {`(- ${res.positionLoseAmount})`}
                      </td>
                    </tr>
                    <tr>
                      <td id="runs" className="col-lay">
                        {res.lossRunRange - 1}
                      </td>
                      <td id="exposure" className="col-lay">
                        {`(- ${res.positionLoseAmount})`}
                      </td>
                    </tr>
                  </>
                );
              })}
            {/* {detailsData?.noBet?.length > 0 &&
                  detailsData?.noBet?.map((res) => {
                    return (
                      <>
                        <tr>
                          <td style={{ background: "#cde8fd" }}>
                            {res.profitRunRange}
                          </td>
                          <td style={{ background: "#cde8fd" }}>
                            {res.positionProfitAmount}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ background: "#fae5eb" }}>
                            {res.lossRunRange}
                          </td>
                          <td
                            style={{ background: "#fae5eb", color: "#d0021b" }}
                          >
                            {`(- ${res.positionLoseAmount})`}
                          </td>
                        </tr>
                        <tr>
                        <td style={{ background: "#fae5eb" }}>
                          {res.lossRunRange + 1}
                        </td>
                        <td
                            style={{ background: "#fae5eb", color: "#d0021b" }}
                          >
                          {`(- ${res.positionLoseAmount})`}
                        </td>
                      </tr>
                      </>
                    );
                  })}
                {detailsData?.yesBet?.length > 0 &&
                  detailsData?.yesBet?.map((res) => {
                    return (
                      <>
                        <tr>
                          <td style={{ background: "#cde8fd" }}>
                          {res.profitRunRange}
                          </td>
                          <td style={{ background: "#cde8fd" }}>
                          {res.positionProfitAmount}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ background: "#fae5eb" }}>
                          {res.lossRunRange}
                          </td>
                          <td
                            style={{ background: "#fae5eb", color: "#d0021b" }}
                          >
                             {`(- ${res.positionLoseAmount})`}
                          </td>
                        </tr>
                        <tr>
                        <td style={{ background: "#fae5eb" }}>
                          {res.lossRunRange - 1}
                        </td>
                        <td
                            style={{ background: "#fae5eb", color: "#d0021b" }}
                          >
                          {`(- ${res.positionLoseAmount})`}
                        </td>
                      </tr>
                      </>
                    );
                  })} */}
            {isEmpty(detailsData) ? (
              <tr>
                <td colSpan={9}>No records found</td>
              </tr>
            ) : null}
          </tbody>
        </Table>{" "}
      </Modal.Body>
    </Modal>
  );
};

export default FancyBetDialog;
