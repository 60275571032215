import React from "react";
import { Table } from "react-bootstrap";
import BetListInner from "./BetListInner";
import { isEmpty } from "lodash";
const OpenBets = ({ market, setMarket, list, bets }) => {
  return (
    <div className="matched-unmatched">
      <div className="matches_select">
        <select
          value={market}
          onChange={(e) => {
            setMarket(e.target.value);
          }}
          style={{ width: "100%" }}
        >
          <option value="">Select Market</option>
          {list?.map((res) => {
            return <option value={res?._id}>{res?.eventName}</option>;
          })}
        </select>
      </div>
      {(bets?.backBets?.length > 0 || bets?.layBets?.length > 0) && (
        <div>
          <div className="unmatch-match-sec">
            <span>Matched</span>
          </div>
          {bets?.backBets?.length > 0 && (
            <BetListInner type="back" data={bets?.backBets} />
          )}
          {bets?.layBets?.length > 0 && (
            <BetListInner type="lay" data={bets?.layBets} />
          )}
        </div>
      )}
    </div>
  );
};

export default OpenBets;
