import React,{useState} from "react";
import Layout from "./Layout";
import IRE from "../assets/images/flags/IRE.svg";
import FRA from "../assets/images/flags/FRA.svg";
import RSA from "../assets/images/flags/RSA.svg";
import GB from "../assets/images/flags/GB.svg";
import USA from "../assets/images/flags/USA.svg";
const HorseRacing = () => {
  const [tab, setTab] = useState("all");
  return (
    <Layout>
      <div className="col-center games_center_block ">
        <div className="game_banner">
          <img
            src="../assets/images/kv_tennis.jpg"
            className="w-100"
            alt="No_Image"
          />
        </div>

        <div className="game_table_main_wrapper horse" >
          <div className="game_list_filter_shape">
            <h4 style={{ fontWeight: "600" }}>Highlights</h4>
          </div>
          <div
            style={{
              background: "black",
              color: "white",
              fontSize: "13px",
              fontWeight: "700",
              padding: "8px",
            }}
          >
            Horse Racing Schedule
          </div>
          <ul class="nav nav-tabs scrollflags">
            <li
              onClick={() => setTab("all")}
              class={`nav-item ${tab == "all" ? "active" : ""}`}
            >
              <a class={`nav-link ${tab == "all" ? "active" : ""}`}>
                <span>ALL</span>
              </a>
            </li>
            <li
              onClick={() => setTab("IRE")}
              class={`nav-item ${tab == "IRE" ? "active" : ""}`}
            >
              <a class={`nav-link ${tab == "IRE" ? "active" : ""}`}>
                <img class="country-flag" src={IRE} />
                <span>IRE</span>
              </a>
            </li>
            <li
              onClick={() => setTab("FRA")}
              class={`nav-item ${tab == "FRA" ? "active" : ""}`}
            >
              <a class={`nav-link ${tab == "FRA" ? "active" : ""}`}>
                <img class="country-flag" src={FRA} />
                <span>FRA</span>
              </a>
            </li>
            <li
              onClick={() => setTab("RSA")}
              class={`nav-item ${tab == "RSA" ? "active" : ""}`}
            >
              <a class={`nav-link ${tab == "RSA" ? "active" : ""}`}>
                <img class="country-flag" src={RSA} />
                <span>RSA</span>
              </a>
            </li>
            <li
              onClick={() => setTab("GB")}
              class={`nav-item ${tab == "GB" ? "active" : ""}`}
            >
              <a class={`nav-link ${tab == "GB" ? "active" : ""}`}>
                <img class="country-flag" src={GB} />
                <span>GB</span>
              </a>
            </li>
            <li
              onClick={() => setTab("USA")}
              class={`nav-item ${tab == "USA" ? "active" : ""}`}
            >
              <a class={`nav-link ${tab == "USA" ? "active" : ""}`}>
                <img class="country-flag" src={USA} />
                <span>USA</span>
              </a>
            </li>
          </ul>

          <div style={{ background: "white", padding: "2px 10px" }}>
            {tab == "all" ? (
              data?.map((res) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "11px 8px",
                      borderBottom: "1px solid #c8ced3",
                    }} 
                  >
                    <div
                      style={{
                        fontSize: "12px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          color: "#2789ce",
                          fontWeight: "700",
                          marginRight: "6px",
                        }}
                      >
                        Belmont At The Big A{" "}
                      </span>{" "}
                      <span style={{ color: "#777777", fontWeight: "400" }}>
                        {" "}
                        30-09-2024 02:12{" "}
                      </span>
                    </div>{" "}
                    <a
                      id="multiMarketPin"
                      className={"pin-off"}
                      href="javascript:void(0)"
                    ></a>
                  </div>
                );
              })
            ) : (
              <div>
                <div className="schedule">
                  <div className="schedule-list-name">Galway </div>
                  <div className="schedule-list">
                    <div>20:10</div> <div>20:10</div> <div>20:10</div>{" "}
                    <div>20:10</div>
                    <div>20:10</div>
                    <div>20:10</div>
                  </div>
                </div>
                <div className="schedule">
                  <div className="schedule-list-name">Galway </div>
                  <div className="schedule-list">
                    <div>20:10</div> <div>20:10</div> <div>20:10</div>{" "}
                    <div>20:10</div>
                    <div>20:10</div>
                    <div>20:10</div>
                  </div>
                </div>{" "}
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HorseRacing;

const data = [{ name: "1" }, { name: "1" }, { name: "1" }, { name: "1" }];
