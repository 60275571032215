import React from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import { FaCircleInfo } from "react-icons/fa6";
const PreminumFancyTable = ({
  data,
  renderPreminumFancySlip,
  selectPreminumFancy,
  setSelectPreminumFancyFunction,
  setAccordian,
  accordian,
  setPreminumFancyAmount,
  setAcceptAnyOddsPreminumFancyCheckbox,
  manualFancy,
  matchStatus,
  oddsLimit,
  preminumfancyAmount,
}) => {
  const premiumFunction = (item, res) => {
    setSelectPreminumFancyFunction(item, res);
    setAcceptAnyOddsPreminumFancyCheckbox(0);
  };
  return (
    <div>
      {data?.length > 0 &&
        data?.map((item, index) => {
          return (
            <div className=" premiun-table" key={index + 1}>
              <Accordion
                defaultActiveKey={[0, 1, 2, 3, 4]}
                onSelect={(e) => setAccordian(e)}
              >
                <Accordion.Item eventKey={index}>
                  <Accordion.Header>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        padding: "2px 5px",
                      }}
                    >
                      <span>{item.marketName}</span>
                      <FaCircleInfo />
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="winner-super-over">
                      <Row className="justify-content-center p-0">
                        {item?.sportsBookSelection?.length > 0
                          ? item?.sportsBookSelection?.map((res, index) => {
                              let newPosition = "";
                              if (
                                item?.id === selectPreminumFancy?.id &&
                                item?.sportsBookSelection?.length > 0 &&
                                preminumfancyAmount > 0
                              ) {
                                let backProfit =
                                  (selectPreminumFancy?.newRunner?.odds - 1) *
                                  preminumfancyAmount;
                                let backLoss = preminumfancyAmount;
                                if (
                                  res.id === selectPreminumFancy?.newRunner?.id
                                ) {
                                  newPosition =
                                    res?.position !== 0 &&
                                    !isNaN(Math.abs(res?.position))
                                      ? Number(backProfit) +
                                        Number(res.position)
                                      : Number(backProfit);
                                } else {
                                  newPosition =
                                    res.position !== 0 &&
                                    !isNaN(Math.abs(res?.position))
                                      ? Number(backLoss) - Number(res?.position)
                                      : -Number(backLoss);
                                }
                              }
                              let condition =
                                item?.sportsBookSelection?.length > 0 &&
                                Math.abs(newPosition) > 0 &&
                                item?.id === selectPreminumFancy?.id &&
                                preminumfancyAmount > 0
                                  ? true
                                  : false;
                              return (
                                <Col
                                  key={index + 1}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  style={{ borderBottom: "1px solid #7e97a7" }}
                                  onClick={() =>
                                    manualFancy == "off" ||
                                    oddsLimit <= res.odds
                                      ? null
                                      : premiumFunction(item, res)
                                  }
                                >
                                  <div className="d-flex align-items-center">
                                    <div className="w-75 d-flex flex-column">
                                      <span style={{ padding: "2px 10px" }}>
                                        {res?.selectionName}
                                      </span>
                                      <div className="d-flex align-items-center">
                                        {Math?.abs(res?.position) > 0 && (
                                          <span
                                            style={{
                                              color:
                                                res?.position > 0
                                                  ? "green"
                                                  : "red",
                                              padding: "2px 3px",
                                            }}
                                            className="d-flex align-items-center"
                                          >
                                            <FaArrowRight
                                              style={{ marginRight: "4px" }}
                                            />
                                            <span>
                                              {Math.abs(res?.position).toFixed(
                                                2
                                              )}
                                            </span>{" "}
                                          </span>
                                        )}
                                        {condition &&
                                          Math?.abs(newPosition) > 0 && (
                                            <>
                                              <span
                                                style={{
                                                  color:
                                                    newPosition > 0
                                                      ? "green"
                                                      : "red",
                                                  padding: "0 5px",
                                                }}
                                              >
                                                (
                                                {Number(newPosition).toFixed(2)}
                                                )
                                              </span>
                                            </>
                                          )}{" "}
                                      </div>
                                    </div>
                                    <div className="w-25">
                                      <span
                                        style={{
                                          background: "#72e3a0",
                                          height: "40px",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          width: "30%",
                                        }}
                                      >
                                        {res.odds}
                                      </span>
                                    </div>
                                  </div>
                                </Col>
                              );
                            })
                          : ""}
                      </Row>
                      {item?.id === selectPreminumFancy?.id && (
                        <>{renderPreminumFancySlip(selectPreminumFancy)}</>
                      )}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          );
        })}
    </div>
  );
};

export default PreminumFancyTable;
