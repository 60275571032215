import React, { useState, useEffect, Fragment, useContext } from "react";
import { Accordion } from "react-bootstrap";
import _, { filter, pick } from "lodash";
import MatchSidebar from "../components/MatchSidebar";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { apiGet, apiPost } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import transparentGif from "../../src/assets/images/transparent.gif";
import moment from "moment/moment";
import { isEmpty } from "lodash";
import { toast } from "wc-toast";
import AuthContext from "../context/AuthContext";
import Login from "../components/Login";
import helpers from "../utils/helpers";
import Loader from "../components/Loader";
import LoaderGif from "../assets/gif/loader.gif";
import BetSlipContext from "../context/BetSlipContext";
import loading40 from "../../src/assets/images/loading40.gif";
import io from "socket.io-client";
import Layout from "./Layout";
import MatchListSingle from "../components/MatchListSingle";
const isInputNumber = helpers.isInputNumber;
const MultiMarkets = () => {
  const showToggle = () => {
    document.getElementById("body-parent").classList.toggle("showbet");
  };
  let { activeOneClick, activeOneClickAmount, handelAddMultiMarket, runApi } =
    useContext(BetSlipContext);
  const [isLoader, setLoader] = useState(false);
  const params = useParams();
  const location = useLocation();
  var eventId = params.eventId;
  let sportType = params.sportType;
  let { user, loginUserDetail, loginUserDetailData, stakeData } =
    useContext(AuthContext);

  const [details, setDetails] = useState([]);

  const getMatchDetails = async () => {
    const { status, data: response_users } = await apiGet(apiPath.multiMarket);
    if (status === 200) {
      if (response_users.success) {
        setDetails(response_users?.results);
        if (response_users?.results?.length > 0) {
          getOdds(
            response_users?.results?.map((res) => {
              return res?.marketId;
            })
          );
        }
      }
    }
  };

  const getOdds = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getMatchOdds + `?marketId=${id?.join(",")}&multi=true`
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results?.length > 0) {
          setDetails((current) =>
            current.map((obj) => {
              let newObj = response_users?.results?.find((res) => {
                return res?.mi == obj?.marketId;
              });
              if (obj?.marketId == newObj?.mi) {
                return { ...obj, odds: newObj };
              }
              return obj;
            })
          );
        }
      }
    }
  };

  useEffect(() => {
    if (!isEmpty(user)) {
      getMatchDetails();
    }
  }, [user]);

  return (
    <Layout>
      <div className="col-center games_center_block game-wrapper-block">
        {details.length > 0 ? (
          <div className="game_table_main_wrapper">
            <div className="game_table_inner">
              <ul className="slip-head d-flex">
                <li className="col-game"></li>
                <li className="col-matched text-end"></li>
                <li className="col-visit text-center">1</li>
                <li className="col-draw text-center">x</li>
                <li className="col-home text-center">2</li>
                <li className="col-info"></li>
              </ul>
              <div className="game-list">
                {details.length > 0 &&
                  details.map((item, index) => {
                    let obj = moment.duration(
                      moment(item?.eventDateTime).diff(moment(new Date()))
                    )._data;
                    let scoreNew = {};
                    return (
                      <MatchListSingle
                        item={item}
                        scoreNew={scoreNew}
                        obj={obj}
                        loginUserDetailData={loginUserDetailData}
                        handelAddMultiMarket={handelAddMultiMarket}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        ) : (
          <div className="no-multi">
            <h3>There are currently no followed multi markets.</h3>
            <p>Please add some markets from events.</p>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default MultiMarkets;
