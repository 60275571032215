import React, { useState, useEffect } from "react";
import { apiGet } from "../utils/apiFetch";
import helpers from "../utils/helpers";
import apiPath from "../utils/apiPath";
import { Link, useNavigate, useParams } from "react-router-dom";
import OddsListing from "../components/OddsListing";
import { useContext } from "react";
import BetSlipContext from "../context/BetSlipContext";
import AuthContext from "../context/AuthContext";
import Loader from "../assets/gif/loader.gif";
import moment from "moment";
import { isEmpty } from "lodash";
import Layout from "./Layout";
import MatchListSingle from "../components/MatchListSingle";

const MatchOuter = () => {
  const navigate = useNavigate();
  const params = useParams();
  let { handelAddMultiMarket } = useContext(BetSlipContext);
  let { user, inPlayScore, loginUserDetailData } = useContext(AuthContext);
  const [matches, setMatches] = useState([]);

  let userId = "";
  if (user?.user?._id) {
    userId = "&userId=" + user?.user?._id;
  }
  const getMatches = async (type) => {
    try {
      const { status, data: response_users } = await apiGet(
        `${apiPath.matchList + `?gameType=${type}` + userId}`
      );
      if (status === 200) {
        if (response_users.success) {
          inPlayScore?.length > 0
            ? setMatches(
                response_users?.results?.map((res) => {
                  let temp = inPlayScore.find(
                    (item) => item?.eventId == res?.eventId
                  );
                  return {
                    ...res,
                    score: temp?.eventId == res?.eventId ? temp?.score : "",
                    timeElapsed:
                      temp?.eventId == res?.eventId ? temp?.timeElapsed : "",
                  };
                })
              )
            : setMatches(response_users.results);

          if (response_users?.results?.length > 0) {
            getOdds(
              response_users?.results?.map((res) => {
                return res?.marketId;
              })
            );
          }
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (params?.type !== "") {
      getMatches(params?.type);
    }
  }, [params?.type]);

  useEffect(() => {
    if (inPlayScore?.length > 0) {
      setMatches((current) => {
        return current.map((res) => {
          let temp = inPlayScore.find((item) => item?.eventId == res?.eventId);
          return {
            ...res,
            score: temp?.eventId == res?.eventId ? temp?.score : "",
            timeElapsed: temp?.eventId == res?.eventId ? temp?.timeElapsed : "",
          };
        });
      });
    }
  }, [inPlayScore]);

  const getOdds = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getMatchOdds + `?marketId=${id?.join(",")}&multi=true`
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results?.length > 0) {
          setMatches((current) =>
            current.map((obj) => {
              let newObj = response_users?.results?.find((res) => {
                return res?.mi == obj?.marketId;
              });
              if (obj?.marketId == newObj?.mi) {
                return { ...obj, odds: newObj };
              }
              return obj;
            })
          );
        }
      }
    }
  };
  return (
    <Layout news={true}>
      <div className="col-center games_center_block">
        <div className="game_banner">
          <img
            src="../assets/images/kv_tennis.jpg"
            className="w-100"
            alt="No_Image"
          />
        </div>

        <div className="game_table_main_wrapper">
          <div className="game_list_filter_shape">
            <h4 style={{ fontWeight: "600" }}>Highlights</h4>
          </div>

          {matches.length > 0 ? (
            <div className="game_table_main_wrapper">
              <div className="game_table_inner">
                <ul className="slip-head d-flex">
                  <li className="col-game"></li>
                  <li className="col-matched text-end"></li>
                  <li className="col-visit text-center">1</li>
                  <li className="col-draw text-center">x</li>
                  <li className="col-home text-center">2</li>
                  <li className="col-info"></li>
                </ul>
                <div className="game-list">
                  {matches &&
                    matches.length > 0 &&
                    matches.map((item, index) => {
                      let obj = moment.duration(
                        moment(item?.eventDateTime).diff(moment(new Date()))
                      )._data;
                      let scoreNew = {};
                      return (
                        <MatchListSingle
                          item={item}
                          scoreNew={scoreNew}
                          obj={obj}
                          loginUserDetailData={loginUserDetailData}
                          handelAddMultiMarket={handelAddMultiMarket}
                          type={params?.type}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          ) : matches.length <= 0 ? (
            <div className="d-flex align-item-center p-2">No Match Found</div>
          ) : (
            <span className="d-flex justify-content-center align-item-center mt-2 pb-3">
              <img src={Loader} alt="No_Image" />
            </span>
          )}
        </div>
      
        {/* <div className="row">
            <div class="col-md-12 footerDiv mobile-android-height">
              <div class="inner-footer">
                <div class="support-wrap">
                  <dl class="support-mail">
                    <a
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      class="rules-btn-home"
                    >
                      Privacy Policy
                    </a>
                    <a
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      class="rules-btn-home arrow"
                    >
                      KYC
                    </a>
                    <a
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      class="rules-btn-home arrow"
                    >
                      Terms and Conditions
                    </a>
                    <a
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      class="rules-btn-home arrow"
                    >
                      Rules and Regulations
                    </a>
                    <a
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      class="rules-btn-home arrow"
                    >
                      Responsible Gambling
                    </a>
                  </dl>
                </div>
                <div class="extra-wrap">
                  <div class="appdl-link-android" style={{ display: "block" }}>
                    <a target="_blank">
                      <img alt="" src="./../../../assets/androidapk.png" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="exampleModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              class="modal fade"
            >
              <div role="document" class="modal-dialog modal-md">
                <div class="modal-content">
                  <div class="modal-content rules-wrap">
                    <div class="rules-content">
                      <div></div>
                    </div>
                    <ul class="btn-wrap break">
                      <li>
                        <a data-bs-dismiss="modal" class="btnok">
                          OK
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
      </div>
    </Layout>
  );
};

export default MatchOuter;
