import React, { useState, useContext, useEffect } from "react";
import Login from "../components/Login";
import MatchSidebar from "../components/MatchSidebar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Accordion, Container } from "react-bootstrap";
import AuthContext from "../context/AuthContext";
import { apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import LoginBeforeModel from "../components/LoginBeforeModel";
import OpenBets from "./MatchOdds/OpenBets";
import { isEmpty } from "lodash";
import obj from "../utils/helpers";
import ReactPlayer from "react-player";
const Layout = ({ news, children }) => {
  const params = useParams();

  const { user, setBeforeModal, beforeModal, message, streamingUrl, allBets } =
    useContext(AuthContext);
  const location = useLocation();
  const [mute, setMute] = useState(false);
  const [accordianToogle, setAccordianToogle] = useState("bets");
  const [market, setMarket] = useState("");
  const [currentBets, setCurrentBets] = useState({});

  useEffect(() => {
    if (market !== "") {
      handel(market);
    } else {
      setCurrentBets({});
    }
  }, [market, allBets]);

  const handel = (value) => {
    let find = allBets?.find((res) => {
      return res?._id === value;
    });
    if (!isEmpty(find)) {
      let result = [
        find?.betList,
        find?.bookmakerList,
        find?.sessionBetList,
        find?.sportBookBetList?.map((res) => {
          return { ...res, betTypeNew: "sport" };
        }),
      ].flat();
      console.log(result, "reslut");
      setCurrentBets({
        ...find,
        layBets: result?.filter((res) => {
          return res?.betType == "lay" || res?.type == "No";
        }),
        backBets: result?.filter((res) => {
          return res?.betType == "back" || res?.type == "Yes";
        }),
      });
    }
  };
  return (
    <div>
      <div className="full-wrap">
        <MatchSidebar />
        <div
          style={{
            overflowY: "scroll",
            height: "100%",
            width: "60%",
            padding: "6px",
            scrollbarWidth: "none",
            maxHeight: "100%",
          }}
        >
          {news && (
            <div className="d-flex justify-content-center">
              <section className="breadcum-sec" style={{ width: "100%" }}>
                {!isEmpty(user) && message.length > 0 && (
                  <div
                    className="bredcum-sec-main"
                    style={{ borderRadius: "0" }}
                  >
                    <h5 className="mb-0">
                      <i className="fa-solid fa-microphone"></i> News
                    </h5>
                    <marquee width="100%">
                      <div style={{ display: "flex" }}>
                        {message.map((res) => {
                          return (
                            <div style={{ marginRight: "20px" }}>
                              <span style={{ marginRight: "10px" }}>
                                {obj.msgDateFormat(res.msgDate)} -{" "}
                              </span>
                              <span>{res.message}</span>
                            </div>
                          );
                        })}
                      </div>
                    </marquee>
                  </div>
                )}
              </section>
            </div>
          )}
          <div className="col-center games_center_block">{children}</div>
        </div>
        <div className="col-right">
          <div className="theme_accordian">
            <Accordion
              defaultActiveKey={["myBets"]}
              alwaysOpen
              onSelect={(e) => setAccordianToogle(e)}
            >
              {" "}
              {!isEmpty(user) &&
                location?.pathname?.split("/")[1] == "markets" && (
                  <Accordion.Item
                    eventKey=""
                    className="mb-0"
                    style={{ height: "auto" }}
                  >
                    <Accordion.Header>Live Streaming</Accordion.Header>
                    {!isEmpty(streamingUrl?.url) && !isEmpty(user) && (
                      <Accordion.Body>
                        <ReactPlayer
                          muted={streamingUrl?.ad ? false : mute}
                          controls={false}
                          playsinline={true}
                          playing={true}
                          width="100%"
                          height="200px"
                          url={
                            streamingUrl?.ad && streamingUrl?.ad
                              ? streamingUrl?.ad
                              : streamingUrl?.url
                          }
                        />
                      </Accordion.Body>
                    )}
                  </Accordion.Item>
                )}
              <Accordion.Item
                eventKey="myBets"
                // className={user ? "login-accordion-item" : ""}
              >
                <Accordion.Header>Open Bets</Accordion.Header>
                {allBets?.length > 0 && !isEmpty(user) && (
                  <Accordion.Body>
                    <OpenBets
                      market={market}
                      setMarket={setMarket}
                      list={allBets}
                      bets={currentBets}
                      setCurrentBets={setCurrentBets}
                    />
                  </Accordion.Body>
                )}
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
      <LoginBeforeModel
        show={beforeModal}
        onHide={() => setBeforeModal(false)}
      />
    </div>
  );
};

export default Layout;
