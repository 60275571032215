import React, { useEffect, useState } from "react";
import { Row, Col, Table, Form, Button } from "react-bootstrap";
import { apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import moment from "moment";
import Layout from "./Layout";
import helpers from "../utils/helpers";
import obj, { headerData } from "../utils/constants";
import ReactPaginate from "react-paginate";
import { MyAccountLayout } from "./MyAccountLayout";
import NewObj from "../utils/helpers";
import { isEmpty, startCase } from "lodash";
const BetHistoryNew = () => {
  const [data, setData] = useState({});
  let currentDate = moment(new Date()).format("YYYY-MM-DD");
  let previousDate = moment(new Date()).subtract(1, "day").format("YYYY-MM-DD");
  const [filter, setFilter] = useState({
    status: "completed",
    sports: "4",
    fromPeriod: previousDate,
    toPeriod: currentDate,
    page: 1,
    limit: 50,
  });

  const getData = async () => {
    const { status, data } = await apiGet(apiPath.getHistory, filter);
    if (status == 200) {
      if (data?.success) {
        setData(data?.results);
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Layout>
      <MyAccountLayout
        heading={"Bet History"}
        text={"History"}
        type="bet"
        isTable={true}
        isFilter={true}
        filter={filter}
        setFilter={setFilter}
        getData={getData}
      >
        <Table className="table-bordered">
          <thead>
            <tr>
              <th>Sport Name</th> <th>Event Name</th> <th>Market Name</th>
              <th>Selection</th>
              <th>Type</th>
              <th>Odds Req.</th> <th>Stack</th>
              <th>Place Time</th>
              <th>Matched Time</th>
            </tr>
          </thead>

          <tbody>
            {data?.length > 0 ? (
              data?.map((res) => {
                return (
                  <tr>
                    <td>{obj.betCheckObj[res?.eventType]}</td>{" "}
                    <td>{res?.matchName}</td>
                    <td>{res?.market}</td> <td>{res?.teamName}</td>{" "}
                    <td
                      style={{
                        color: res?.betType == "lay" ? "#d50000" : "#09437f",
                        fontWeight: "700",
                      }}
                    >
                      {startCase(res?.betType)}
                    </td>{" "}
                    <td>{res?.bhav}</td> <td>{res?.amount}</td>{" "}
                    <td>{NewObj.dateFormat(res?.timeInserted)}</td>
                    <td>{NewObj.dateFormat(res?.updatedAt)}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={9}>
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "8px",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    No Data!
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </MyAccountLayout>
    </Layout>
  );
};

export default BetHistoryNew;

const Empty = () => {
  return (
    <tr>
      <td colSpan={9}>
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "8px",
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          No Data!
        </span>
      </td>
    </tr>
  );
};
