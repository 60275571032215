import React, { useState, useEffect, Fragment, useContext } from "react";
import { Table, Accordion, Tabs, Tab } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { apiGet, apiPost } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import moment from "moment/moment";
import io from "socket.io-client";
import { toast } from "wc-toast";
import AuthContext from "../context/AuthContext";
import secureLocalStorage from "react-secure-storage";
import obj, { categoryArray, priceData } from "../utils/constants";
import helpers from "../utils/helpers";
import Loader from "../components/Loader";
import FancyTabs from "./MatchOdds/FancyTabs";
import { popoverPremium, popoverFancy } from "./MatchOdds/ComponentsMatchOdds";
import BetSlipContext from "../context/BetSlipContext";
import PreminumFancyTable from "./MatchOdds/PreminumFancyTable";
import { inRange, isEmpty, isNumber } from "lodash";
import BetFair from "./MatchOdds/BetFair";
import Layout from "./Layout";
import { Bookmaker } from "./MatchOdds/Bookmaker";
import { FiMinus, FiPlus } from "react-icons/fi";
const isInputNumber = helpers.isInputNumber;
const MatchOdd = () => {
  let { activeOneClick, activeOneClickAmount } = useContext(BetSlipContext);
  const params = useParams();
  const location = useLocation();
  var eventId = params.eventId;
  let sportType = params.sportType;
  const [preminumFancy, setPremiumFancy] = useState([]);
  let {
    user,
    loginUserDetail,
    loginUserDetailData,
    stakeData,
    getCoins,
    setSocketId,
    setStreamingUrl,
    getAfterBet,betLoader, setBetLoader
  } = useContext(AuthContext);
  useEffect(() => {
    if (!isEmpty(user)) {
      loginUserDetail();
    }
  }, [user]);
  const navigate = useNavigate();
  const [isMultimarket, setIsMultiMarket] = useState(false);
  const [selections, setSelections] = useState([]);
  const [back_odds, setBackOdds] = useState([]);
  const [lay_odds, setLayOdds] = useState([]);
  const [priceList, setPriceList] = useState([
    { id: 1, value: 10 },
    { id: 2, value: 50 },
    { id: 3, value: 100 },
    { id: 4, value: 200 },
    { id: 5, value: 1000 },
    { id: 6, value: 5000 },
  ]);
  useEffect(() => {
    if (stakeData.length) {
      setPriceList(stakeData);
    }
  }, [user, stakeData.length]);
  const [bookmakerBackOdds, setBookmakerBackOdds] = useState([]);
  const [bookmakerLayOdds, setBookmakerLayOdds] = useState([]);
  const [bookmakerSelections, setBookmakerSelections] = useState([]);
  const [details, setDetails] = useState({});

  const [bookmakerBetSlipObj, setBookMakerBetSlipObj] = useState({});
  const [acceptAnyOddsCheckbox, setAcceptAnyOddsCheckbox] = useState(0);



  const [fancyList, setFancyList] = useState([]);
  const [showFancy, setShowFancy] = useState("fancy");
  const [fancyBetSlipObj, setFancyBetSlipObj] = useState({});
  const [acceptAnyOddsFancyCheckbox, setAcceptAnyOddsFancyCheckbox] =
    useState(0);
  const [loaderFancyBet, setLoaderFancyBet] = useState(false);
  const [key, setKey] = useState("All");
  const [totalMatched, setTotalMatched] = useState(0);
  const [fancyKey, setFancyKey] = useState("All");
  const [fancyCategory, setFancyCategory] = useState([]);
  const [fancyCategoryTabArray, setFancyCategoryTabArray] = useState([]);
  const [
    acceptAnyOddsPreminumFancyCheckbox,
    setAcceptAnyOddsPreminumFancyCheckbox,
  ] = useState(0);
  const [currentBets, setCurrentBets] = useState({});
  const [premiumFancyPosition, setPremiumFancyPosition] = useState([]);
  const onChangeKey = (id) => {
    setKey(id);
    if (id == "All") {
      setFancyCategoryTabArray(
        fancyList.filter((res) => {
          return res.odds?.rt?.length > 0;
        })
      );
    } else if (id == "More") {
      let arr = fancyList.filter(
        (res) =>
          res.categoryType !== 1 &&
          res.categoryType !== 2 &&
          res.categoryType !== 3 &&
          res.categoryType !== 4 &&
          res.categoryType !== 5 &&
          res.categoryType !== 6 &&
          res.categoryType !== 7 &&
          res.categoryType !== 8 &&
          res.categoryType !== 9 &&
          res.categoryType !== 10 &&
          res.categoryType !== 11 &&
          res.categoryType !== 14 &&
          res.odds?.rt?.length > 0
      );
      setFancyCategoryTabArray(arr);
    } else {
      let temp = fancyList.filter((res) => {
        return res.categoryType == id && res.odds?.rt?.length > 0;
      });
      setFancyCategoryTabArray(temp);
    }
  };
  const [amountRangeBetFair, setAmountRangeBetFair] = useState({});
  const [accordian, setAccordian] = useState("");
  const [selectPreminumFancy, setSelectPreminumFancy] = useState({});
  const [preminumfancyAmount, setPreminumFancyAmount] = useState("");
  const [betFairMs, setBetFairMs] = useState(1);
  const [socketObj, setSocketObj] = useState(null);
  const [checkRefresh, setCheckRefresh] = useState(true);
  const [fancyPosition, setFancyPosition] = useState([]);
  const [marketNew, setMarketNew] = useState([]);
  const [marketIdNew, setMarketIdNew] = useState([]);
  const [timeLeft, setTimeLeft] = useState(5);
  const getSocket = () => {
    if (details?.eventId && checkRefresh) {
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
      setCheckRefresh(false);
      let newSocket = "";
      let randomId = user?.user?._id ? user?.user?._id : 112233;
      if (!isEmpty(user)) {
        newSocket = io(
          `${
            details?.socketUrl
              ? details?.socketUrl
              : process.env.REACT_APP_API_BASE_URL1
          }?token=${randomId}&userType=front&eventId=${details?.eventId}`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      } else {
        newSocket = io(
          `${
            details?.socketUrl
              ? details?.socketUrl
              : process.env.REACT_APP_API_BASE_URL1
          }?user_id=${randomId}&eventId=${details?.eventId}`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      }
      setSocketObj(newSocket);
      setSocketId(newSocket);
      if (!isEmpty(user)) {
        newSocket.on("listenDiamondFancy", listenDiamondFancy);
        newSocket.on("listenBetFairOdds", listenBetfairOdds);
        newSocket.on("listenBookmakerOdds", listenBookmakerData);
        newSocket.on("listenPremiumFancy", listenSportBook);
        newSocket.emit("setPremiumFancy", {
          user_id: user?.user?._id ? user?.user?._id : randomId,
          eventId: params.eventId,
        });
      } else {
        newSocket.on("listenDiamondFancy", listenDiamondFancy);
        newSocket.on("listenBetFairOdds", listenBetfairOdds);
        newSocket.on("listenPremiumFancy", listenSportBook);
        newSocket.on("listenBookmakerOdds", listenBookmakerData);
      }
      newSocket.on("disconnect", function () {
        setCheckRefresh(true);
      });
      return () => newSocket.disconnect();
    }
  };

  const [amountRangeObject, setAmountRangeObject] = useState({});
  useEffect(() => {
    let fancy = {};
    let betfair = {};
    let bookmaker = {};
    let sportbook = {};
    if (!isEmpty(details)) {
      let profileData =
        details?.matchSetting?.length > 0
          ? { ...details, arrayCheck: "details" }
          : { ...loginUserDetailData, arrayCheck: "profile" };

      let objBetFair = profileData?.matchSetting?.find(
        (res) =>
          res?.type == "betFaire" &&
          (loginUserDetailData?.arrayCheck == "details"
            ? true
            : res?.sportType == details?.gameType)
      );
      if (!isEmpty(objBetFair)) {
        if (inRange(totalMatched, 5000, 15000)) {
          betfair = {
            ...objBetFair,
            min: process.env.REACT_APP_AMOUNT_RANGE_MIN
              ? Number(process.env.REACT_APP_AMOUNT_RANGE_MIN)
              : Number(objBetFair?.minAmount),
            max: 370,
            oddsLimit: objBetFair?.oddsLimit ? objBetFair?.oddsLimit : 0,
          };
        } else if (inRange(totalMatched, 0, 5000)) {
          betfair = {
            ...objBetFair,
            min: process.env.REACT_APP_AMOUNT_RANGE_MIN
              ? Number(process.env.REACT_APP_AMOUNT_RANGE_MIN)
              : Number(objBetFair?.minAmount),
            max: 100,
            oddsLimit: objBetFair?.oddsLimit ? objBetFair?.oddsLimit : 0,
          };
        } else {
          betfair = {
            ...objBetFair,
            min: process.env.REACT_APP_AMOUNT_RANGE_MIN
              ? Number(process.env.REACT_APP_AMOUNT_RANGE_MIN)
              : Number(objBetFair?.minAmount),
            max: process.env.REACT_APP_AMOUNT_RANGE_MAX
              ? Number(process.env.REACT_APP_AMOUNT_RANGE_MAX)
              : Number(objBetFair?.maxAmount),
            oddsLimit: objBetFair?.oddsLimit ? objBetFair?.oddsLimit : 0,
          };
        }
      }

      let objFancy = profileData?.matchSetting?.find(
        (res) =>
          res?.type == "fancy" &&
          (profileData?.arrayCheck == "details"
            ? true
            : res?.sportType == details?.gameType)
      );
      if (!isEmpty(objFancy)) {
        fancy = {
          ...objFancy,
          min: process.env.REACT_APP_AMOUNT_RANGE_MIN
            ? Number(process.env.REACT_APP_AMOUNT_RANGE_MIN)
            : Number(objFancy?.minAmount),
          max: process.env.REACT_APP_AMOUNT_RANGE_MAX
            ? Number(process.env.REACT_APP_AMOUNT_RANGE_MAX)
            : Number(objFancy?.maxAmount),
          oddsLimit: objFancy?.oddsLimit ? objFancy?.oddsLimit : 0,
        };
      }

      let objSportBook = profileData?.matchSetting?.find(
        (res) =>
          res?.type == "sportBook" &&
          (profileData?.arrayCheck == "details"
            ? true
            : res?.sportType == details?.gameType)
      );
      if (!isEmpty(objSportBook)) {
        sportbook = {
          ...objSportBook,
          min: Number(objSportBook?.minAmount),
          max: Number(objSportBook?.maxAmount),
          oddsLimit: objSportBook?.oddsLimit ? objSportBook?.oddsLimit : 0,
        };
      }

      let objBookmaker = profileData?.matchSetting?.find(
        (res) =>
          res?.type == "bookmaker" &&
          (profileData?.arrayCheck == "details"
            ? true
            : res?.sportType == details?.gameType)
      );
      if (!isEmpty(obj)) {
        bookmaker = {
          ...objBookmaker,
          min: process.env.REACT_APP_AMOUNT_RANGE_MIN
            ? Number(process.env.REACT_APP_AMOUNT_RANGE_MIN)
            : Number(objBookmaker?.minAmount),
          max: process.env.REACT_APP_AMOUNT_RANGE_MAX
            ? Number(process.env.REACT_APP_AMOUNT_RANGE_MAX)
            : Number(objBookmaker?.maxAmount),
          oddsLimit: objBookmaker?.oddsLimit ? objBookmaker?.oddsLimit : 0,
        };
      }
    }
    setAmountRangeObject({
      fancy: fancy,
      betfair: betfair,
      bookmaker: bookmaker,
      sportbook: sportbook,
    });
  }, [details, loginUserDetailData, totalMatched]);

  useEffect(() => {
    let channelID = details?.channel ? details?.channel : "";
    setStreamingUrl((prev) => {
      return {
        ...prev,
        url:
          "https://mediasrv789-ss247-23-prod-sa-ulivestreaming.com/" +
          channelID +
          "/index.m3u8",
        ad: details?.adsStatus,
      };
    });
  }, [details]);

  const listenSportBook = (message) => {
    const socket_data = message;
    if (socket_data.results && socket_data.eventId == params.eventId) {
      if (!isEmpty(user)) {
        setPremiumFancyPosition((prev) => {
          setPremiumFancy((current) => {
            let updatedArray = socket_data?.results;
            return updatedArray?.map((res) => {
              let obj = prev?.filter((item) => {
                return item?.fancyName == res?.marketName;
              });
              if (obj?.length > 0 && obj[0]?.fancyName == res?.marketName) {
                return {
                  ...res,
                  sportsBookSelection: res?.sportsBookSelection?.map((elem) => {
                    let temp = obj?.find(
                      (item) => elem?.id == item?.fancySelectionId
                    );
                    return {
                      ...elem,
                      position: temp?.position,
                    };
                  }),
                };
              } else {
                return res;
              }
            });
          });
          return prev;
        });
      }
    }
  };

  const listenBetfairOdds = (event) => {
    if (event?.results[0]?.mi == params?.marketId) {
      const socket_data =
        (event &&
          event?.results?.find((item) => item.eventId == params?.eventId)) ||
        [];
      if (
        socket_data &&
        socket_data?.eventId &&
        params?.eventId == socket_data?.eventId
      ) {
        if (socket_data.ms) {
          setBetFairMs(socket_data.ms);
        }
        if (socket_data.rt?.length) {
          const back_odds = socket_data.rt.filter((rt) => rt.ib) || [];
          const lay_odds = socket_data.rt.filter((rt) => !rt.ib) || [];
          setBackOdds(back_odds);
          setLayOdds(lay_odds);
          setTotalMatched(socket_data?.totalMatched);
        }
      }
    } else {
      setMarketNew((res) => {
        let socketData = event?.results[0];
        return res?.map((item) => {
          if (item?.marketId == socketData?.mi && socketData?.rt?.length > 0) {
            return {
              ...item,
              back_odds: socketData?.rt?.filter((rt) => rt.ib) || [],
              lay_odds: socketData?.rt?.filter((rt) => !rt.ib) || [],
              totalMatched: socketData?.totalMatched,
              ms: socketData?.ms,
              mi: socketData?.mi,
            };
          } else {
            return item;
          }
        });
      });
    }
  };

  const listenDiamondFancy = (message) => {
    if (message?.eventId === params?.eventId) {
      if (message?.results?.length > 0) {
        let temp = categoryArray?.filter((f) =>
          message?.results?.some((item) => item?.categoryType === f.type)
        );
        setFancyCategory(
          temp.length > 0
            ? [
                { type: "All", name: "All" },
                ...temp,
                { type: "More", name: "More" },
              ]
            : [{ type: "All", name: "All" }]
        );
        setFancyPosition((prev) => {
          setFancyList(
            message?.results?.map((res) => {
              let obj = prev?.find(
                (item) => item.selectionId == res.selectionId
              );
              return {
                ...res,
                odds: res,
                fancyName: res.name,
                position: !isEmpty(obj) ? obj?.position : "",
                newPosition:
                  Math.abs(obj?.newPosition) > 0 ? obj?.newPosition : "",
              };
            })
          );
          setFancyCategoryTabArray(
            message?.results?.map((res) => {
              let obj = prev?.find(
                (item) =>
                  item.selectionId == res.selectionId &&
                  item.fancyName == res.name
              );
              return {
                ...res,
                odds: res,
                fancyName: res.name,
                position: !isEmpty(obj) ? obj?.position : "",
                newPosition:
                  Math.abs(obj?.newPosition) > 0 ? obj?.newPosition : "",
              };
            })
          );
          return prev;
        });
      }
    }
  };

  const listenBookmakerData = (message) => {
    if (message.eventId === params?.eventId) {
      setBookmakerSelections((prev) => {
        return prev?.map((res) => {
          let temp = message?.results?.rt.find((item) => {
            return item?.name === res?.RunnerName;
          });
          if (temp?.name === res?.RunnerName) {
            return {
              ...res,
              ...temp,
            };
          } else {
            return { ...res, ...temp };
          }
        });
      });
      const back_odds = message?.results?.rt.filter((rt) => rt.ib) || [];
      const lay_odds = message?.results?.rt.filter((rt) => !rt.ib) || [];
      setBookmakerLayOdds(lay_odds);
      setBookmakerBackOdds(back_odds);
    }
  };

  useEffect(() => {
    if (checkRefresh && details?.eventId && !isEmpty(user)) {
      getSocket();
    }
  }, [checkRefresh, details, user]);

  document.addEventListener("visibilitychange", function () {
    if (!document.hidden && !checkRefresh) {
      setCheckRefresh(true);
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
    } else {
      setCheckRefresh(false);
    }
  });

  const setSelectPreminumFancyFunction = (item, res) => {
    if (activeOneClick) {
      handelPlacePreminumFancyBet({
        ...item,
        newRunner: res,
        // odds: res.odds,
        // selectionId: res.id,
        // runnerName: res.selectionName,
        // apiSiteSelectionId: res.apiSiteSelectionId,
      });
    } else {
      let mainArr = {
        ...item,
        newRunner: res,
        // odds: res.odds,
        // selectionId: res.id,
        // runnerName: res.selectionName,
        // apiSiteSelectionId: res.apiSiteSelectionId,
      };
      setSelectPreminumFancy(mainArr);

      if (loginUserDetailData?.defaultStake) {
        setPreminumFancyAmount(loginUserDetailData?.defaultStake);
        handelPreminumFancyPositions(
          loginUserDetailData?.defaultStake,
          mainArr
        );
      }
    }
  };
  const getPremiumFancyBetPosition = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.betPremiumFancyPosition +
        `?eventId=${params.eventId}&type=${details.gameType}`
    );
    if (status === 200) {
      if (response_users.success) {
        setPremiumFancyPosition(response_users?.results);
        setPremiumFancy((current) => {
          return current?.map((res) => {
            let obj = response_users?.results?.filter((item) => {
              return item?.fancyName == res?.marketName;
            });
            if (obj?.length > 0 && obj[0]?.fancyName == res?.marketName) {
              return {
                ...res,
                sportsBookSelection: res?.sportsBookSelection?.map((elem) => {
                  let temp = obj?.find(
                    (item) => elem?.id == item?.fancySelectionId
                  );
                  return {
                    ...elem,
                    position: temp?.position,
                  };
                }),
              };
            } else {
              return res;
            }
          });
        });
      }
    }
  };
  const getFancyBetPosition = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.fancyBetPosition + "?type=" + sportType + "&eventId=" + eventId
    );
    try {
      if (status === 200) {
        if (response_users.success) {
          setFancyPosition(response_users.results);
          updatedFancyPosition(
            response_users?.results?.map((res) => {
              return { selectionId: res?.selectionId, position: res?.position };
            })
          );
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const updatedFancyPosition = (object) => {
    setFancyPosition(object);
    setFancyList((prev) => {
      return prev?.map((res) => {
        let obj = object?.find((item) => item?.selectionId == res?.selectionId);
        return {
          ...res,
          odds: res,
          position: !isEmpty(obj) ? obj?.position : "",
        };
      });
    });
    setFancyCategoryTabArray((prev) => {
      return prev?.map((res) => {
        let obj = object?.find((item) => item?.selectionId == res?.selectionId);
        return {
          ...res,
          odds: res,
          position: !isEmpty(obj) ? obj?.position : "",
        };
      });
    });
  };
  const getCurrentBets = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.getBetsData + "?eventId=" + params.eventId
    );
    if (status === 200) {
      if (response_users.success) {
        setCurrentBets(response_users.results);
      }
    }
  };
  const handlePreminumAmount = (e, obj) => {
    setPreminumFancyAmount(e);
    handelPreminumFancyPositions(e, obj);
  };
  const handelPreminumFancyPositions = (price, slipObj) => {
    let backProfit = (slipObj?.newRunner?.odds - 1) * price;
    let backLoss = price;
    setPremiumFancy((current) => {
      return current?.map((rl) =>
        rl.id == slipObj.id
          ? {
              ...rl,
              sportsBookSelection: rl?.sportsBookSelection?.map((res) => {
                return res.marketId === slipObj?.newRunner?.marketId
                  ? {
                      ...res,
                      position:
                        res.position !== 0 && !isNaN(Math.abs(res.position))
                          ? res.position
                          : 0,
                      newPosition:
                        res.position !== 0 && !isNaN(Math.abs(res.position))
                          ? Number(backProfit) + Number(res.position)
                          : Number(backProfit),
                    }
                  : {
                      ...res,
                      position:
                        res.position !== 0 && !isNaN(Math.abs(res.position))
                          ? res.position
                          : 0,
                      newPosition:
                        res.position !== 0 && !isNaN(Math.abs(res.position))
                          ? Number(backLoss) - Number(res.position)
                          : -Number(backLoss),
                    };
              }),
            }
          : rl
      );
    });
  };
  const renderPreminumFancySlip = (item) => {
    return (
      <div className="super-canvas">
        <Table>
          <tbody>
            {/* <tr>
              <td className="quick-batting-wrapper p-0">
                <div className="d-flex align-items-center batting-flex">
                  <div className="d-flex max-batting align-items-center">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked"
                        onChange={(e) =>
                          setAcceptAnyOddsPreminumFancyCheckbox(
                            e.target.checked ? 1 : 0
                          )
                        }
                        checked={
                          acceptAnyOddsPreminumFancyCheckbox ||
                          loginUserDetailData?.sportsBook
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckChecked"
                      >
                        Accept Any Odds
                      </label>
                    </div>
                  </div>

                  <dd className="col-btn ms-2 w-auto ">
                    <a
                      href="#"
                      onClick={() => {
                        setSelectPreminumFancy({});
                        setPreminumFancyAmount("");
                      }}
                    >
                      Cancel
                    </a>
                  </dd>

                  <dd className="quick-bet-confirm ms-2">
                    <strong>{item?.newRunner?.odds}</strong>
                    <span>odds</span>
                  </dd>

                  <dd className="up_down_rate mx-3 quick-input">
                    <input
                      type="text"
                      title={!preminumfancyAmount ? "Enter amount" : ""}
                      onKeyPress={(e) => {
                        isInputNumber(e);
                        if (e.target.value.length > 6) {
                          e.preventDefault();
                        }
                      }}
                      max={1500000}
                      value={preminumfancyAmount}
                      onChange={(e) =>
                        handlePreminumAmount(e.target.value, item)
                      }
                    />
                  </dd>

                  <dd className="col-send">
                    <button
                      className="bookmaker-place-bet-btn btn"
                      style={
                        loaderFancyBet ||
                        details?.sport_setting?.status != "active"
                          ? {
                              cursor: "not-allowed",
                              color: "#fff",
                              background: "gray",
                            }
                          : {
                              cursor: "pointer",
                            }
                      }
                      onClick={() =>
                        handelPlacePreminumFancyBet(selectPreminumFancy)
                      }
                      disabled={
                        loaderFancyBet ||
                        details?.sport_setting?.status != "active"
                      }
                    >
                      Place Bets
                      {loaderFancyBet && (
                        <span style={{ marginLeft: "10px" }}>
                          <Loader
                            Style={{
                              borderTop: "7px solid #FFB80C",
                              borderBottom: "7px solid #FFB80C",
                              width: "10px",
                              height: "10px",
                            }}
                          />
                        </span>
                      )}
                    </button>
                  </dd>
                </div>
                <div className="preset_value p-0 m-0">
                  <ul>
                    {priceData?.map((res, index) => {
                      return (
                        <li key={index + 1}>
                          <a
                            href="#"
                            onClick={() =>
                              handlePreminumAmount(res.value, item)
                            }
                          >
                            {res.value}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </td>
            </tr> */}





            <tr>
            <td className="quick-batting-wrapper p-0">
        <div
          className="d-flex align-items-center batting-flex "
          style={
            {background:"#d3edd0"}
          }
        >
          <div className="w-100 btn-slip">
            <button
              className="bookmakerCancelBtn w-100 fw-5"
              onClick={() => {
                setSelectPreminumFancy({});
                setPreminumFancyAmount("");
              }}
            >
              Cancel
            </button>
          </div>

          <div className="up_down_rate  btn-slip mx-1 quick-input w-100">
            {" "}
            <button>
              <FiMinus size={20} color="#1f72ac" />
            </button>
            <input
              type="number"
              value={item?.newRunner?.odds}
              // onChange={(e) => handelSetPrice(e.target.value, data)}
              max={1500000}
              disabled
            />
            <button className="plus">
              <FiPlus size={20} color="#1f72ac" />
            </button>
          </div>

          <div className="up_down_rate  btn-slip mx-1 quick-input w-100">
            <button>
              <FiMinus size={20} color="#1f72ac" />
            </button>
            <input
              type="number"
              title={!preminumfancyAmount ? "Enter amount" : ""}
              value={preminumfancyAmount}
              onChange={(e) =>
                handlePreminumAmount(e.target.value, item)
              }
              max={1500000}
            />
            <button className="plus">
              <FiPlus size={20} color="#1f72ac" />
            </button>
          </div>

          <div
            className="col-send w-100"
            // style={
            //   !condition
            //     ? {
            //         cursor: "not-allowed",
            //       }
            //     : {}
            // }
          >
            <button
              className="bookmaker-place-bet-btn btn"
              style={
                loaderFancyBet ||
                details?.sport_setting?.status != "active"
                  ? {
                      cursor: "not-allowed",
                      color: "#fff",
                      background: "gray",
                    }
                  : {
                      cursor: "pointer",
                    }
              }
              onClick={() =>
                handelPlacePreminumFancyBet(selectPreminumFancy)
              }
              disabled={
                loaderFancyBet ||
                details?.sport_setting?.status != "active"
              }
            >
              Place Bets
              {/* {betLoader && (
                <span style={{ marginLeft: "10px" }}>
                  <Loader
                    Style={{
                      borderTop: "7px solid #FFB80C",
                      borderBottom: "7px solid #FFB80C",
                      width: "10px",
                      height: "10px",
                    }}
                  />
                </span>
              )} */}
            </button>
          </div>
        </div>
        <div
          className="preset_value p-2 m-0"
          style={
           {background:"#d3edd0"}
          }
        >
          <div className="d-flex align-items-center">
            {Price?.map((money) => {
              return (
                <button
                  className="fancy-quick-btn"
                  onClick={() =>  handlePreminumAmount(money.value, item)}
                >
                  {money?.value}
                </button>
              );
            })}
          </div>
        </div>
      </td>
    </tr>
          </tbody>
        </Table>
      </div>
    );
  };

  const getMatchDetails = async () => {
    let temp = !isEmpty(user)
      ? `?eventId=${params?.eventId}&userId=${user ? user?.user?._id : ""}`
      : `?eventId=${params?.eventId}`;
    const { status, data: response_users } = await apiGet(
      apiPath.matchDetail + temp
    );
    if (status === 200) {
      if (response_users.success) {
        let temp = categoryArray.filter((f) =>
          response_users?.results?.fancyList.some(
            (item) => item.categoryType === f.type
          )
        );
        setFancyCategory([
          { type: "All", name: "All" },
          ...temp,
          { type: "More", name: "More" },
        ]);
        setDetails(response_users?.results);
        if (
          response_users?.results?.hasFancy &&
          response_users?.results?.provider == "diamond"
        ) {
          setShowFancy("fancy");
        } else {
          if (
            response_users?.results?.gameType == "cricket" &&
            !response_users?.results?.eventName.includes(" SRL ")
          ) {
            if (
              response_users?.results?.fancyList?.length == 0 &&
              !isEmpty(user)
            ) {
              if (response_users?.results.fancy == "off") {
                setShowFancy("premium");
              } else if (response_users?.results.premiumFancy == "off") {
                setShowFancy("fancy");
              } else {
                !response_users?.results?.eventName.includes(" T10 ")
                  ? setShowFancy("premium")
                  : setShowFancy("fancy");
              }
            } else {
              if (response_users?.results.fancy == "off") {
                setShowFancy("premium");
              } else if (response_users?.results.premiumFancy == "off") {
                setShowFancy("fancy");
              } else {
                setShowFancy("fancy");
              }
            }
          } else {
            setShowFancy("premium");
          }
        }

        if (response_users?.results?.marketList?.length > 1) {
          let latest = response_users?.results?.marketList?.filter((res) => {
            return res?.market !== "Match Odds" && res?.jsonData?.length > 0;
          });
          if (latest?.length > 0) {
            setMarketNew(latest || []);
            let updated = latest?.map((res) => {
              return res?.marketId;
            });
            if (updated?.length > 0) {
              setMarketIdNew(updated);
            }
          }
          setSelections(
            response_users?.results?.marketList?.filter((res) => {
              return res?.marketId == params?.marketId;
            })[0]?.jsonData || []
          );
        } else {
          setSelections(response_users?.results?.jsonData || []);
        }

        listenBetfairOdds({ results: response_users?.results?.matchOdss });
        setBookmakerSelections(
          response_users?.results?.jsonBookmakerData || []
        );
      }
      if (response_users?.results?.multi_market) {
        setIsMultiMarket(true);
      }
    }
  };

  const getMatchOddsMulti = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getMatchOdds +
        `?marketId=${id?.join(",")}&multi=true&eventId=${params?.eventId}`
    );
    if (status === 200) {
      if (response_users.success) {
        const socket_data = response_users?.results;
        setMarketNew((prev) => {
          return prev?.map((res) => {
            let temp = socket_data?.find((temp) => {
              return temp?.mi == res?.marketId;
            });
            if (temp?.mi == res?.marketId) {
              return {
                ...res,
                back_odds: temp?.rt?.filter((rt) => rt.ib) || [],
                lay_odds: temp?.rt?.filter((rt) => !rt.ib) || [],
                totalMatched: temp?.totalMatched,
                ms: temp?.ms,
                mi: temp?.mi,
              };
            } else {
              return res;
            }
          });
        });
      }
    }
  };

  const getBetPositionMulti = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.betPosition +
        `?eventId=${params?.eventId}&type=${
          details?.gameType
        }&marketId=${id?.join(",")}&multi=true`
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users?.results?.betFair?.length > 0) {
          updatePositionMulti(response_users?.results?.betFair);
        }
      }
    }
  };

  const updatePositionMulti = (object) => {
    setMarketNew((current) =>
      object?.length > 0
        ? current?.map((res) => {
            return {
              ...res,
              jsonData: res?.jsonData?.map((newObj) => {
                let obj = object?.find(
                  (item) => item?.selectionId == newObj?.SelectionId
                );
                if (!isEmpty(obj)) {
                  return {
                    ...newObj,
                    position:
                      obj?.selectionId == newObj?.SelectionId
                        ? obj?.position
                        : 0,
                    newPosition: 0,
                  };
                } else {
                  return {
                    ...newObj,
                    position: 0,
                    newPosition: 0,
                  };
                }
              }),
            };
          })
        : current
    );
  };

  useEffect(() => {
    getMatchDetails();
    getFancyData();
    getMatchData();
  }, [params.eventId, params.marketId]);

  useEffect(() => {
    if (!isEmpty(user)) {
      getCurrentBets();
    }
    if (isEmpty(user)) {
      let timer = setInterval(() => {
        getMatchDetails();
        getFancyData();
        getMatchData();
      }, 60000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [user]);

  useEffect(() => {
    if (marketIdNew?.length > 0) {
      setTimeout(() => {
        getMatchOddsMulti(marketIdNew);
      }, 2000);
    }
  }, [marketIdNew]);

  useEffect(() => {
    if (marketIdNew?.length > 0) {
      setTimeout(() => {
        getBetPositionMulti(marketIdNew);
      }, 3000);
    }
  }, [marketIdNew]);

  const getMatchData = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getMatchOdds + `?marketId=${!isEmpty(id) ? id : params.marketId}`
    );
    if (status === 200) {
      if (response_users.success) {
        const socket_data = response_users?.results;
        if (
          socket_data &&
          socket_data?.eventId &&
          params?.eventId == socket_data?.eventId
        ) {
          if (socket_data.ms) {
            setBetFairMs(socket_data.ms);
          }
          if (socket_data.rt?.length) {
            const back_odds = socket_data?.rt?.filter((rt) => rt.ib) || [];
            const lay_odds = socket_data?.rt?.filter((rt) => !rt.ib) || [];
            setBackOdds(back_odds);
            setLayOdds(lay_odds);
            setTotalMatched(socket_data?.totalMatched);
          }
          if (!isEmpty(id)) {
            setCheckRefresh(true);
          }
        }
      }
    }
  };

  const getFancyData = async () => {
    const { status, data: message } = await apiGet(
      apiPath.getFancyOdds + `?eventId=${params.eventId}`
    );
    if (status === 200) {
      if (message.success) {
        if (message?.results?.length > 0) {
          let temp = categoryArray?.filter((f) =>
            message?.results?.some((item) => item?.categoryType === f.type)
          );
          setFancyCategory(
            temp.length > 0
              ? [
                  { type: "All", name: "All" },
                  ...temp,
                  { type: "More", name: "More" },
                ]
              : [{ type: "All", name: "All" }]
          );
          setFancyPosition((prev) => {
            if (prev?.length > 0) {
              setFancyList(
                message?.results?.map((res) => {
                  let obj = prev?.find(
                    (item) =>
                      item.selectionId == res.selectionId &&
                      item.fancyName == res.name
                  );
                  return {
                    ...res,
                    odds: res,
                    fancyName: res.name,
                    position: !isEmpty(obj) ? obj?.position : "",
                  };
                })
              );
              setFancyCategoryTabArray(
                message?.results?.map((res) => {
                  let obj = prev?.find(
                    (item) =>
                      item.selectionId == res.selectionId &&
                      item.fancyName == res.name
                  );
                  return {
                    ...res,
                    odds: res,
                    fancyName: res.name,
                    position: !isEmpty(obj) ? obj?.position : "",
                  };
                })
              );
            } else {
              setFancyList(
                message?.results?.map((res) => {
                  return { ...res, odds: res };
                })
              );
              setFancyCategoryTabArray(
                message?.results?.map((res) => {
                  return { ...res, odds: res };
                })
              );
            }
            return prev;
          });
        }
      }
    }
  };

  const getBetPosition = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.betPosition + `?eventId=${eventId}&type=${params.sportType}`
    );
    if (status === 200) {
      //BET FAIR
      if (response_users.success) {
        if (response_users?.results?.betFair?.length > 0) {
          secureLocalStorage.setItem(
            "betFair",
            response_users?.results?.betFair
          );
        }
        if (response_users?.results?.bookmaker?.length > 0) {
          secureLocalStorage.setItem(
            "bookmaker",
            response_users?.results?.bookmaker
          );
        }
        if (response_users?.results?.tie?.length > 0) {
          secureLocalStorage.setItem("tie", response_users?.results?.tie);
        }
        if (response_users?.results?.toss?.length > 0) {
          secureLocalStorage.setItem("toss", response_users?.results?.toss);
        }
        updatePosition(response_users?.results);
      }
    }
  };

  const updatePosition = (object) => {
    setSelections((current) => {
      return object?.betFair?.length > 0
        ? current?.map((res) => {
            let obj = object?.betFair?.find(
              (item) => item?.selectionId == res?.SelectionId
            );
            if (!isEmpty(obj)) {
              return {
                ...res,
                position:
                  obj?.selectionId == res?.SelectionId ? obj?.position : 0,
                newPosition: 0,
              };
            } else {
              return {
                ...res,
                position: 0,
                newPosition: 0,
              };
            }
          })
        : current?.map((res) => {
            return {
              ...res,
              position: 0,
              newPosition: 0,
            };
          });
    });
    setBookmakerSelections((current) => {
      return object?.bookmaker?.length > 0
        ? current?.map((res) => {
            let obj = object?.bookmaker?.find(
              (item) => item?.selectionId == res?.selectionID
            );
            return {
              ...res,
              position: obj?.position ? obj?.position : 0,
              newPosition: 0,
            };
          })
        : current?.map((res) => {
            return {
              ...res,
              position: 0,
              newPosition: 0,
            };
          });
    });
  };

  const updatePositionAfterBetPlace = (type, marketName) => {
    if (type == "betFair") {
      secureLocalStorage?.removeItem("betFair");
      secureLocalStorage.setItem(
        "betFair",
        selections?.map((res) => {
          return {
            selectionId: res?.SelectionId,
            position: res?.newPosition,
          };
        })
      );
      setSelections(
        selections?.map((res) => {
          return {
            ...res,
            position: res?.newPosition,
            newPosition: 0,
          };
        })
      );
    } else if (type == "bookmaker") {
      secureLocalStorage?.removeItem("bookmaker");
      secureLocalStorage.setItem(
        "bookmaker",
        bookmakerSelections?.map((res) => {
          return {
            selectionId: res?.selectionID,
            position: res?.newPosition,
          };
        })
      );
      setBookmakerSelections(
        bookmakerSelections?.map((res) => {
          return {
            ...res,
            position: res?.newPosition,
            newPosition: 0,
          };
        })
      );
    } else if (type == "multi") {
      let newly = marketNew?.map((res) => {
        if (res?.market == marketName) {
          return {
            ...res,
            jsonData: res?.jsonData?.map((newObj) => {
              return {
                ...newObj,
                position: newObj?.newPosition,
                newPosition: 0,
              };
            }),
          };
        } else {
          return res;
        }
      });
      setMarketNew(newly);
      secureLocalStorage?.removeItem("betFairMulti");
      secureLocalStorage.setItem(
        "betFairMulti",
        newly
          ?.map((res) => {
            return res?.jsonData;
          })
          ?.flat()
          ?.map((res) => {
            return {
              selectionId: res?.SelectionId,
              position: isNumber(res?.position) ? res?.position : 0,
            };
          })
      );
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (!isEmpty(user)) {
        if (!isEmpty(details)) {
          if (
            !(
              secureLocalStorage.getItem("betFair") ||
              secureLocalStorage.getItem("bookmaker")
            )
          ) {
            getBetPosition();
          } else {
            let obj = {
              betFair:
                secureLocalStorage.getItem("betFair")?.length > 0
                  ? secureLocalStorage.getItem("betFair")
                  : [],
              bookmaker:
                secureLocalStorage.getItem("bookmaker")?.length > 0
                  ? secureLocalStorage.getItem("bookmaker")
                  : [],
              tie:
                secureLocalStorage.getItem("tie")?.length > 0
                  ? secureLocalStorage.getItem("tie")
                  : [],
              toss:
                secureLocalStorage.getItem("toss")?.length > 0
                  ? secureLocalStorage.getItem("toss")
                  : [],
            };
            updatePosition(obj);
          }
          getFancyBetPosition();
          getPremiumFancyBetPosition();
        }
      }
    }, 2000);
  }, [details, user]);

  const amountRange = (details, marketName) => {
    if (details?.gameType == "soccer") {
      if (marketName !== "Match Odds") {
        let obj = details?.matchSetting?.find((res) => {
          return res?.type == "over_under";
        });
        if (!isEmpty(obj)) {
          return obj;
        }
      } else {
        let obj = details?.matchSetting?.find((res) => res?.type == "betFaire");
        if (!isEmpty(obj)) {
          return obj;
        }
      }
    } else {
      let result = details?.matchSetting?.find((res) => {
        return res?.type == marketName;
      });
      if (!isEmpty(result)) {
        let obj = result;
        if (!isEmpty(obj)) {
          return obj;
        }
      } else {
        let obj = details?.matchSetting?.find((res) => res?.type == "betFaire");
        if (!isEmpty(obj)) {
          return obj;
        }
      }
    }
  };
  const [betSlipObject, setBetSlipObject] = useState({});
  const handelShowBetSlip = (
    filter_odds,
    selection,
    betType,
    marketName,
    marketId
  ) => {
    let AmountObj = {};
    if (details?.matchSetting && details?.matchSetting.length) {
      AmountObj = amountRange(details, marketName);
    }
    const filteredData = {
      ...filter_odds,
      price: priceList,
      selectionId: selection.SelectionId,
      teamName: selection.RunnerName,
      betType: betType,
      marketName: marketName,
      marketId: marketId,
      rangeObject: AmountObj,
    };
    setBetSlipObject(filteredData);
  };
  const handelBetFairPositions = (value, slip) => {
    setBetSlipObject((prev) => {
      return { ...prev, activePrice: value };
    });
    let newselection = [...selections]?.map((res) => {
      return {
        ...res,
        position: res?.position || 0,
        newPosition: res?.newPosition || 0,
      };
    });
    let selectionsTemp = [...newselection];
    if (value > 0) {
      if (slip.ib) {
        let backProfit = Number(slip.rt - 1) * Number(value);
        let backLoss = Number(value);
        if (Math.abs(selectionsTemp[0]?.position) > 0) {
          setSelections(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slip.ri) {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + backProfit,
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(rl.position) - backLoss,
                };
              }
            })
          );
        } else {
          setSelections(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slip.ri) {
                return {
                  ...rl,
                  newPosition: Number(backProfit),
                };
              } else {
                return {
                  ...rl,
                  newPosition: -Number(backLoss),
                };
              }
            })
          );
        }
      } else {
        let backProfit = Number(value);
        let backLoss = Number(slip.rt - 1) * Number(value);
        if (Math.abs(selectionsTemp[0]?.position) > 0) {
          setSelections(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slip.ri) {
                return {
                  ...rl,
                  newPosition: Number(rl.position) - backLoss,
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + backProfit,
                };
              }
            })
          );
        } else {
          setSelections(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slip.ri) {
                return {
                  ...rl,
                  newPosition: -Number(backLoss),
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(backProfit),
                };
              }
            })
          );
        }
      }
    }
  };

  const handelPlaceBetfair = async () => {
    const date = moment(new Date()).format("YYYY-MM-DD, h:mm:ss");
    let rangeObject = betSlipObject?.rangeObject;
    if (!isEmpty(user)) {
      setTimeLeft(rangeObject?.betDelay > 1 ? rangeObject?.betDelay - 1 : 1);
      setBetLoader(true);

      const placeFunc = async () => {
        const result = {
          eventId: details?.eventId,
          marketId: betSlipObject?.marketId,
          betPlaceTime: date,
          betPlaceType: "betfair",
          bet: [
            {
              selectionId: betSlipObject?.selectionId,
              teamName: betSlipObject?.teamName,
              bhav: betSlipObject?.rt || null,
              amount: betSlipObject?.activePrice,
              betType: betSlipObject.betType,
            },
          ],
        };

        const { data: response_users2 } = await apiPost(
          apiPath.beforeBetPlace,
          result
        );
        if (response_users2.success) {
          const { status, data: response_users } = await apiPost(
            sportType === "cricket"
              ? apiPath.betfairPlaceBet
              : sportType === "soccer"
              ? apiPath.soccerPlaceBet
              : apiPath.tennisPlaceBet,
            result
          );
          if (status === 200) {
            if (response_users.success) {
              setBetLoader(false);
              getAfterBet();
              toast.success(response_users.message);
              getBetPosition();
              setBetSlipObject({});
              getCurrentBets();
            } else {
              setBetLoader(false);
              toast.error(response_users.message);
            }
          }
        } else {
          setBetLoader(false);
          toast.error(response_users2.message);
        }
      };
      setTimeout(
        () => {
          placeFunc();
        },
        rangeObject?.betDelay > 1 ? (rangeObject?.betDelay - 1) * 1000 : 1000
      );
    }
  };

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
    }
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const handelShowBookmakerBetSlip = (filter_odds, selection, betType) => {
    let AmountObj = {};
    if (details?.matchSetting && details?.matchSetting.length) {
      if (!isEmpty(user)) {
        let obj = details?.matchSetting?.find(
          (res) => res?.type == "bookmaker"
        );
        if (!isEmpty(obj)) {
          AmountObj = obj;
        }
      }
    }
    const filteredData = {
      ...filter_odds,
      price: priceList,
      selectionId: selection.selectionID,
      teamName: selection.runnerName,
      marketId: details?.marketId,
      betType: betType,
      rangeObject: AmountObj,
    };
    setBetSlipObject(filteredData);
  };
  const handelBookmakerPositions = (value, slip) => {
    setBetSlipObject((prev) => {
      return { ...prev, activePrice: value };
    });
    let bookmaker = [...bookmakerSelections];
    if (value >= 0) {
      if (slip.ib) {
        let backProfit = Number((Number(slip?.rt) / 100) * Number(value));
        let backLoss = Number(value);
        if (Math.abs(bookmaker[0]?.position) > 0) {
          setBookmakerSelections(
            bookmaker?.map((rl) => {
              if (rl.selectionID == slip?.selectionId) {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + backProfit,
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(rl.position) - backLoss,
                };
              }
            })
          );
        } else {
          setBookmakerSelections(
            bookmaker?.map((rl) => {
              if (rl.selectionID == slip?.selectionId) {
                return {
                  ...rl,
                  newPosition: Number(backProfit),
                };
              } else {
                return {
                  ...rl,
                  newPosition: -Number(backLoss),
                };
              }
            })
          );
        }
      } else {
        let backProfit = Number(value);
        let backLoss = Number((Number(slip?.rt) / 100) * Number(value));
        if (Math.abs(bookmaker[0]?.position) > 0) {
          setBookmakerSelections(
            bookmaker?.map((rl) => {
              if (rl.selectionID == slip?.selectionId) {
                return {
                  ...rl,
                  newPosition: Number(rl.position) - backLoss,
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + backProfit,
                };
              }
            })
          );
        } else {
          setBookmakerSelections(
            bookmaker?.map((rl) => {
              if (rl.selectionID == slip?.selectionId) {
                return {
                  ...rl,
                  newPosition: -Number(backLoss),
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(backProfit),
                };
              }
            })
          );
        }
      }
    }
  };
  const handelAcceptAnyOddsCheck = (e) => {
    setAcceptAnyOddsCheckbox(e.target.checked ? 1 : 0);
    const temp = bookmakerBetSlipObj;
    temp.acceptAny = e.target.checked ? 1 : 0;
    setBookMakerBetSlipObj((prevState) => temp);
  };
  const handelPlaceBetBookmaker = async () => {
    const date = moment(new Date()).format("YYYY-MM-DD, h:mm:ss");
    const resultData = {
      eventId: details?.eventId,
      marketId: details?.bookmakerMarketId,
      selectionId: betSlipObject?.selectionId?.substring(1),
      betPlaceTime: date,
      teamName: betSlipObject?.teamName,
      bhav: betSlipObject?.rt,
      type: params.sportType,
      amount: betSlipObject?.activePrice,
      betType: betSlipObject?.betType,
      exceptAny: betSlipObject?.exceptAny,
      betPlaceType: "bookmaker",
    };
    if (!isEmpty(user)) {
      setBetLoader(true);
      const { data: response_users2 } = await apiPost(
        apiPath.beforeBetPlace,
        resultData
      );
      if (response_users2.success) {
        const { status, data: response_users } = await apiPost(
          apiPath.bookmakerBet,
          resultData
        );
        if (status === 200) {
          if (response_users.success) {
            getAfterBet();
            setBetLoader(false);
            toast.success(response_users.message);
            setBetSlipObject({});
          } else {
            setBetLoader(false);
            toast.error(response_users.message);
          }
        }
      } else {
        setBetLoader(false);
        toast.error(response_users2.message);
      }
    }
  };
  //******************************* FANCY BET FUNCTIONS ****************************************************//
  const toggleFancyAndPremium = (item) => {
    if (item == "premium") {
      setAcceptAnyOddsPreminumFancyCheckbox(0);
      setPreminumFancyAmount(0);
      setSelectPreminumFancy({});
      setAccordian("");
    }
    setShowFancy(item);
  };
  const handelShowFancyBetSlip = (filter_odds, selection, betType) => {
    const filteredData = {
      ...filter_odds,
      price: priceList,
      activePrice: null,
      selectionId: selection.selectionId,
      fancyName: selection.fancyName,
      betType: betType,
      centralizedId: selection?.centralizedId,
      eventId: selection?.eventId,
      marketId:
        details?.fancyType === "diamond"
          ? selection?.jsonData?.mid
          : selection?.marketId,
      runnerName:
        details?.fancyType === "diamond"
          ? selection?.jsonData?.runnerName
          : selection?.jsonData[0]?.runnerName,
    };
    setFancyBetSlipObj(filteredData);
    if (user && loginUserDetailData?.defaultStake) {
      filteredData.activePrice = loginUserDetailData?.defaultStake;
      // handelFancyPositions(filteredData);
    }
  };

  const handelSetFancyPrice = (item, data) => {
    const filteredData = {
      ...data,
      price: priceList,
      activePrice: item?.value,
      selectionId: data?.selectionId,
      fancyName: data.fancyName,
      betType: data.betType,
      centralizedId: data?.centralizedId,
      eventId: data?.eventId,
      marketId: data?.marketId,
      runnerName: data?.runnerName,
    };
    setFancyBetSlipObj(filteredData);
  };
  const handelChangeFancyAmount = (event, data) => {
    const temp = fancyBetSlipObj;
    temp.activePrice = event.target.value ? +event.target.value : "";
    setFancyBetSlipObj((prevState) => temp);
    // handelFancyPositions(temp);
  };
  const handelClearFancyBetSlip = () => {
    setFancyBetSlipObj({});
  };
  const handelAcceptAnyOddsFancyCheck = (e) => {
    setAcceptAnyOddsFancyCheckbox(e.target.checked ? 1 : 0);
    const temp = fancyBetSlipObj;
    temp.acceptAny = e.target.checked ? 1 : 0;
    setFancyBetSlipObj((prevState) => temp);
  };
  const handelPlaceFancyBet = async (filter_odds, selection, betType) => {
    if (!isEmpty(user)) {
      setBetLoader(true);
      const date = moment(new Date()).format("YYYY-MM-DD, h:mm:ss");
      const resultData = {
        eventId: fancyBetSlipObj?.eventId,
        marketId: fancyBetSlipObj?.marketId,
        centralizedId: fancyBetSlipObj?.centralizedId,
        selectionId: fancyBetSlipObj?.selectionId,
        betPlaceTime: date,
        fancyName: fancyBetSlipObj?.fancyName,
        bhav: fancyBetSlipObj?.pt,
        amount: fancyBetSlipObj?.activePrice,
        betType: fancyBetSlipObj?.betType == "back" ? "Yes" : "No",
        exceptAny: acceptAnyOddsFancyCheckbox,
        betRun: fancyBetSlipObj?.rt,
        runnerName: fancyBetSlipObj?.runnerName,
        betPlaceType: details?.fancyType === "diamond" ? "fancy" : "",
      };
      if (!isEmpty(user)) {
        setLoaderFancyBet(true);
        const { data: response_users2 } = await apiPost(
          apiPath.beforeBetPlace,
          resultData
        );
        if (response_users2.success) {
          let api =
            details.fancyType == "diamond"
              ? apiPath.diamondfancyBetPlace
              : apiPath.fancyBetPlace;
          const { status, data: response_users } = await apiPost(
            api,
            resultData
          );
          if (status === 200) {
            if (response_users.success) {
              getAfterBet();
              setBetLoader(false);
              setLoaderFancyBet(false);
              getFancyBetPosition();
              toast.success(response_users.message);
              setFancyBetSlipObj({});
            } else {   setBetLoader(false);
              toast.error(response_users.message);
              setLoaderFancyBet(false);
            }
          }
        } else {   setBetLoader(false);
          toast.error(response_users2.message);
          setLoaderFancyBet(false);
        }
      }
    } else {
      navigate("/login");
    }
  };
  const handelPlacePreminumFancyBet = async (selectPreminumFancy) => {
    const date = moment(new Date()).format("YYYY-MM-DD, h:mm:ss");
    const resultData = {
      eventId: details?.eventId,
      marketId: details.marketId,
      fancySelectionId: selectPreminumFancy?.newRunner?.id,
      selectionId: selectPreminumFancy.newRunner.marketId,
      betPlaceTime: date,
      runnerName: selectPreminumFancy?.newRunner?.selectionName,
      fancyName: selectPreminumFancy?.marketName,
      bhav: selectPreminumFancy?.newRunner?.odds,
      amount: activeOneClick ? activeOneClickAmount : preminumfancyAmount,
      betType: "back",
      exceptAny: acceptAnyOddsPreminumFancyCheckbox,
      apiSiteSpecifier: selectPreminumFancy?.apiSiteSpecifier,
      apiSiteSelectionId: selectPreminumFancy?.newRunner.apiSiteSelectionId,
      betPlaceType: "premiumFancy",
    };
    if (!isEmpty(user)) {
      setBetLoader(true);
      setLoaderFancyBet(true);
      const { data: response_users2 } = await apiPost(
        apiPath.beforeBetPlace,
        resultData
      );
      if (response_users2.success) {
        const { status, data: response_users } = await apiPost(
          apiPath.preminumFancyBets,
          resultData
        );
        if (status === 200) {
          if (response_users.success) {setBetLoader(false);
            setSelectPreminumFancy({});
            getAfterBet();
            setPreminumFancyAmount(0);
            setLoaderFancyBet(false);
            toast.success(response_users.message);
            getPremiumFancyBetPosition();
            setFancyBetSlipObj({});
          } else {setBetLoader(false);
            toast.error(response_users.message);
            setLoaderFancyBet(false);
          }
        }
      } else {setBetLoader(false);
        setSelectPreminumFancy({});
        setPreminumFancyAmount(0);
        toast.error(response_users2.message);
        setLoaderFancyBet(false);
      }
    } else {setBetLoader(false);
      setSelectPreminumFancy({});
      setPreminumFancyAmount(0);
    }
  };
  //****** BET FAIR BACK DATA ******//
  const renderBackOdds = (selection, odds, marketName, marketId, betFairMs) => {
    const the_odds = odds;
    const filter_odds = the_odds?.filter(
      (todd) => todd?.ri === selection?.SelectionId
    );
    const conditionFunc = (odds) => {
      return (
        filter_odds?.length > 0 &&
        details?.matchOdds == "on" &&
        odds !== null &&
        Number(odds) < amountRangeObject?.betfair?.oddsLimit &&
        details?.sport_setting?.status == "active"
      );
    };
    return (
      <>
        <td className="relative_for p-0">
          {betFairMs != 1 ? (
            <div class="suspend">
              <span>
                {betFairMs == 9
                  ? "Ball Running"
                  : betFairMs == 2
                  ? "In Active"
                  : betFairMs == 3
                  ? "Suspend"
                  : betFairMs == 4
                  ? "Closed"
                  : "Suspend"}
              </span>
            </div>
          ) : (
            <table>
              <tr>
                <td
                  value={filter_odds[2]?.rt}
                  id={"back_odds_" + filter_odds[2]?.rt}
                  className={`back-3 bet-cursor mobile_hide border-0 ${
                    betSlipObject?.rt == filter_odds[2]?.rt && "active-back"
                  } ${
                    document
                      .getElementById("back_odds_" + filter_odds[2]?.rt)
                      ?.getAttribute("value") != filter_odds[2]?.rt &&
                    filter_odds[2]?.rt &&
                    "spark"
                  }`}
                  onClick={() => {
                    if (conditionFunc(filter_odds[2]?.rt)) {
                      handelShowBetSlip(
                        filter_odds[2],
                        selection,
                        "back",
                        marketName,
                        marketId
                      );
                    }
                  }}
                  style={
                    !conditionFunc(filter_odds[2]?.rt)
                      ? { cursor: "not-allowed" }
                      : { cursor: "pointer" }
                  }
                >
                  <a>
                    {filter_odds[2]?.rt || null}
                    <span>{filter_odds[2]?.rt > 0 && filter_odds[2]?.bv}</span>
                  </a>
                </td>
                <td
                  value={filter_odds[1]?.rt}
                  id={"back_odds_" + filter_odds[1]?.rt}
                  className={`back-2 bet-cursor mobile_hide border-0 ${
                    document
                      .getElementById("back_odds_" + filter_odds[1]?.rt)
                      ?.getAttribute("value") != filter_odds[1]?.rt &&
                    filter_odds[1]?.rt &&
                    "spark"
                  }`}
                  onClick={() => {
                    if (conditionFunc(filter_odds[1]?.rt)) {
                      handelShowBetSlip(
                        filter_odds[1],
                        selection,
                        "back",
                        marketName,
                        marketId
                      );
                    }
                  }}
                  style={
                    !conditionFunc(filter_odds[1]?.rt)
                      ? { cursor: "not-allowed" }
                      : { cursor: "pointer" }
                  }
                >
                  <a>
                    {filter_odds[1]?.rt || null}
                    <span>{filter_odds[1]?.rt > 0 && filter_odds[1]?.bv}</span>
                  </a>
                </td>
                <td
                  value={filter_odds[0]?.rt}
                  id={"back_odds_" + filter_odds[0]?.rt}
                  className={`back-1s bet-cursor border-0 ${
                    document
                      .getElementById("back_odds_" + filter_odds[0]?.rt)
                      ?.getAttribute("value") != filter_odds[0]?.rt &&
                    filter_odds[0]?.rt &&
                    "spark"
                  }`}
                  onClick={() => {
                    if (conditionFunc(filter_odds[0]?.rt)) {
                      handelShowBetSlip(
                        filter_odds[0],
                        selection,
                        "back",
                        marketName,
                        marketId
                      );
                    }
                  }}
                  style={
                    !conditionFunc(filter_odds[0]?.rt)
                      ? { cursor: "not-allowed" }
                      : { cursor: "pointer" }
                  }
                >
                  <a>
                    {filter_odds[0]?.rt || null}
                    <span>{filter_odds[0]?.rt > 0 && filter_odds[0]?.bv}</span>
                  </a>
                </td>
              </tr>
            </table>
          )}
        </td>
      </>
    );
  };
  //****** BET FAIR LAY DATA ******//
  const renderLayOdds = (selection, odds, marketName, marketId, betFairMs) => {
    const the_odds = odds;
    const filter_odds = the_odds.filter(
      (todd) => todd.ri === selection.SelectionId
    );

    const conditionFunc = (odds) => {
      return (
        filter_odds?.length > 0 &&
        details?.matchOdds == "on" &&
        odds !== null &&
        Number(odds) < amountRangeObject?.betfair?.oddsLimit &&
        details?.sport_setting?.status == "active"
      );
    };
    return (
      <>
        <td className="relative_for p-0">
          {betFairMs != 1 ? (
            <div class="suspend">
              <span>
                {betFairMs == 9
                  ? "Ball Running"
                  : betFairMs == 2
                  ? "In Active"
                  : betFairMs == 3
                  ? "Suspend"
                  : betFairMs == 4
                  ? "Closed"
                  : "Suspend"}
              </span>
            </div>
          ) : (
            <table>
              <tr>
                <td
                  value={filter_odds[0]?.rt}
                  id={"lay_odds_" + filter_odds[0]?.rt}
                  className={`lay-1 bet-cursor border-0 ${
                    document
                      .getElementById("lay_odds_" + filter_odds[0]?.rt)
                      ?.getAttribute("value") != filter_odds[0]?.rt &&
                    filter_odds[0]?.rt &&
                    "spark"
                  }`}
                  onClick={() => {
                    if (conditionFunc(filter_odds[0]?.rt)) {
                      handelShowBetSlip(
                        filter_odds[0],
                        selection,
                        "lay",
                        marketName,
                        marketId
                      );
                    }
                  }}
                  style={
                    !conditionFunc(filter_odds[0]?.rt)
                      ? { cursor: "not-allowed" }
                      : { cursor: "pointer" }
                  }
                >
                  <a>
                    {filter_odds[0]?.rt || null}
                    <span>{filter_odds[0]?.bv || null}</span>
                  </a>
                </td>
                <td
                  value={filter_odds[1]?.rt}
                  id={"lay_odds_" + filter_odds[1]?.rt}
                  className={`lay-2 bet-cursor mobile_hide border-0 ${
                    document
                      .getElementById("lay_odds_" + filter_odds[1]?.rt)
                      ?.getAttribute("value") != filter_odds[1]?.rt &&
                    filter_odds[1]?.rt &&
                    "spark"
                  }`}
                  onClick={() => {
                    if (conditionFunc(filter_odds[1]?.rt)) {
                      handelShowBetSlip(
                        filter_odds[1],
                        selection,
                        "lay",
                        marketName,
                        marketId
                      );
                    }
                  }}
                  style={
                    !conditionFunc(filter_odds[1]?.rt)
                      ? { cursor: "not-allowed" }
                      : { cursor: "pointer" }
                  }
                >
                  <a>
                    {filter_odds[1]?.rt || null}
                    <span>{filter_odds[1]?.bv || null}</span>
                  </a>
                </td>
                <td
                  value={filter_odds[2]?.rt}
                  id={"lay_odds_" + filter_odds[2]?.rt}
                  className={`lay-3 bet-cursor mobile_hide border-0 ${
                    document
                      .getElementById("lay_odds_" + filter_odds[2]?.rt)
                      ?.getAttribute("value") != filter_odds[2]?.rt &&
                    filter_odds[2]?.rt &&
                    "spark"
                  }`}
                  onClick={() => {
                    if (conditionFunc(filter_odds[2]?.rt)) {
                      handelShowBetSlip(
                        filter_odds[2],
                        selection,
                        "lay",
                        marketName,
                        marketId
                      );
                    }
                  }}
                  style={
                    !conditionFunc(filter_odds[2]?.rt)
                      ? { cursor: "not-allowed" }
                      : { cursor: "pointer" }
                  }
                >
                  <a>
                    {filter_odds[2]?.rt || null}
                    <span>{filter_odds[2]?.bv || null}</span>
                  </a>
                </td>
              </tr>
            </table>
          )}
        </td>
      </>
    );
  };

  //******************************* BOOKMAKER  UI ****************************************************//

  //****** BOOKMAKER BACK DATA ******//
  const renderBookmarkBack = (selection) => {
    const the_odds = bookmakerBackOdds;
    const filter_odds = the_odds
      .filter((todd) => todd.name == selection.runnerName)
      .sort((a, b) => {
        return a - b;
      });
    const conditionFunc = (odds) => {
      return (
        filter_odds?.length > 0 &&
        details?.matchOdds == "on" &&
        odds !== null &&
        Number(odds) < amountRangeObject?.bookmaker?.oddsLimit &&
        details?.sport_setting?.status == "active"
      );
    };
    return (
      <Fragment>
        <td className="pe-0 " colSpan="3" style={{ position: "relative" }}>
          <dl className="back-gradient">
            <dd
              id="back_3"
              className="mobile_hide"
              onClick={() => {
                if (conditionFunc(filter_odds[2]?.rt)) {
                  handelShowBookmakerBetSlip(filter_odds[0], selection, "back");
                }
              }}
              style={
                !conditionFunc(filter_odds[2]?.rt)
                  ? { cursor: "not-allowed" }
                  : { cursor: "pointer" }
              }
            >
              <p> {filter_odds[2]?.rt || null} </p>
            </dd>
            <dd
              id="back_2"
              onClick={() => {
                if (conditionFunc(filter_odds[1]?.rt)) {
                  handelShowBookmakerBetSlip(filter_odds[0], selection, "back");
                }
              }}
              style={
                !conditionFunc(filter_odds[1]?.rt)
                  ? { cursor: "not-allowed" }
                  : { cursor: "pointer" }
              }
            >
              <p> {filter_odds[1]?.rt || null}</p>
            </dd>
            <dd
              id="back_1"
              className=""
              onClick={() => {
                if (conditionFunc(filter_odds[0]?.rt)) {
                  handelShowBookmakerBetSlip(filter_odds[0], selection, "back");
                }
              }}
              style={
                !conditionFunc(filter_odds[0]?.rt)
                  ? { cursor: "not-allowed" }
                  : { cursor: "pointer" }
              }
            >
              <a> {filter_odds[0]?.rt || null}</a>
            </dd>
          </dl>
        </td>
      </Fragment>
    );
  };
  //****** BOOKMAKER LAY DATA ******//
  const renderBookmarkLay = (selection) => {
    const the_odds = bookmakerLayOdds;
    const filter_odds = the_odds
      .filter((todd) => todd.name == selection.runnerName)
      .sort((a, b) => {
        return a - b;
      });
    const conditionFunc = (odds) => {
      return (
        filter_odds?.length > 0 &&
        details?.matchOdds == "on" &&
        odds !== null &&
        Number(odds) < amountRangeObject?.bookmaker?.oddsLimit &&
        details?.sport_setting?.status == "active"
      );
    };
    return (
      <Fragment>
        <td className="ps-0" colSpan="3" style={{ position: "relative" }}>
          <dl className="lay-gradient">
            <dd
              id="lay_1"
              onClick={() => {
                if (conditionFunc(filter_odds[0]?.rt)) {
                  handelShowBookmakerBetSlip(filter_odds[0], selection, "lay");
                }
              }}
              style={
                !conditionFunc(filter_odds[0]?.rt)
                  ? { cursor: "not-allowed" }
                  : { cursor: "pointer" }
              }
            >
              <a>{filter_odds[0]?.rt || null}</a>
            </dd>
            <dd
              id="lay_2"
              onClick={() => {
                if (conditionFunc(filter_odds[1]?.rt)) {
                  handelShowBookmakerBetSlip(filter_odds[0], selection, "lay");
                }
              }}
              style={
                !conditionFunc(filter_odds[1]?.rt)
                  ? { cursor: "not-allowed" }
                  : { cursor: "pointer" }
              }
            >
              <p>{filter_odds[1]?.rt || null}</p>
            </dd>
            <dd
              id="lay_3"
              className="mobile_hide"
              onClick={() => {
                if (conditionFunc(filter_odds[2]?.rt)) {
                  handelShowBookmakerBetSlip(filter_odds[0], selection, "lay");
                }
              }}
              style={
                !conditionFunc(filter_odds[2]?.rt)
                  ? { cursor: "not-allowed" }
                  : { cursor: "pointer" }
              }
            >
              <p>{filter_odds[2]?.rt || null}</p>
            </dd>
          </dl>
        </td>
      </Fragment>
    );
  };

  const renderFancyLay = (the_data) => {
    return (
      <td className="ps-0 " colSpan="" style={{ position: "relative" }}>
        <dl className="lay-gradient">
          <dd
            id="lay_1"
            className="p-0"
            onClick={() =>
              !the_data?.odds?.rt[0]?.rt || details?.fancy == "off"
                ? // ||
                  // details?.matchSetting?.find((res) => res?.type === "fancy")
                  //   ?.oddsLimit <= the_data?.odds?.rt[0]?.rt
                  null
                : activeOneClick
                ? handelPlaceFancyBet(the_data?.odds?.rt[0], the_data, "lay")
                : handelShowFancyBetSlip(the_data?.odds?.rt[0], the_data, "lay")
            }
            style={
              !the_data?.odds?.rt[0]?.rt || details?.fancy == "off"
                ? // ||
                  // details?.matchSetting?.find((res) => res?.type === "fancy")
                  //   ?.oddsLimit <= the_data?.odds?.rt[0]?.rt
                  {
                    cursor: "not-allowed",
                    backgroundColor: "#faa9ba",
                    color: "#000",
                    flexDirection: "column",
                  }
                : {
                    cursor: "pointer",
                    backgroundColor: "#faa9ba",
                    color: "#000",
                    flexDirection: "column",
                  }
            }
          >
            <p className="border-0 p-0 mt-0 rounded-0">
              {the_data?.odds?.rt[0]?.rt}
            </p>

            <p
              className="border-0 p-0 mt-0 rounded-0"
              style={{ fontSize: "11px", fontWeight: "normal" }}
            >
              {the_data?.odds?.rt[0]?.pt}
            </p>
          </dd>
        </dl>
      </td>
    );
  };
  const renderFancyBack = (the_data) => {
    return (
      <td className="ps-0" colSpan="" style={{ position: "relative" }}>
        <dl className="back-gradient">
          <dd
            id="back_1"
            className="px-0"
            onClick={() =>
              !the_data?.odds?.rt[1]?.rt || details?.fancy == "off"
                ? // ||
                  // details?.matchSetting?.find((res) => res?.type === "fancy")
                  //   ?.oddsLimit <= the_data?.odds?.rt[1]?.rt
                  null
                : activeOneClick
                ? handelPlaceFancyBet(the_data?.odds?.rt[1], the_data, "back")
                : handelShowFancyBetSlip(
                    the_data?.odds?.rt[1],
                    the_data,
                    "back"
                  )
            }
            style={
              !the_data?.odds?.rt[1]?.rt || details?.fancy == "off"
                ? // ||
                  // details?.matchSetting?.find((res) => res?.type === "fancy")
                  //   ?.oddsLimit <= the_data?.odds?.rt[1]?.rt
                  {
                    cursor: "not-allowed",
                    backgroundColor: "#72bbef",
                    color: "#000",
                    flexDirection: "column",
                  }
                : {
                    cursor: "pointer",
                    backgroundColor: "#72bbef",
                    color: "#000",
                    flexDirection: "column",
                  }
            }
          >
            <p className="border-0 p-0 mt-0 rounded-0">
              {the_data?.odds?.rt[1]?.rt}
            </p>
            <p
              className="border-0 p-0 mt-0 rounded-0"
              style={{ fontSize: "11px", fontWeight: "normal" }}
            >
              {the_data?.odds?.rt[1]?.pt}
            </p>
          </dd>
        </dl>
      </td>
    );
  };
  const renderFancySlip = (fancyData) => {
    return (
      // <td colSpan="7" className="quick-batting-wrapper p-0">
      //   <div
      //     className="d-flex align-items-center batting-flex py-2 px-3"
      //     style={
      //       fancyData?.betType == "back"
      //         ? { backgroundColor: "#BEDDF4" }
      //         : fancyData?.betType == "lay"
      //         ? { backgroundColor: "#F3DCE2" }
      //         : {}
      //     }
      //   >
      //     <div className="d-flex max-batting align-items-center justify-content-start">
      //       {/* <d1>
      //         <span>
      //           <a href="#">Max</a>
      //         </span>
      //       </d1> */}
      //       <div className="form-check">
      //         <input
      //           className="form-check-input"
      //           type="checkbox"
      //           id="flexCheckChecked"
      //           onChange={(e) => handelAcceptAnyOddsFancyCheck(e)}
      //           checked={fancyData?.acceptAny || loginUserDetailData?.fancyBet}
      //         />
      //         <label className="form-check-label" htmlFor="flexCheckChecked">
      //           Accept Any Odds
      //         </label>
      //       </div>
      //     </div>

      //     <dd className="col-btn mx-3 ">
      //       {/* <a href="#">Cancel</a> */}
      //       <button
      //         className="bookmakerCancelBtn w-100 fw-5"
      //         onClick={() => handelClearFancyBetSlip()}
      //       >
      //         Cancel
      //       </button>
      //     </dd>

      //     <dd
      //       className="quick-bet-confirm text-center"
      //       style={{ height: "33px" }}
      //     >
      //       <strong className="">
      //         {fancyData?.rt}
      //         <br />
      //       </strong>

      //       <span>{fancyData?.pt}</span>
      //     </dd>

      //     <dd className="up_down_rate mx-3 quick-input">
      //       <input
      //         type="text"
      //         value={fancyData?.activePrice ? fancyData?.activePrice : ""}
      //         onChange={(e) => handelChangeFancyAmount(e, fancyData)}
      //         onKeyPress={(e) => {
      //           isInputNumber(e);
      //           if (e.target.value.length > 6) {
      //             e.preventDefault();
      //           }
      //         }}
      //         max={1500000}
      //         style={
      //           !fancyData?.activePrice
      //             ? { border: "1px solid red", borderRadius: "4px" }
      //             : {}
      //         }
      //         title={!fancyData?.activePrice ? "Enter amount" : ""}
      //       />
      //     </dd>

      //     <dd
      //       className="col-send"
      //       style={
      //         !fancyData.activePrice || loaderFancyBet
      //           ? {
      //               cursor: "not-allowed",
      //             }
      //           : {}
      //       }
      //     >
      //       <button
      //         className="bookmaker-place-bet-btn btn"
      //         style={
      //           !fancyData?.activePrice ||
      //           loaderFancyBet ||
      //           details?.sport_setting?.status != "active"
      //             ? {
      //                 cursor: "not-allowed",
      //                 color: "#fff",
      //                 background: "gray",
      //               }
      //             : {
      //                 cursor: "pointer",
      //               }
      //         }
      //         onClick={() => handelPlaceFancyBet()}
      //         disabled={
      //           !fancyData?.activePrice ||
      //           loaderFancyBet ||
      //           details?.sport_setting?.status != "active"
      //         }
      //       >
      //         Place Bets
      //         {loaderFancyBet && (
      //           <span style={{ marginLeft: "10px" }}>
      //             <Loader
      //               Style={{
      //                 borderTop: "7px solid #FFB80C",
      //                 borderBottom: "7px solid #FFB80C",
      //                 width: "10px",
      //                 height: "10px",
      //               }}
      //             />
      //           </span>
      //         )}
      //       </button>
      //     </dd>
      //   </div>
      //   <div
      //     className="preset_value p-0 m-0"
      //     style={
      //       fancyData?.betType == "back"
      //         ? { backgroundColor: "#D4E8F8" }
      //         : fancyData?.betType == "lay"
      //         ? { backgroundColor: "#FAEFF2" }
      //         : {}
      //     }
      //   >
      //     <ul>
      //       {fancyData?.price.map((money) => {
      //         return (
      //           <li key={money?.id}>
      //             <button
      //               className="price-button-pay-slip"
      //               onClick={() => handelSetFancyPrice(money, fancyData)}
      //               style={
      //                 fancyData.activePrice === money.value
      //                   ? {
      //                       background: "#444444",
      //                       color: "#f8d61c ",
      //                       cursor: "not-allowed",
      //                       padding: "6px 10px",
      //                       minWidth: "114px",
      //                       fontWeight: "300",
      //                     }
      //                   : {
      //                       padding: "6px 10px",
      //                       minWidth: "114px",
      //                       fontWeight: "300",
      //                     }
      //               }
      //               disabled={fancyData.activePrice === money.value}
      //             >
      //               {money?.value}
      //             </button>
      //           </li>
      //         );
      //       })}
      //     </ul>
      //   </div>
      // </td>

      <td colSpan="7" className="quick-batting-wrapper p-0">
        <div
          className="d-flex align-items-center batting-flex "
          style={
            fancyData?.betType == "back"
              ? { backgroundColor: "#beddf4" }
              : fancyData?.betType == "lay"
              ? { backgroundColor: "#F3DCE2" }
              : {}
          }
        >
          <div className="w-100 btn-slip">
            <button
              className="bookmakerCancelBtn w-100 fw-5"
              onClick={() => handelClearFancyBetSlip()}
            >
              Cancel
            </button>
          </div>

          <div className="up_down_rate btn-slip mx-1 quick-input w-100">
            {" "}
            <button>
              <FiMinus size={20} color="#1f72ac" />
            </button>
            <input
              type="text"
              readOnly={"readOnly"}
              value={`${fancyData?.rt}/${fancyData?.pt}`}
              max={1500000}
              disabled
            />
            <button className="plus">
              <FiPlus size={20} color="#1f72ac" />
            </button>
          </div>

          <div className="up_down_rate  btn-slip mx-1 quick-input w-100">
            <button>
              <FiMinus size={20} color="#1f72ac" />
            </button>
            <input
              type="number"
              value={fancyData?.activePrice ? fancyData?.activePrice : ""}
              onChange={(e) => handelChangeFancyAmount(e, fancyData)}
              max={1500000}
            />
            <button className="plus">
              <FiPlus size={20} color="#1f72ac" />
            </button>
          </div>

          <div className="col-send w-100">
            <button
              className="bookmaker-place-bet-btn btn"
              style={
                !fancyData?.activePrice ||
                loaderFancyBet ||
                details?.sport_setting?.status != "active"
                  ? {
                      cursor: "not-allowed",
                    }
                  : {
                      cursor: "pointer",
                    }
              }
              onClick={() => handelPlaceFancyBet()}
              disabled={
                !fancyData?.activePrice ||
                loaderFancyBet ||
                details?.sport_setting?.status != "active"
              }
            >
              Place Bets
              {/* {loaderFancyBet && (
                <span style={{ marginLeft: "10px" }}>
                  <Loader
                    Style={{
                      borderTop: "7px solid #FFB80C",
                      borderBottom: "7px solid #FFB80C",
                      width: "10px",
                      height: "10px",
                    }}
                  />
                </span>
              )} */}
            </button>
          </div>
        </div>
        <div
          className="preset_value p-2 m-0"
          style={
            fancyData?.betType == "back"
              ? { backgroundColor: "#beddf4" }
              : fancyData?.betType == "lay"
              ? { backgroundColor: "#F3DCE2" }
              : {}
          }
        >
          <div className="d-flex align-items-center">
            {Price?.map((money) => {
              return (
                <button
                  className="fancy-quick-btn"
                  onClick={() => handelSetFancyPrice(money, fancyData)}
                >
                  {money?.value}
                </button>
              );
            })}
          </div>
        </div>
      </td>
    );
  };

  const handelBetFairPositionsMulti = (value, slip) => {
    setBetSlipObject((prev) => {
      return { ...prev, activePrice: value };
    });

    let selectionsTemp = [...marketNew]
      ?.filter((res) => {
        return res?.mi == slip?.marketId;
      })
      ?.map((res) => {
        return {
          ...res,
          position: res?.position || 0,
          newPosition: res?.position || 0,
        };
      });

    let newArray = selectionsTemp[0]?.jsonData;
    if (value > 0) {
      if (slip.ib) {
        let backProfit = Number(slip.rt - 1) * Number(value);
        let backLoss = Number(value);
        if (Math.abs(newArray[0]?.position) > 0) {
          newArray = newArray?.map((rl) => {
            if (rl.SelectionId == slip.ri) {
              return {
                ...rl,
                newPosition: Number(rl.position) + backProfit,
              };
            } else {
              return {
                ...rl,
                newPosition: Number(rl.position) - backLoss,
              };
            }
          });
        } else {
          newArray = newArray?.map((rl) => {
            if (rl.SelectionId == slip.ri) {
              return {
                ...rl,
                newPosition: Number(backProfit),
              };
            } else {
              return {
                ...rl,
                newPosition: -Number(backLoss),
              };
            }
          });
        }
      } else {
        let backProfit = Number(value);
        let backLoss = Number(slip.rt - 1) * Number(value);
        if (Math.abs(newArray[0]?.position) > 0) {
          newArray = newArray((rl) => {
            if (rl.SelectionId == slip.ri) {
              return {
                ...rl,
                newPosition: Number(rl.position) - backLoss,
              };
            } else {
              return {
                ...rl,
                newPosition: Number(rl.position) + backProfit,
              };
            }
          });
        } else {
          newArray = newArray?.map((rl) => {
            if (rl.SelectionId == slip.ri) {
              return {
                ...rl,
                newPosition: -Number(backLoss),
              };
            } else {
              return {
                ...rl,
                newPosition: Number(backProfit),
              };
            }
          });
        }
      }
    }
    setMarketNew((prev) => {
      return prev?.map((res) => {
        if (res?.market == slip?.marketName) {
          return { ...res, jsonData: newArray };
        } else {
          return res;
        }
      });
    });
  };

  const onCancel = (type) => {
    setBetSlipObject({});
    if (type == "betFair") {
      setSelections((current) =>
        current.map((res) => {
          return {
            ...res,
            newPosition: 0,
          };
        })
      );
    } else if (type == "bookmaker") {
      setBookmakerSelections((current) => {
        return current.map((res) => {
          return {
            ...res,
            newPosition: 0,
          };
        });
      });
    } else if (type == "multi") {
      setMarketNew((current) =>
        current?.map((res) => {
          return {
            ...res,
            jsonData: res?.jsonData?.map((newObj) => {
              return {
                ...newObj,
                position: Math.abs(newObj?.position) > 0 ? newObj?.position : 0,
                newPosition: 0,
              };
            }),
          };
        })
      );
    }
  };
  const handelChangeAmount = (e, data, type) => {};
  const handelSetPrice = () => {};
  return (
    <Layout>
      <div className=" games_center_block">
        {details?.scoreId && details?.scoreId != 0 && (
          <div className="py-2 py-md-2">
            <iframe
              className="responsive-iframe w-100"
              style={{ height: "180px" }}
              src={`https://www.satsports.net/score_widget/index.html?id=${details?.scoreId}`}
            ></iframe>
          </div>
        )}

        {details?.matchOdds == "on" &&
        selections?.length > 0 &&
        back_odds?.length > 0 &&
        lay_odds?.length > 0 ? (
          <BetFair
            selections={selections}
            marketName={"Match Odds"}
            renderBackOdds={renderBackOdds}
            renderLayOdds={renderLayOdds}
            betFairMs={betFairMs}
            BackOdds={back_odds || []}
            LayOdds={lay_odds || []}
            marketId={params?.marketId}
            amountRangeBetFair={amountRangeBetFair}
            totalMatched={totalMatched}
            user={user}
            betSlipObject={betSlipObject}
            onCancel={onCancel}
            amountRange={amountRangeObject?.betfair}
            handelSetPrice={handelBetFairPositions}
            handelPlace={handelPlaceBetfair}
            details={details}
            betLoader={betLoader}
            type="betfair"
          />
        ) : (
          ""
        )}
        {marketNew?.length > 0 &&
          marketNew?.map((res) => {
            return (
              <BetFair
                selections={res?.jsonData}
                marketName={res?.market}
                renderBackOdds={renderBackOdds}
                renderLayOdds={renderLayOdds}
                marketId={res?.mi}
                betFairMs={!isEmpty(res?.ms) ? res?.ms : 1}
                BackOdds={
                  res?.back_odds?.length > 0 ? res?.back_odds || [] : []
                }
                type="multi"
                LayOdds={res?.lay_odds?.length > 0 ? res?.lay_odds || [] : []}
                amountRangeBetFair={amountRangeBetFair}
                totalMatched={res?.totalMatched}
                user={user}
                betSlipObject={betSlipObject}
                onCancel={onCancel}
                handelSetPrice={handelBetFairPositionsMulti}
                handelPlace={handelPlaceBetfair}
                details={details}
                betLoader={betLoader}
              />
            );
          })}
        {bookmakerSelections?.length > 0 && details?.jsonBookmakerData && (
          <Bookmaker
            bookmakerSelections={bookmakerSelections}
            renderBookmarkBack={renderBookmarkBack}
            bookmakerBackOdds={bookmakerBackOdds}
            bookmakerLayOdds={bookmakerLayOdds}
            renderBookmarkLay={renderBookmarkLay}
            details={details}
            betSlipObject={betSlipObject}
            onCancel={onCancel}
            handelAcceptAnyOddsCheck={handelAcceptAnyOddsCheck}
            handelSetPrice={handelBookmakerPositions}
            user={user}
            amountRange={amountRangeObject?.bookmaker}
            handelPlace={handelPlaceBetBookmaker}
            betLoader={betLoader}
          />
        )}
        {/* 8/11/22 */}

        <div className={`sportsbook-head  mt-3 d-flex align-items-center `}>
          {fancyList?.length > 0 && (
            <span className="winner-sportsbook" style={{ cursor: "pointer" }}>
              <h4
                className="in-play mb-0"
                onClick={() => toggleFancyAndPremium("fancy")}
              >
                <span id="sportsBookName">Fancy Bet</span>
                <OverlayTrigger
                  trigger="click"
                  placement="right"
                  overlay={popoverFancy}
                >
                  <div className="info-popover"></div>
                </OverlayTrigger>
              </h4>
            </span>
          )}
          {!isEmpty(user)
            ? preminumFancy?.length > 0 && (
                <h4
                  className="in-play mb-0 "
                  onClick={() => toggleFancyAndPremium("premium")}
                  style={{ cursor: "pointer" }}
                >
                  <strong className="sportstrong"></strong>{" "}
                  <span id="sportsBookName">Sportsbook</span>
                  <OverlayTrigger
                    trigger="click"
                    placement="right"
                    overlay={popoverPremium}
                  >
                    <div className="info-popover"></div>
                  </OverlayTrigger>
                </h4>
              )
            : ""}
        </div>

        {/* PREMIUM CRICKET START */}
        {showFancy == "fancy" && fancyList?.length > 0 ? (
          <div className="premier-tab fancy-premier-tab">
            <Tabs
              defaultActiveKey="All"
              id=""
              activeKey={key}
              onSelect={(k) => {
                onChangeKey(k);
              }}
              className="mb-1"
            >
              {fancyCategory?.length > 0 &&
                fancyCategory?.map((item, index) => {
                  return (
                    <Tab eventKey={item.type} title={item.name} key={index + 1}>
                      <FancyTabs
                        amountRangeFancy={amountRangeObject?.fancy}
                        key={index + 1}
                        fancyCategoryTabArray={
                          key == "All" ? fancyList : fancyCategoryTabArray
                        }
                        renderFancyLay={renderFancyLay}
                        renderFancyBack={renderFancyBack}
                        fancyBetSlipObj={fancyBetSlipObj}
                        renderFancySlip={renderFancySlip}
                        sportType={sportType}
                      />
                    </Tab>
                  );
                })}
            </Tabs>
          </div>
        ) : null}
        {/* FANCY BET END */}

        {/* PREMIUM CRICKET START */}
        {!isEmpty(user) ? (
          showFancy == "premium" && preminumFancy?.length > 0 ? (
            <div className="premier-tab winner-super-accordian">
              <Tabs
                defaultActiveKey="All"
                activeKey={fancyKey}
                onSelect={(e) => {
                  setFancyKey(e);
                }}
                id=""
                className="mb-1"
              >
                <Tab eventKey={"All"} title={"All"}>
                  <PreminumFancyTable
                    setAccordian={setAccordian}
                    accordian={accordian}
                    renderPreminumFancySlip={renderPreminumFancySlip}
                    data={preminumFancy}
                    preminumfancyAmount={preminumfancyAmount}
                    setPreminumFancyAmount={setPreminumFancyAmount}
                    setAcceptAnyOddsPreminumFancyCheckbox={
                      setAcceptAnyOddsPreminumFancyCheckbox
                    }
                    setSelectPreminumFancyFunction={
                      setSelectPreminumFancyFunction
                    }
                    setSelectPreminumFancy={setSelectPreminumFancy}
                    selectPreminumFancy={selectPreminumFancy}
                    manualFancy={details?.manualFancy}
                    matchStatus={details?.status}
                    oddsLimit={
                      details.matchSetting.length > 0
                        ? details.matchSetting.find(
                            (res) => res?.type === "sportBook"
                          )?.oddsLimit
                        : loginUserDetailData?.matchSetting?.find(
                            (res) =>
                              res?.type === "sportBook" &&
                              res?.sportType === details?.gameType
                          )?.oddsLimit
                    }
                  />
                </Tab>
              </Tabs>
            </div>
          ) : null
        ) : (
          ""
        )}
      </div>
    </Layout>
  );
};

export default MatchOdd;

const Price = [
  { id: 1, value: 100 },
  { id: 2, value: 200 },
  { id: 3, value: 500 },
  { id: 4, value: 500 },
  { id: 5, value: 10000 },
  { id: 6, value: 25000 },
  { id: 7, value: 50000 },
  { id: 8, value: 100000 },
];
