import Evolution from "../assets/images/New/evolution.png";
import Ezugi from "../assets/images/New/41.png";
import Third from "../assets/images/New/12.png";
import c34 from "../assets/images/New/34.png";
import c28 from "../assets/images/New/28.png";
import c31 from "../assets/images/New/31.png";
import c03 from "../assets/images/New/03.png";
import c02 from "../assets/images/New/02.png";
import c33 from "../assets/images/New/33.png";
import c39 from "../assets/images/New/39.png";
import c21 from "../assets/images/New/21.png";
import c09 from "../assets/images/New/09.png";
import c25 from "../assets/images/New/25.png";
import c15 from "../assets/images/New/15.png";
import c20 from "../assets/images/New/20.png";
import c10 from "../assets/images/New/10.png";
import c05 from "../assets/images/New/05.png";
import c19 from "../assets/images/New/19.png";
import c17 from "../assets/images/New/17.png";
import c01 from "../assets/images/New/01.png";
import c11 from "../assets/images/New/11.png";
import c24 from "../assets/images/New/24.png";
import c22 from "../assets/images/New/22.png";
import c40 from "../assets/images/New/40.png";
import c43 from "../assets/images/New/43.png";
import c23 from "../assets/images/New/23.png";
import c14 from "../assets/images/New/14.png";
import c16 from "../assets/images/New/16.png";
import c29 from "../assets/images/New/29.png";
import c37 from "../assets/images/New/37.png";
import c35 from "../assets/images/New/35.png";
import c36 from "../assets/images/New/36.png";
import c32 from "../assets/images/New/32.png";
import c06 from "../assets/images/New/06.png";
import c30 from "../assets/images/New/30.png";
import c08 from "../assets/images/New/08.png";
import c18 from "../assets/images/New/18.png";
import c38 from "../assets/images/New/38.png";
import c27 from "../assets/images/New/27.png";
import c26 from "../assets/images/New/26.png";

const obj = {
  user_status: {
    owner: "OW",
    sub_owner: "SOW",
    super_admin: "SUA",
    admin: "AD",
    sub_admin: "SAD",
    senior_super: "SSM",
    super_agent: "SA",
    agent: "AG",
    user: "CL",
  },
  user_next_status: {
    owner: "sub_owner",
    sub_owner: "super_admin",
    super_admin: "admin",
    admin: "sub_admin",
    sub_admin: "senior_super",
    senior_super: "super_agent",
    super_agent: "agent",
    agent: "user",
  },
  market_category: {
    1: "Market",
    3: "Bookmakers",
    5: "ManualOdds",
    10: "Win Toss",
    2: "Session",
    6: "Over by Over Session Market",
    7: "Player",
    9: "Wicket",
    11: "Bowler Session",
    8: "Last Digit ODD",
    4: "LineMarket",
    14: "Premium ODDs",
  },
  betCheckObj: {
    4: "Cricket",
    2: "Tennis",
    1: "Soccer",
    3: "Casino",
  },
  streamingUrl:
    "https://ss247.life/api/8dac71c43808368b2ba1dbb7bfb6fd76bfaddaf4/Nstreamapi.php",
};

export default obj;

export const priceData = [
  {
    id: 1,
    value: 10,
  },
  {
    id: 2,
    value: 50,
  },
  {
    id: 3,
    value: 100,
  },
  {
    id: 4,
    value: 200,
  },
  {
    id: 5,
    value: 1000,
  },
  {
    id: 6,
    value: 5000,
  },
];
export const headerData = [
  {
    class: "text-start",
    title: "Sport Name",
    widht: "",
  },
  {
    class: "text-start",
    title: "Event Name",
    widht: "",
  },
  {
    class: "text-start",
    title: "Market Name",
    widht: "",
  },
  {
    class: "text-start",
    title: "Selection",
    widht: "",
  },
  {
    class: "text-start",
    title: "Type",
    widht: "",
  },
  {
    class: "text-start",
    title: "Odds Req.",
    widht: "",
  },
  {
    class: "text-start",
    title: "Stake",
    widht: "",
  },
  {
    class: "text-start",
    title: "Place Time",
    widht: "",
  }
];

export const betFairData = {
  data: [
    {
      bmi: "1.205777993",
      ip: 1,
      mi: 2572760,
      ms: 1,
      eti: "4",
      eid: "31859931",
      grt: "2022-11-01T08:20:58.203Z",
      rt: [
        {
          ri: 10301,
          rt: 1.49,
          bv: 9134.79,
          pr: 0,
          af: 0,
          st: 1,
          ib: true,
        },
        {
          ri: 10301,
          rt: 1.48,
          bv: 30747.88,
          pr: 1,
          af: 0,
          st: 1,
          ib: true,
        },
        {
          ri: 10301,
          rt: 1.47,
          bv: 16964.29,
          pr: 2,
          af: 0,
          st: 1,
          ib: true,
        },
        {
          ri: 10301,
          rt: 1.5,
          bv: 11305.92,
          pr: 0,
          af: 0,
          st: 1,
          pt: 0,
          ib: false,
        },
        {
          ri: 10301,
          rt: 1.51,
          bv: 72391.18,
          pr: 1,
          af: 0,
          st: 1,
          pt: 0,
          ib: false,
        },
        {
          ri: 10301,
          rt: 1.52,
          bv: 10997.18,
          pr: 2,
          af: 0,
          st: 1,
          pt: 0,
          ib: false,
        },
        {
          ri: 448,
          rt: 3,
          bv: 5652.96,
          pr: 0,
          af: 0,
          st: 1,
          ib: true,
        },
        {
          ri: 448,
          rt: 2.98,
          bv: 43.56,
          pr: 1,
          af: 0,
          st: 1,
          ib: true,
        },
        {
          ri: 448,
          rt: 2.96,
          bv: 36885.43,
          pr: 2,
          af: 0,
          st: 1,
          ib: true,
        },
        {
          ri: 448,
          rt: 3.05,
          bv: 4641.48,
          pr: 0,
          af: 0,
          st: 1,
          pt: 0,
          ib: false,
        },
        {
          ri: 448,
          rt: 3.1,
          bv: 14535.58,
          pr: 1,
          af: 0,
          st: 1,
          pt: 0,
          ib: false,
        },
        {
          ri: 448,
          rt: 3.15,
          bv: 7993.98,
          pr: 2,
          af: 0,
          st: 1,
          pt: 0,
          ib: false,
        },
      ],
    },
  ],
  messageType: "match_odds",
};
export const categoryArray = [
  { type: 1, name: "Market" },
  { type: 3, name: "Bookmakers" },
  { type: 5, name: "ManualOdds" },
  { type: 10, name: "Win Toss" },
  { type: 2, name: "Session" },
  { type: 6, name: "Over by Over Session Market" },
  { type: 7, name: "Player" },
  { type: 9, name: "Wicket" },
  { type: 11, name: "Bowler Session" },
  { type: 8, name: "Last Digit ODD" },
  { type: 4, name: "LineMarket" },
  { type: 14, name: "Premium ODDs" },
];


export const easyToGoCasino = [
  {
    product: "EVOLUTION GAMING (S)",
    code: "1006",
    productType: "LIVE CASINO",
    productTypeCode: "1",
    image: "assets/images/home/Evolution-s.jpg",
  },
  // {
  //   product: "EVOLUTION GAMING",
  //   code: "141",
  //   productType: "LIVE CASINO",
  //   productTypeCode: "1",
  //   image: "assets/images/home/evolution_casino.jpg",
  // },
  // {
  //   product: "ORIENTAL GAMING",
  //   code: "98",
  //   productType: "LIVE CASINO",
  //   productTypeCode: "1",
  //   image: "assets/images/home/banner_supernowa-half.jpg",
  // },
  // {
  //   product: "GREATWALL",
  //   code: "23",
  //   productType: "SLOT",
  //   productTypeCode: "2",
  //   image: "assets/images/home/banner_7mojos-half.jpg",
  // },
  // {
  //   product: "918KAYA",
  //   code: "13",
  //   productType: "SLOT",
  //   productTypeCode: "2",
  //   image: "assets/images/home/banner_horsebook-half.jpg",
  // },
  // {
  //   product: "PLAYBOY",
  //   code: "82",
  //   productType: "SLOT",
  //   productTypeCode: "2",
  //   image: "assets/images/home/banner_minesweeper-half.jpg",
  // },
  // {
  //   product: "CLUBSUNCITY",
  //   code: "67",
  //   productType: "SLOT",
  //   productTypeCode: "2",
  //   image: "assets/images/home/banner_skytrader-half.jpg",
  // },
  {
    product: "GENESIS",
    code: "131",
    productType: "SLOT",
    productTypeCode: "2",
    image: "assets/images/home/banner_32card-half.jpg",
  },
  {
    product: "MICROGAMING",
    code: "35",
    productType: "SLOT",
    productTypeCode: "2",
    image: "assets/images/home/banner_rummy-half.jpg",
  },
  {
    product: "GAMINGSOFT SLOT",
    code: "2",
    productType: "SLOT",
    productTypeCode: "2",
    image: "assets/images/home/banner_binary-half.jpg",
  },
  // {
  //   product: "GAMEPLAY",
  //   code: "151",
  //   productType: "SLOT",
  //   productTypeCode: "1",
  //   image: "assets/images/home/banner_andarBahar-half.jpg",
  // },
  {
    product: "KMG",
    code: "143",
    productType: "SLOT",
    productTypeCode: "2",
    image: "assets/images/home/banner_sicbo-half.jpg",
  },
  {
    id: 1,
    product: "KINGMAKER",
    code: "117",
    productType: "SLOT",
    productTypeCode: "2",
    image: "assets/images/home/banner_cardMatka-half.jpg",
  },
  {
    product: "CMD",
    code: "88",
    productType: "SPORTBOOK",
    productTypeCode: "3",
    image: "assets/images/home/banner_sevenUpDown-half.jpg",
  },
  {
    product: "1G Poker",
    code: "146",
    productType: "CARD AND BOARD",
    productTypeCode: "5",
    image: "assets/images/home/banner_bpoker-half.jpg",
  },
];

export const lotteryArray = [
  {
    img: "",
    name: "evolution",
  },
  {
    img: "",
    name: "ezugi",
  },
  {
    img: "",
    name: "20-20 teenpati",
  },
  {
    img: "",
    name: "20-20 dragon tiger",
  },
  {
    img: "",
    name: "evolution",
  },
  {
    img: "",
    name: "evolution",
  },
  {
    img: "",
    name: "evolution",
  },
  {
    img: "",
    name: "evolution",
  }
];

export const casinoArray = [
  {
    img: Evolution,
    name: "evolution",
    type: "evolution",
    live: "international",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-006",
    isLobbyTrue:true
  },
  {
    img: Ezugi,
    name: "ezugi",
    live: "international",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-001",
    isLobbyTrue:true
  },
  {
    img: c25,
    name: "BG",
    live: "international",
    platForm: "BG",
    gameType: "",
    casinoType: "",
  },
  {
    img: c25,
    name: "BTG",
    live: "international",
    platForm: "BTG",
    gameType: "",
    casinoType: "",
    
  },
  {
    img: c25,
    name: "DRAGOONSOFT",
    live: "international",
    platForm: "DRAGOONSOFT",
    gameType: "",
    casinoType: "",
    
  },
  {
    img: c25,
    name: "FC",
    live: "international",
    platForm: "FC",
    gameType: "",
    casinoType: "",
    
  },
  {
    img: c25,
    name: "HOTROAD",
    live: "international",
    platForm: "HOTROAD",
    gameType: "",
    casinoType: "",
    
  },
  {
    img: c25,
    name: "JDB",
    live: "international",
    platForm: "JDB",
    gameType: "",
    casinoType: "",
    
  },
  {
    img: c25,
    name: "JILI",
    live: "international",
    platForm: "JILI",
    gameType: "",
    casinoType: "",
    
  },
  {
    img: c25,
    name: "KINGMAKER",
    live: "international",
    platForm: "KINGMAKER",
    gameType: "",
    casinoType: "",
    
  },
  {
    img: c25,
    name: "NETENT",
    live: "international",
    platForm: "NETENT",
    gameType: "",
    casinoType: "",
    
  },{
    img: c25,
    name: "NLC",
    live: "international",
    platForm: "NLC",
    gameType: "",
    casinoType: "",
    
  },{
    img: c25,
    name: "PG",
    live: "international",
    platForm: "PG",
    gameType: "",
    casinoType: "",
    
  },{
    img: c25,
    name: "PP",
    live: "international",
    platForm: "PP",
    gameType: "",
    casinoType: "",
    
  },{
    img: c25,
    name: "PT",
    live: "international",
    platForm: "PT",
    gameType: "",
    casinoType: "",
    
  },{
    img: c25,
    name: "RT",
    live: "international",
    platForm: "RT",
    gameType: "",
    casinoType: "",
    
  },{
    img: c25,
    name: "SPADE",
    live: "international",
    platForm: "SPADE",
    gameType: "",
    casinoType: "",
    
  },{
    img: c25,
    name: "SPRIBE",
    live: "international",
    platForm: "SPRIBE",
    gameType: "",
    casinoType: "",
    
  },{
    img: c25,
    name: "YL",
    live: "international",
    platForm: "YL",
    gameType: "",
    casinoType: "",
    
  },
  {
    img: Third,
    name: "20-20 teenpati",
    type: "teenpatti",
    live: "ourcasino",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-011",
    table: "81",
  },
  {
    img: c34,
    name: "20-20 dragon tiger",
    type: "dragontiger",
    live: "ourcasino",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-006",
    table: "31",
  },
  {
    img: c28,
    name: "baccarat",
    type: "baccarat",
    live: "ourcasino",
    platForm: "SEXYBCRT",
    gameType: "LIVE",
    casinoType: "MX-LIVE-001",
    table: "1",
  },
  {
    img: c31,
    name: "lucky 7-a",
    type: "Lucky",
    live: "ourcasino",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-008",
    table:"7001"
  },
  {
    img: c03,
    name: "andhar bahar - a",
    live: "ourcasino",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-032",
  },
  {
    img: c02,
    name: "32 cards - a",
    type: "other",
    live: "ourcasino",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-039",
  },
  {
    img: c33,
    name: "20-20 poker - a",
    type:"Poker",
    live: "ourcasino",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-049",
  },
  {
    img: c39,
    name: "baccarat - a",
    type: "baccarat",
    live: "ourcasino",
    platForm: "PG",
    gameType: "TABLE",
    casinoType: "PG-TABLE-001",
  },
  {
    img: c21,
    name: "1day teen patti",
    type: "teenpatti",
    live: "ourcasino",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-046",
  },
  {
    img: c09,
    name: "dragon tiger",
    type: "dragontiger",
    live: "ourcasino",
    platForm: "PP",
    gameType: "LIVE",
    casinoType: "PP-LIVE-042",
  },
  {
    img: c25,
    name: "fast lucky - 7",
    type: "Lucky",
    live: "ourcasino",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-008",
    table:"7002"
  },
  {
    img: c15,
    name: "1 day dragon tiger",
    type: "other",
    live: "ourcasino",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-003",
    table:"2001"
  },
  {
    img: c20,
    name: "mulfis teen patti",
    type: "teenpatti",
    live: "ourcasino",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-016",
    
  },
  {
    img: c10,
    name: "dtl - a",
    live: "ourcasino",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-027",
  },
  {
    img: c05,
    name: "fast dragon tiger",
    type: "dragontiger",
    live: "ourcasino",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-012",
  },
  {
    img: c19,
    name: "casino war",
    type: "other",
    live: "ourcasino",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-030",
  },
  {
    img: c17,
    name: "casino meter",
    type: "other",
    live: "ourcasino",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-SLOT-114",
  },
  {
    img: c01,
    name: "29 card baccarat",
    type: "baccarat",
    live: "ourcasino",
    platForm: "PP",
    gameType: "SLOT",
    casinoType: "PP-LIVE-001",
  },
  {
    img: c11,
    name: "instant worli",
    type: "other",
    live: "ourcasino",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-022",
  },
  {
    img: c24,
    name: "point teen patti",
    type: "other",
    live: "ourcasino",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-087",
  },
  {
    img: c22,
    name: "joker teen patti",
    type: "other",
    live: "ourcasino",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-010",
  },
  {
    img: c22,
    name: "heads & tails",
    type: "virtual",
    live: "virtual",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-036",
  },
  {
    img: c22,
    name: "dream cather",
    type: "virtual",
    live: "virtual",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-035",
    
  },
  {
    img: c22,
    name: "lucky 0 to 9",
    type: "Lucky",
    live: "virtual",
    platForm: "FC",
    gameType: "SLOT",
    casinoType: "FC-SLOT-021",
  },
  {
    img: c40,
    name: "betgames casino",
    live: "international",
    platForm: "BG",
    gameType: "",
    casinoType: "",
  },
  // {
  //   img: c43,
  //   name: "tvbet",
  //   live: "international",
  // },
  {
    img: c23,
    name: "Aviator",
    type: "bollywood",
    live: "ourcasino",
    platForm: "SPRIBE",
    gameType: "EGAME",
    casinoType: "SPRIBE-EGAME-001",
  },
  {
    img: c14,
    name: "3 card judgment - a",
    type: "other",
    live: "ourcasino",
    platForm: "BG",
    gameType: "LIVE",
    casinoType: "BG-LIVE-010",
  },
  {
    img: c16,
    name: "1 card meter",
    type: "other",
    live: "ourcasino",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-107",
  },
  {
    img: c16,
    name: "Crazy Time",
    type: "other",
    live: "ourcasino",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-006",
  },
  {
    img: c29,
    name: "trio",
    type: "other",
    live: "ourcasino",
    platForm: "JDB",
    gameType: "SLOT",
    casinoType: "JDB-SLOT-093",
  },
  {
    img: c37,
    name: "andar bahar - c",
    type: "andarbahar",
    live: "ourcasino",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-204",
  },
  {
    img: c35,
    name: "Roullete",
    type: "other",
    live: "ourcasino",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-004",
    table:"1001"
  },
  {
    img: c36,
    name: "20-20 poker - b",
    type:"Poker",
    live: "ourcasino",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-006",
  },
  {
    img: c32,
    name: "dtl teenpatti",
    type: "teenpatti",
    live: "ourcasino",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-001",
  },
  // {
  //   img: c06,
  //   name: "ina mina dika",
  //   type: "bollywood",
  //   live: "ourcasino",
  // },
  {
    img: c30,
    name: "lucky 7 - b",
    type: "Lucky",
    live: "ourcasino",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-008",
    table:"7003"
  },
  {
    img: c08,
    name: "Spin a Win",
    type: "bollywood",
    live: "ourcasino",
    platForm: "PT",
    gameType: "LIVE",
    casinoType: "PT-LIVE-015",
  },
  {
    img: c18,
    name: "20-20 card race",
    live: "ourcasino",
    platForm: "JILI",
    gameType: "TABLE",
    casinoType: "JILI-TABLE-016",
  },
  {
    img: c38,
    name: "baccarat - c",
    type: "baccarat",
    live: "ourcasino",
    platForm: "HOTROAD",
    gameType: "LIVE",
    casinoType: "HOTROAD-LIVE-001",
    table:"1"
  },
  {
    img: c27,
    name: "32 cards - b",
    type: "other",
    live: "ourcasino",
    platForm: "KINGMAKER",
    gameType: "TABLE",
    casinoType: "KM-TABLE-039",
  },
  {
    img: c26,
    name: "kbc",
    type: "other",
    live: "ourcasino",
    platForm: "EVOLUTION",
    gameType: "LIVE",
    casinoType: "EVOLUTION-LIVE-014",
  },
];