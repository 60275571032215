import { isEmpty } from "lodash";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import helpers from "../utils/helpers";
import OddsListing from "./OddsListing";
const MatchListSingle = ({
  item,
  scoreNew,
  obj,
  loginUserDetailData,
  handelAddMultiMarket,
  type,
  time,
}) => {
  const location = useLocation();
  return (
    <dl className="game-list-col d-flex">
      <dt
        className="col-matched d-flex justify-content-between align-items-center"
        style={{ fontSize: "11px", lineHeight: "normal" }}
      >
        <Link
          className="d-flex align-items-center"
          to={`/markets/${type}/${item?.eventId}/${item?.marketId}`}
          state={{
            item,
            seriesName: item?.seriesName,
          }}
        >
          <span className="d-flex">
            {!isEmpty(scoreNew) ? (
              <span className="game-titlesmall">
                {item?.eventName.split("v")[0]}
                <span className="in_play">{scoreNew?.score}</span>
                {item?.eventName.split("v")[1]}
              </span>
            ) : (
              <span className="game-titlesmall">{item?.eventName}</span>
            )}
          </span>

          {obj.days == 0 &&
          obj.hours == 0 &&
          obj?.years == 0 &&
          obj?.months == 0 &&
          obj.minutes > 0 &&
          obj?.minutes < loginUserDetailData?.beforeInPlay ? (
            <span className="game-list-time">Starting in {obj?.minutes}'</span>
          ) : item.status == "in_play" ? (
            <span className="in_play">In-Play {item?.timeElapsed}</span>
          ) : (
            <span className="game-list-time">
              {time == "inplay"
                ? helpers.dateFormat(item.eventDateTime)
                : helpers.matchDateTime(item.eventDateTime)}
            </span>
          )}
        </Link>
        <div className="d-flex align-items-center">
          {/* {item?.channel !== "false" && <span className="game-live"></span>} */}
          {item?.bookmakerRunners && item?.bookmakerRunners.length > 0 && (
            <span class="game-bookmakers">BM</span>
          )}
          {item?.fancyList && item?.fancyList.length > 0 && (
            <span className="game-fancy">F</span>
          )}
          {item?.bookmakerRunners &&
            item?.bookmakerRunners?.length > 0 &&
            item?.runners &&
            item?.runners?.length > 0 &&
            item?.fancyList &&
            item?.fancyList.length > 0 && (
              <span class="game-sportbook">S</span>
            )}{" "}
        </div>
      </dt>
      <dd className="col-mdl text-end"></dd>
      <OddsListing matchData={item} />
      <dd className="col-info">
        {["/inplay", "/multi-markets"].includes(location?.pathname) ? (
          ""
        ) : (
          <Link
            className={item?.multiMarket ? "add-pin-position" : "add-pin"}
            onClick={() => handelAddMultiMarket(item?.eventId)}
          ></Link>
        )}
      </dd>
    </dl>
  );
};

export default MatchListSingle;
