import React from "react";
import Layout from "./Layout";
import Image from "../assets/images/virtualCricket.png";
const VirtualSports = () => {
  return (
    <Layout news={true}>
      <div
        className="col-center games_center_block"
        style={{ display: "flex",marginTop:"10px" }}
      >
        <div
          style={{
            position: "relative",
            marginRight: "10px",
            width: "100%",
            height: "fit-content",
          }}
        >
          <img src={Image} className="casino-icon" />
          <div class="casino-name">Universe T1 League 08/10/2024</div>
        </div>
        <div
          style={{
            position: "relative",
            marginRight: "10px",
            width: "100%",
            height: "fit-content",
          }}
        >
          <img src={Image} className="casino-icon" />
          <div class="casino-name">Universe T1 League 08/10/2024</div>
        </div>
      </div>
    </Layout>
  );
};

export default VirtualSports;
