import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";

export const MyAccountLayout = ({
  type,
  heading,
  children,
  isFilter,
  filter,
  setFilter,
  getData,
  isTable,
}) => {
  return (
    <div style={{ padding: "10px 3px", background: "white" }}>
      {isFilter && (
        <Container>
          <Row
            style={{
              background: "#e0e6e6",
              padding: "10px 10px 0",
              border: "1px solid #000501",
              borderRadius: "5px",
              marginBottom: "10px",
              paddingBottom: "10px",
            }}
          >
            {" "}
            <Col lg={3} sm={3}>
              <Form.Select
                value={filter?.dbType}
                onChange={(e) =>
                  setFilter((prev) => {
                    return { ...prev, dbType: e.target.value };
                  })
                }
                aria-label="Default select example"
              >
                <option value={""}>Data Source</option>{" "}
                <option value={"liveData"}>LIVE DATA</option>{" "}
                <option value={"backupData"}>BACKUP DATA</option>{" "}
                <option value={"oldData"}>OLD DATA</option>
              </Form.Select>
            </Col>
            {["bet", "rolling"].includes(type) && (
              <Col lg={3} sm={3}>
                <Form.Select
                  value={filter?.sports}
                  onChange={(e) =>
                    setFilter((prev) => {
                      return { ...prev, sports: e.target.value };
                    })
                  }
                  aria-label="Default select example"
                >
                  <option value={""}>Sport Type</option>{" "}
                  <option value={"4"}>Cricket</option>{" "}
                  <option value={"2"}>Tennis</option>{" "}
                  <option value={"1"}>Soccer</option>{" "}
                  <option value={"3"}>Casino</option>{" "}
                </Form.Select>
              </Col>
            )}
            <Col lg={3} sm={3}>
              <Form.Control
                type="date"
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    fromPeriod: e.target.value,
                  })
                }
                max={new Date().toISOString().split("T")[0]}
                value={filter.fromPeriod}
              />
            </Col>
            <Col lg={3} sm={3}>
              <Form.Control
                type="date"
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    toPeriod: e.target.value,
                  })
                }
                min={
                  filter?.fromPeriod
                    ? new Date(filter?.fromPeriod).toISOString().split("T")[0]
                    : new Date()
                }
                max={new Date().toISOString().split("T")[0]}
                value={filter?.toPeriod}
              />
            </Col>
            <Col lg={3} sm={3} className="d-flex align-items-end">
              <Button onClick={() => getData()} className="theme_dark_btn">
                Get P&L
              </Button>
            </Col>
          </Row>{" "}
        </Container>
      )}
      <div className="w-100">
        <div className="account-table-header">{heading}</div>
        <div className="p-3">
          {isTable && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "8px",
                justifyContent: "start",
                fontSize: "14px",
              }}
            >
              <span>Show</span>
              <select
                style={{ margin: "0 5px", width: "10%", padding: "4px" }}
                className="select-input"
              >
                <option>10</option>
              </select>{" "}
              <span>entries</span>
            </div>
          )}
          {children}
          {isTable && (
            <div className="d-flex justify-content-between align-items-center">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "8px",
                  marginTop: "8px",
                  justifyContent: "center",
                  fontSize: "14px",
                }}
              >
                Showing 0 to 0 of 0 entreis
              </div>{" "}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "14px",
                  width: "45%",
                }}
              >
                <span style={{ width: "25%", textAlign: "center" }}>First</span>
                <span style={{ width: "25%", textAlign: "center" }}>
                  Previous
                </span>
                <span style={{ width: "25%", textAlign: "center" }}>Next</span>
                <span style={{ width: "25%", textAlign: "center" }}>Last</span>
              </div>{" "}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
