import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import { apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import { FaLongArrowAltRight } from "react-icons/fa";
import { BsArrowDownSquare, BsArrowRightSquare } from "react-icons/bs";
const MatchSidebar = (props) => {
  const params = useParams();
  const [sportType, setSportType] = useState("");
  const [tournamentData, setTournamentData] = useState([]);
  const [getSeriesId, setSeriesId] = useState("");
  const [matches, setMatches] = useState([]);
  const [getSeriesName, setSeriesName] = useState("");
  const navigate = useNavigate();
  const [getMatchId, setMatchId] = useState(
    params.eventId ? params.eventId : ""
  );
  const [getMarketId, setMarketId] = useState(
    params.marketId ? params.marketId : ""
  );
  const getTournamentData = async (type) => {
    const { status, data: response_users } = await apiGet(apiPath.seriesList, {
      gameType: type !== "" ? type : sportType,
    });
    if (status === 200) {
      if (response_users.success) {
        setTournamentData(response_users.results);
      }
    }
  };
  const getMatches = async (id) => {
    id && setSeriesId(id);
    const { status, data: response_users } = await apiGet(
      apiPath.matchList + "?gameType=" + sportType + "&seriesId=" + id
    );
    if (status === 200) {
      if (response_users.success) {
        setMatches(response_users.results);
      }
    }
  };

  return (
    <>
      <div className="match-sidebar">
        <ul className="mb-0 sidebar-menu">
          <div>
            <h6>Sports</h6>
            {tournamentData?.length > 0 ? (
              tournamentData.map((item, index) => {
                return (
                  <>
                    <li
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      key={index + 1}
                      onClick={() => {
                        if (
                          getSeriesId == item.seriesId &&
                          matches?.length > 0
                        ) {
                          setMatches([]);
                          setSeriesName("");
                          setSeriesId("");
                        } else {
                          setSeriesName(item.seriesName);
                          getMatches(item.seriesId);
                        }
                      }}
                    >
                      <Link to="#">{item.seriesName}</Link>
                      {getSeriesId == item.seriesId && matches?.length > 0 ? (
                        <BsArrowDownSquare color="#83AE17" size={18} />
                      ) : (
                        <BsArrowRightSquare color="#83AE17" size={18} />
                      )}
                    </li>
                    {getSeriesId == item.seriesId && matches?.length > 0 && (
                      <div>
                        {matches &&
                          matches.map((item, index) => {
                            return (
                              <li
                                style={{ paddingLeft: "24px" }}
                                key={index + 1}
                                onClick={() => {
                                  setMatches([]);
                                  setSeriesName("");
                                  setSeriesId("");
                                  navigate(
                                    `/markets/${sportType}/${item?.eventId}/${item?.marketId}`
                                  );
                                }}
                              >
                                <FaLongArrowAltRight />{" "}
                                <Link to="#">{item.eventName}</Link>
                              </li>
                            );
                          })}
                      </div>
                    )}
                  </>
                );
              })
            ) : (
              <>
                <li
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSportType("cricket");
                    getTournamentData("cricket");
                  }}
                >
                  <span>Cricket</span>{" "}
                  <BsArrowRightSquare color="#83AE17" size={18} />
                </li>{" "}
                <li
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSportType("soccer");
                    getTournamentData("soccer");
                  }}
                >
                  <span>Soccer</span>{" "}
                  <BsArrowRightSquare color="#83AE17" size={18} />
                </li>{" "}
                <li
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSportType("tennis");
                    getTournamentData("tennis");
                  }}
                >
                  <span>Tennis</span>{" "}
                  <BsArrowRightSquare color="#83AE17" size={18} />
                </li>
              </>
            )}
          </div>
        </ul>
      </div>
    </>
  );
};

export default MatchSidebar;
