import React, { useState,useContext } from "react";
import Trasnsparent from "../assets/images/transparent-login.gif";
import { casinoArray } from "../utils/constants";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";

const CasinoSection = ({ game, sports }) => {
  const {user,launchCasino, } = useContext(AuthContext);
  const navigate = useNavigate();

  const [casinoTab, setCasinoTab] = useState("");
  return (
    <div className="sports">
      <ul class="nav nav-tabs-casino game-nav-bar menu-list-casino mt-3">
        <li class="nav-item casinoname-item text-center aaa ng-star-inserted">
          <a
            onClick={() => setCasinoTab("")}
            class={casinoTab == "" ? "nav-link active" : "nav-link"}
          >
            <div>Popular</div>
          </a>
        </li>
        <li class="nav-item casinoname-item text-center aaa ng-star-inserted">
          <a
            onClick={() => setCasinoTab("Teen")}
            class={casinoTab == "Teen" ? "nav-link active" : "nav-link"}
          >
            <div>Teen Patti</div>
          </a>
        </li>
        <li class="nav-item casinoname-item text-center aaa ng-star-inserted">
          <a
            onClick={() => setCasinoTab("Lucky")}
            class={casinoTab == "Lucky" ? "nav-link active" : "nav-link"}
          >
            <div>Lucky 7</div>
          </a>
        </li>
        <li class="nav-item casinoname-item text-center aaa ng-star-inserted">
          <a
            onClick={() => setCasinoTab("Dragon")}
            class={casinoTab == "Dragon" ? "nav-link active" : "nav-link"}
          >
            <div>Dragon Tiger</div>
          </a>
        </li>
        <li class="nav-item casinoname-item text-center aaa ng-star-inserted">
          <a
            onClick={() => setCasinoTab("Baccarat")}
            class={casinoTab == "Baccarat" ? "nav-link active" : "nav-link"}
          >
            <div>Baccarat</div>
          </a>
        </li>
        <li class="nav-item casinoname-item text-center aaa ng-star-inserted">
          <a
            onClick={() => setCasinoTab("Andar")}
            class={casinoTab == "Andar" ? "nav-link active" : "nav-link"}
          >
            <div>Andar Bahar</div>
          </a>
        </li>
        <li class="nav-item casinoname-item text-center aaa ng-star-inserted">
          <a
            onClick={() => setCasinoTab("Poker")}
            class={casinoTab == "Poker" ? "nav-link active" : "nav-link"}
          >
            <div>Poker</div>
          </a>
        </li>
        <li class="nav-item casinoname-item text-center aaa ng-star-inserted">
          <a
            onClick={() => setCasinoTab("Bollywood")}
            class={casinoTab == "Bollywood" ? "nav-link active" : "nav-link"}
          >
            <div>Bollywood</div>
          </a>
        </li>
        <li class="nav-item casinoname-item text-center aaa ng-star-inserted">
          <a
            onClick={() => setCasinoTab("Region")}
            class={casinoTab == "Region" ? "nav-link active" : "nav-link"}
          >
            <div>Region</div>
          </a>
        </li>
        <li class="nav-item casinoname-item text-center aaa ng-star-inserted">
          <a
            onClick={() => setCasinoTab("Other")}
            class={casinoTab == "Other" ? "nav-link active" : "nav-link"}
          >
            <div>Other</div>
          </a>
        </li>
        <li class="nav-item casinoname-item text-center aaa ng-star-inserted">
          <a
            onClick={() => setCasinoTab("Virtual")}
            class={casinoTab == "Virtual" ? "nav-link active" : "nav-link"}
          >
            <div>Virtual</div>
          </a>
        </li>
      </ul>
      <div className="main-con">
        <div className="row home">
          {casinoArray?.map((res) => {
            return casinoTab == "" ? (
              <div class="col col-4 col-md-3 col-sm-3 col-xl-3 pointer 11 ng-star-inserted"
              onClick={() => {
                if (!isEmpty(user)) {
                  launchCasino({
                    platForm: res?.platForm,
                    gameType: res?.gameType,
                    casinoType: res?.casinoType,
                    isLobbyTrue: res?.isLobbyTrue,
                    table:res?.table
                  });
                } else {
                  navigate("/login");
                }
              }}
              >
                <div style={{ position: "relative" }}>
                  <div class="clip"></div>
                  <div class="clip2">
                    <img
                      style={{
                        height: "150px",
                        width: "210px",
                        objectFit: "cover",
                        verticalAlign: "middle",
                      }}
                      src={res?.img}
                      class="casino_sprite casino-evolution"
                    />
                  </div>
                </div>
                <div class="casinoTitle">{res?.name}</div>
              </div>
            ) : (
              casinoTab == (game ? res?.live : res?.type) && (
                <div class="col col-4 col-md-3 col-sm-3 col-xl-3 pointer 11 ng-star-inserted"
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: res?.platForm,
                      gameType: res?.gameType,
                      casinoType: res?.casinoType,
                      isLobbyTrue: res?.isLobbyTrue,
                      table:res?.table
                    });
                  } else {
                    navigate("/login");
                  }
                }}>
                  <div style={{ position: "relative" }}>
                    <div class="clip"></div>
                    <div class="clip2">
                      <img
                        style={{
                          height: "150px",
                          width: "210px",
                          objectFit: "cover",
                          verticalAlign: "middle",
                        }}
                        src={res?.img}
                        class="casino_sprite casino-evolution"
                      />
                    </div>
                  </div>
                  <div class="casinoTitle">{res?.name}</div>
                </div>
              )
            );
          })}
        </div>
      </div>{" "}
    </div>
  );
};

export default CasinoSection;
