import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Table, Form, Button } from "react-bootstrap";
import { apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import moment from "moment";
import Layout from "./Layout";
import helpers from "../utils/helpers";
import obj, { headerData } from "../utils/constants";
import ReactPaginate from "react-paginate";
import { MyAccountLayout } from "./MyAccountLayout";
import NewObj from "../utils/helpers";
import { isEmpty, startCase } from "lodash";
import AuthContext from "../context/AuthContext";
const AccountStatement = () => {
  const { user } = useContext(AuthContext);
  let currentDate = moment(new Date()).format("YYYY-MM-DD");
  let previousDate = moment(new Date()).subtract(1, "day").format("YYYY-MM-DD");
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 50,
    fromPeriod: previousDate,
    toPeriod: currentDate,
  });
  const getData = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.transactionBetLogs,
      filter
    );
    if (status === 200) {
      if (response_users.success) {
        setData(response_users.results);
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <Layout>
      <MyAccountLayout
        heading={"Account Statement"}
        text={"Statement"}
        type="statement"
        isTable={true}
        isFilter={true}
        filter={filter}
        setFilter={setFilter}
        getData={getData}
      >
        <table className="table-bordered">
          <thead>
            <tr>
              <th>Date / Time</th> <th>Deposit</th> <th>Withdraw</th>
              <th>Balance</th>
              <th>Remarks</th>
              <th>From / To</th>
            </tr>
          </thead>
          <tbody>
            {data?.data?.length > 0 ? (
              data?.data?.map((item) => {
                return (
                  <tr>
                    <td>
                      {NewObj.dateFormat(item?.createdAt, user?.timeZone)}
                    </td>
                    <td>
                      {item?.transactionType === "credit" ? (
                        <span className="text-success">
                          {NewObj.currencyFormat(item?.amount)}
                        </span>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td>
                      {" "}
                      {item?.transactionType === "debit" ? (
                        <span className="text-danger">
                          {"(" + NewObj.currencyFormat(item?.amount) + ")"}
                        </span>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td>{NewObj.currencyFormat(item?.newBalance)}</td>
                    <td>{item?.remark || "-"}</td>
                    <td>
                      {item?.agentData?.username}{" "}
                      <span>
                        <i className="fas fa-arrow-right"> </i>
                      </span>{" "}
                      {item?.forBet != 0 || item?.forBet != "0"
                        ? "-->"
                        : `Agent ->${item?.userData?.username}`}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={6}>
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "8px",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    No Data!
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </MyAccountLayout>
    </Layout>
  );
};

export default AccountStatement;
