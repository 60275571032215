import React from "react";
import { useContext } from "react";
import AuthProvider from "../context/AuthContext";

const LoaderBet = () => {
  let { betLoader } = useContext(AuthProvider);
  return (
    <div
      className="loading-overlay-new"
      style={{ display: betLoader ? "flex" : "none" }}
      id="loading"
    >
      <div className="loading-wrap-new">
        <div className="loading-new">
          <div></div>
          <div></div>
        </div>
        <p>Loading...</p>
      </div>
    </div>
  );
};

export default LoaderBet;
