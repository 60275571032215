import React, { useState, useEffect, useContext } from "react";
import {
  Tabs,
  Tab,
  Accordion,
  OverlayTrigger,
  Form,
  Row,
  Col,
  Container,
  Popover,
  Button,
  Table,
} from "react-bootstrap";
import Rightbar from "../components/Rightbar";
import { apiGet } from "../utils/apiFetch";
import helpers from "../utils/helpers";
import apiPath from "../utils/apiPath";
import { useForm } from "react-hook-form";
import { isEmpty, startCase } from "lodash";
import AuthContext from "../context/AuthContext";
import BetSlipContext from "../context/BetSlipContext";
import OddsListing from "../components/OddsListing";
import { Link } from "react-router-dom";
import moment from "moment";
import io from "socket.io-client";
import Layout from "./Layout";
import InplayInner from "./InplayInner";
import CasinoSection from "./CasinoSection";

const Inplay = () => {
  const {
    formState: { errors },
  } = useForm({});
  const [getAllSports, setAllSports] = useState([]);
  let { handelAddMultiMarket, runApi } = useContext(BetSlipContext);
  let { user, loginUserDetailData, setInplaySocket, inplaySocket } =
    useContext(AuthContext);

  const fetchAllSport = async () => {
    let temp = user?.user?._id ? `?userId=${user?.user?._id}` : "";
    const { status, data: response_users } = await apiGet(
      apiPath.userInPlaySport + temp
    );
    if (status === 200) {
      if (response_users.success) {
        setAllSports(response_users.results);

        if (response_users?.results?.inPlay?.length > 0) {
          let oddList = [
            ...response_users?.results?.inPlay?.map((res) => {
              return res?.marketId;
            }),
            ...response_users?.results?.toDay?.map((res) => {
              return res?.marketId;
            }),
            ...response_users?.results?.tomorrow?.map((res) => {
              return res?.marketId;
            }),
          ];
          getOdds(oddList);
        }
      }
    }
  };

  useEffect(() => {
    fetchAllSport();
  }, []);

  useEffect(() => {
    fetchAllSport();
  }, [runApi]);

  const getOdds = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getMatchOdds + `?marketId=${id?.join(",")}&multi=true`
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results?.length > 0) {
          setAllSports((current) => {
            return {
              ...current,
              inPlay: current?.inPlay?.map((obj) => {
                let newObj = response_users?.results?.find((res) => {
                  return res?.mi == obj?.marketId;
                });
                if (obj?.marketId == newObj?.mi) {
                  return { ...obj, odds: newObj };
                } else {
                  return obj;
                }
              }),
              toDay: current?.toDay?.map((obj) => {
                let newObj = response_users?.results?.find((res) => {
                  return res?.mi == obj?.marketId;
                });
                if (obj?.marketId == newObj?.mi) {
                  return { ...obj, odds: newObj };
                } else {
                  return obj;
                }
              }),
              tomorrow: current?.tomorrow?.map((obj) => {
                let newObj = response_users?.results?.find((res) => {
                  return res?.mi == obj?.marketId;
                });
                if (obj?.marketId == newObj?.mi) {
                  return { ...obj, odds: newObj };
                } else {
                  return obj;
                }
              }),
            };
          });
        }
      }
    }
  };

  const [checkRefresh, setCheckRefresh] = useState(true);
  const [socketObj, setSocketObj] = useState(null);
  const [score, setScore] = useState([]);
  const getScore = (message) => {
    setScore(message);
  };
  const getSocket = () => {
    if (checkRefresh) {
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
      if (!isEmpty(inplaySocket)) {
        inplaySocket.disconnect();
      }
      setCheckRefresh(false);
      let newSocket = "";
      let randomId = user?.user?._id ? user?.user?._id : 112233;
      if (!isEmpty(user)) {
        newSocket = io(
          `${process.env.REACT_APP_API_BASE_URL2}?token=${randomId}&userType=front`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      } else {
        newSocket = io(
          `${process.env.REACT_APP_API_BASE_URL2}?user_id=${randomId}`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      }
      setSocketObj(newSocket);
      setInplaySocket(newSocket);
      newSocket.on("scoreAll", getScore);
      newSocket.on("disconnect", function () {
        setCheckRefresh(true);
      });
      return () => newSocket.close();
    }
  };

  useEffect(() => {
    if (checkRefresh) {
      getSocket();
    }
  }, [checkRefresh]);

  document.addEventListener("visibilitychange", function () {
    if (!document.hidden && !checkRefresh) {
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
      setCheckRefresh(true);
    } else {
      setCheckRefresh(false);
    }
  });

  return (
    <Layout>
      <div className="theme_tabs pt-3">
        <Tabs defaultActiveKey="inplay">
          <Tab eventKey="inplay" title="Inplay">
            <InplayInner
              getInPlaySports={getAllSports?.inPlay}
              loginUserDetailData={loginUserDetailData}
              handelAddMultiMarket={handelAddMultiMarket}
              score={score}
            />
          </Tab>

          <Tab eventKey="today" title="Today">
            <InplayInner
              getInPlaySports={getAllSports?.toDay}
              loginUserDetailData={loginUserDetailData}
              handelAddMultiMarket={handelAddMultiMarket}
              score={score}
            />
          </Tab>

          <Tab eventKey="tomorrow" title="Tomorrow">
            <InplayInner
              getInPlaySports={getAllSports?.tomorrow}
              loginUserDetailData={loginUserDetailData}
              handelAddMultiMarket={handelAddMultiMarket}
              score={score}
            />
          </Tab>
        </Tabs>
      </div>
      <CasinoSection />
    </Layout>
  );
};

export default Inplay;
let USDollar = new Intl.NumberFormat("en-US", {
  currency: "USD",
});
