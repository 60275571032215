import React from "react";
import Layout from "./Layout";
import { lotteryArray } from "../utils/constants";
import Image from "../assets/images/lotteryMatka.jfif";
const Lottery = () => {
  return (
    <Layout>
      <div className="game_banner">
        <img
          src="../assets/images/kv_tennis.jpg"
          className="w-100"
          alt="No_Image"
        />
      </div>

      <div className="game_table_main_wrapper">
        <div className="sports">
          <div className="main-con lottery-casino">
            <div className="row home">
              {lotteryArray?.map((res) => {
                return (
                  <div class="col col-4 col-md-3 col-sm-3 col-xl-3 pointer 11 ng-star-inserted">
                    <div class="position-relative">
                      <div class="clip"></div>
                      <div class="clip2">
                        <img
                          style={{
                            height: "100%",
                            width: "100%",
                            verticalAlign: "middle",
                          }}
                          src={Image}
                          class="casino_sprite casino-evolution"
                        />
                      </div>
                    </div>
                    <div class="casinoTitle">{res?.name}</div>
                  </div>
                );
              })}
            </div>
          </div>{" "}
        </div>
      </div>
    </Layout>
  );
};

export default Lottery;
