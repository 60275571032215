import React, { useState, useContext, useRef, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import AuthContext from "../context/AuthContext";
import { isEmpty } from "lodash";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { apiGet, apiPost } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import logoSkyexchange from "../assets/images/home/logo-bazz247.svg";
import Layout from "./Layout";
function Home() {
  let { user, handleShow, launchCasino, homeLoader } = useContext(AuthContext);
  const navigate = useNavigate();
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
  };
  const [data, setData] = useState([]);

  const getData = async () => {
    const { status, data } = await apiPost(apiPath.getBanner, {
      type: "home_top",
    });
    if (status == 200) {
      if (data?.success) {
        setData(data?.results?.banners);
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    
    <Layout>
          <Row className="gx-2">
            {/* <Col md={12} className="my-2">
              <Slider {...settings}>
                {data?.length > 0 &&
                  data?.map((item) => {
                    return (
                      <div>
                        <LazyLoadImage
                          src={
                            process.env.REACT_APP_API_BASE_URL +
                            item?.banner_path
                          }
                          style={{ width: "100%" }}
                          alt=""
                        />
                      </div>
                    );
                  })}
              </Slider>
            </Col> */}
            <Col md={6} className="mb-2">
              {" "}
              <a href="#" className="position-relative d-block games_main">
                <Link to="/inplay">
                  <img src="assets/images/home/banner_sports.jpg" />
                </Link>

                <div className="title">
                  <h2>Sports </h2>
                  <span>Play Now</span>
                </div>

                <dl id="onLiveBoard" className="on_live">
                  <dt>
                    <p className="live_icon">
                      <span></span> LIVE
                    </p>
                  </dt>

                  <dd id="onLiveCount_CRICKET">
                    <p>Cricket</p>
                    <span id="count">3</span>
                  </dd>

                  <dd id="onLiveCount_SOCCER">
                    <p>Soccer</p>
                    <span id="count">8</span>
                  </dd>

                  <dd id="onLiveCount_TENNIS">
                    <p>Tennis</p>
                    <span id="count">24</span>
                  </dd>

                  {/* <dd id="onLiveCount_E_SOCCER">
                    <p>E-Soccer</p>
                    <span id="count">2</span>
                  </dd>

                  <dd id="onLiveCount_KABADDI">
                    <p>Kabaddi</p>
                    <span id="count">0</span>
                  </dd> */}
                </dl>
              </a>
            </Col>
            <Col md={6} className="mb-2">
              {" "}
              <a href="#" className="position-relative d-block games_main">
                <img src="assets/images/home/banner_kabaddi.jpg" />
                <div className="title">
                  <h2>Kabaddi </h2>
                  <span>Play Now</span>
                </div>
              </a>
            </Col>
            <Col md={6} className="mb-2">
              {" "}
              <a href="#" className="position-relative d-block games_main">
                <img src="assets/images/home/banner_skyexchangeBlog.jpg" />
              </a>
            </Col>
            <Col md={6} className="mb-2">
              {" "}
              <Link
                href="#"
                className="position-relative d-block games_main"
                onClick={() =>
                  !isEmpty(user)
                    ? launchCasino("SABA", "VIRTUAL", "SABA-VIRTUAL-001")
                    : handleShow()
                }
              >
                <img src="assets/images/home/banner_virtualsports.jpg" />
                <div className="title">
                  <h2>Virtual Cricket</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col md={12} lg={6} className="mb-2">
              {" "}
              <Link
                href="#"
                className="position-relative d-block games_main"
                onClick={() =>
                  !isEmpty(user)
                    ? launchCasino("SEXYBCRT", "LIVE", "livecasino")
                    : handleShow()
                }
              >
                <img src="assets/images/home/banner_casino.jpg" />
                <div className="title">
                  <h2>Live Casino </h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col md={12} lg={6} className="mb-2">
              {" "}
              <Link
                href="#"
                className="position-relative d-block games_main"
                onClick={() =>
                  !isEmpty(user)
                    ? launchCasino("SEXYBCRT", "LIVE", "MX-LIVE-007")
                    : handleShow()
                }
              >
                <img src="assets/images/home/banner_casino.jpg" />
                <div className="title">
                  <h2>SEXY</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                href="#"
                className="position-relative d-block games_main"
                onClick={() =>
                  !isEmpty(user)
                    ? launchCasino("KINGMAKER", "TABLE", "KM-TABLE-038")
                    : handleShow()
                }
              >
                <img src="assets/images/home/banner_blackjack-half.jpg" />
                <div className="title">
                  <h2>Blackjack </h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                href="#"
                className="position-relative d-block games_main"
                onClick={() =>
                  !isEmpty(user) ? launchCasino("", "", "") : handleShow()
                }
              >
                <img src="assets/images/home/banner_supernowa-half.jpg" />
                <div className="title">
                  <h2>Supernowa</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                href="#"
                className="position-relative d-block games_main"
                onClick={
                  () =>
                    !isEmpty(user) ? launchCasino("", "", "") : handleShow()
                  // launchCasino("SEXYBCRT", "LIVE", "7mojos")
                }
              >
                <img src="assets/images/home/banner_7mojos-half.jpg" />
                <div className="title">
                  <h2>7mojos</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                href="#"
                className="position-relative d-block games_main"
                onClick={() =>
                  !isEmpty(user) ? launchCasino("", "", "") : handleShow()
                }
              >
                <img src="assets/images/home/banner_horsebook-half.jpg" />
                <div className="title">
                  <h2>Supernowa</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() =>
                  !isEmpty(user)
                    ? launchCasino("KINGMAKER", "TABLE", "KM-TABLE-042")
                    : handleShow()
                }
                href="#"
                className="position-relative d-block games_main"
              >
                <img src="assets/images/home/banner_minesweeper-half.jpg" />
                <div className="title">
                  <h2>Minesweeper</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                href="#"
                className="position-relative d-block games_main"
                onClick={() =>
                  !isEmpty(user) ? launchCasino("", "", "") : handleShow()
                }
              >
                <img src="assets/images/home/banner_skytrader-half.jpg" />
                <div className="title">
                  <h2>Skytrader</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() =>
                  !isEmpty(user) ? launchCasino("", "", "") : handleShow()
                }
                href="#"
                className="position-relative d-block games_main"
              >
                <img src="assets/images/home/banner_32card-half.jpg" />
                <div className="title">
                  <h2>32card</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() =>
                  !isEmpty(user)
                    ? launchCasino("JILI", "TABLE", "JILI-TABLE-004")
                    : handleShow()
                }
                href="#"
                className="position-relative d-block games_main"
              >
                <img src="assets/images/home/banner_rummy-half.jpg" />
                <div className="title">
                  <h2>Rummy</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() =>
                  !isEmpty(user) ? launchCasino("", "", "") : handleShow()
                }
                href="#"
                className="position-relative d-block games_main"
              >
                <img src="assets/images/home/banner_betgames-half.jpg" />
                <div className="title">
                  <h2>Betgames</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() =>
                  !isEmpty(user)
                    ? launchCasino("PP", "LIVE", "PP-LIVE-043")
                    : handleShow()
                }
                href="#"
                className="position-relative d-block games_main"
              >
                <img src="assets/images/home/banner_andarBahar-half.jpg" />
                <div className="title">
                  <h2>Andar Bahar</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() =>
                  !isEmpty(user)
                    ? launchCasino("SEXYBCRT", "LIVE", "MX-LIVE-007")
                    : handleShow()
                }
                href="#"
                className="position-relative d-block games_main"
              >
                <img src="assets/images/home/banner_sicbo-half.jpg" />
                <div className="title">
                  <h2>Sicbo</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() =>
                  !isEmpty(user)
                    ? launchCasino("KINGMAKER", "TABLE", "KM-TABLE-028")
                    : handleShow()
                }
                href="#"
                className="position-relative d-block games_main"
              >
                <img src="assets/images/home/banner_sevenUpDown-half.jpg" />
                <div className="title">
                  <h2>7 Up 7 Down</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() =>
                  !isEmpty(user)
                    ? launchCasino("KINGMAKER", "TABLE", "KM-TABLE-036")
                    : handleShow()
                }
                href="#"
                className="position-relative d-block games_main"
              >
                <img src="assets/images/home/banner_CoinToss-half.jpg" />
                <div className="title">
                  <h2>Coin Toss</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() =>
                  !isEmpty(user)
                    ? launchCasino("KINGMAKER", "TABLE", "KM-TABLE-046")
                    : handleShow()
                }
                href="#"
                className="position-relative d-block games_main"
              >
                <img src="assets/images/home/banner_teenPatti-half.jpg" />
                <div className="title">
                  <h2>Teen Patti</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() =>
                  !isEmpty(user)
                    ? launchCasino("KINGMAKER", "TABLE", "KM-TABLE-022")
                    : handleShow()
                }
                href="#"
                className="position-relative d-block games_main"
              >
                <img src="assets/images/home/banner_cardMatka-half.jpg" />
                <div className="title">
                  <h2>Card Matka</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() =>
                  !isEmpty(user)
                    ? launchCasino("KINGMAKER", "TABLE", "KM-TABLE-021")
                    : handleShow()
                }
                href="#"
                className="position-relative d-block games_main"
              >
                <img src="assets/images/home/banner_numberMatka-half.jpg" />
                <div className="title">
                  <h2>Number Matka</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() =>
                  !isEmpty(user) ? launchCasino("", "", "") : handleShow()
                }
                href="#"
                className="position-relative d-block games_main"
              >
                <img src="assets/images/home/banner_binary-half.jpg" />
                <div className="title">
                  <h2>Binary</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() =>
                  !isEmpty(user) ? launchCasino("", "", "") : handleShow()
                }
                href="#"
                className="position-relative d-block games_main"
              >
                <img src="assets/images/home/banner_bpoker-half.jpg" />
                <div className="title">
                  <h2>Bpoker</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>

            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: "203",
                      gameType: "1",
                      casinoType: "LIVE CASINO",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/livecas.webp"} />
                <div className="title">
                  <h2>Ezugi Casino</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: "1006",
                      gameType: "1",
                      casinoType: "LIVE CASINO",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/evolutiongaming.webp"} />
                <div className="title">
                  <h2>Evolution Casino</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: "PP",
                      gameType: "",
                      casinoType: "",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/pragmatic.webp"} />
                <div className="title">
                  <h2>Pragmatic Play</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: "SPADE",
                      gameType: "",
                      casinoType: "",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/spade.webp"} />
                <div className="title">
                  <h2>Spade Gaming</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: "SPADE",
                      gameType: "",
                      casinoType: "",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/superspade.webp"} />
                <div className="title">
                  <h2>Super Spade</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: "JDB",
                      gameType: "SLOT",
                      casinoType: "JDB-SLOT-001",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/onetouchlive.webp"} />
                <div className="title">
                  <h2>Bombay Live</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: "FC",
                      gameType: "SLOT",
                      casinoType: "",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/fachaigaming.webp"} />
                <div className="title">
                  <h2>Fc Gaming</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: "RT",
                      gameType: "SLOT",
                      casinoType: "",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/redtigergaming.webp"} />
                <div className="title">
                  <h2>Red Tiger</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: "VENUS",
                      gameType: "LIVE",
                      casinoType: "VN-LIVE-001",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/venuscasino.webp"} />
                <div className="title">
                  <h2>Venus Casino</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: "YL",
                      gameType: "",
                      casinoType: "",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/ylgamin.webp"} />
                <div className="title">
                  <h2>Yl Gaming</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: "JDB",
                      gameType: "SLOT",
                      casinoType: "",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/jdbgaming.webp"} />
                <div className="title">
                  <h2>Jdb Gaming</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: "KINGMAKER",
                      gameType: "TABLE",
                      casinoType: "KM-TABLE-036",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/cointoss.webp"} />
                <div className="title">
                  <h2>Cointoss</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: "KINGMAKER",
                      gameType: "TABLE",
                      casinoType: "KM-TABLE-021",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/7d.webp"} />
                <div className="title">
                  <h2>Number Matka</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: "KINGMAKER",
                      gameType: "TABLE",
                      casinoType: "KM-TABLE-043",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/bonus-dice.webp"} />
                <div className="title">
                  <h2>Bonus Dice</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>

            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: "KINGMAKER",
                      gameType: "TABLE",
                      casinoType: "KM-TABLE-047",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/heist.webp"} />
                <div className="title">
                  <h2>Heist</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: "KINGMAKER",
                      gameType: "TABLE",
                      casinoType: "KM-TABLE-050",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/color.webp"} />
                <div className="title">
                  <h2>Color Game</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: "KINGMAKER",
                      gameType: "TABLE",
                      casinoType: "KM-TABLE-042",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/mine.webp"} />
                <div className="title">
                  <h2>Minesweeper</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: "JILI",
                      gameType: "SLOT",
                      casinoType: "JILI-SLOT-029",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/money-coming.webp"} />
                <div className="title">
                  <h2>Money Coming</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: "JILI",
                      gameType: "TABLE",
                      casinoType: "JILI-TABLE-013",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/jilicallquick.webp"} />
                <div className="title">
                  <h2>Callbreak Quick</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: "JILI",
                      gameType: "SLOT",
                      casinoType: "JILI-SLOT-006",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/jililuckyball.webp"} />
                <div className="title">
                  <h2>Lucky Ball</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>

            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: "JILI",
                      gameType: "TABLE",
                      casinoType: "JILI-TABLE-021",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/jililudoquick.webp"} />
                <div className="title">
                  <h2>Ludo Quick</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>

            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: "JILI",
                      gameType: "TABLE",
                      casinoType: "JILI-TABLE-005",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/jilinumberking.webp"} />
                <div className="title">
                  <h2>NumberKing</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: "JILI",
                      gameType: "TABLE",
                      casinoType: "JILI-TABLE-010",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/jilitpjoker.webp"} />
                <div className="title">
                  <h2>Joker</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: "JILI",
                      gameType: "TABLE",
                      casinoType: "JILI-TABLE-002",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/ak47.webp"} />
                <div className="title">
                  <h2>Ak47</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: "JILI",
                      gameType: "FH",
                      casinoType: "JILI-FISH-007",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/mega-fishing.webp"} />
                <div className="title">
                  <h2>Mega Fishing</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: "JILI",
                      gameType: "FH",
                      casinoType: "JILI-FISH-005",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/happy-fishing.webp"} />
                <div className="title">
                  <h2>Happy Fishing</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: "JILI",
                      gameType: "FH",
                      casinoType: "JILI-FISH-003",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/jackpotfish.webp"} />
                <div className="title">
                  <h2>Jackpot Fishing</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: "JILI",
                      gameType: "FH",
                      casinoType: "JILI-FISH-009",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/all-starfishing.webp"} />
                <div className="title">
                  <h2>All Star Fishing</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: "PP",
                      gameType: "LIVE",
                      casinoType: "PP-LIVE-028",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/pamericanroll.webp"} />
                <div className="title">
                  <h2>American Roulette</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>

            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: "1006",
                      gameType: "1",
                      casinoType: "LIVE CASINO",
                      gameid: "CrazyTime0000001",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/pcrazycoin.webp"} />
                <div className="title">
                  <h2>Crazy Coin</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>

            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: "1006",
                      gameType: "1",
                      casinoType: "LIVE CASINO",
                      gameid: "dealnodeal000001",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/pdealornodeal.webp"} />
                <div className="title">
                  <h2>Deal</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: "PP",
                      gameType: "LIVE",
                      casinoType: "PP-LIVE-039",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/pmegaroll.webp"} />
                <div className="title">
                  <h2>Mega Roulette</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
                    launchCasino({
                      platForm: "KINGMAKER",
                      gameType: "TABLE",
                      casinoType: "KM-TABLE-041",
                    });
                  } else {
                    navigate("/login");
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/rocketcash.webp"} />
                <div className="title">
                  <h2>Rocket Cash</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
          </Row>
     
        <footer class="cls_footer_new">
          <div class="footer-top">
            <div class="container">
              <div class="row">
                <div
                  id="policies"
                  class="col-md-456 col-lg-12 footer-about wow fadeInUp"
                >
                  <div class="row">
                    <div class="column">
                      <LazyLoadImage
                        class="logo-footer top-logo"
                        src={logoSkyexchange}
                        alt="logo-footer"
                      />
                    </div>
                    <div class="column">
                      <LazyLoadImage
                        id="legal-image"
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          "/assets/images/LEGAL.png"
                        }
                        alt="logo-footer"
                      />
                    </div>
                    <div class="column">
                      <div class="appdl-link-android" id="appDesign">
                        <a id="appLinks" href="#">
                          <LazyLoadImage
                            src={
                              process.env.REACT_APP_IMAGE_URL +
                              "/assets/images/home/btn-appdl-android.png"
                            }
                            style={{ height: `60px` }}
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <section class="joinNow">
                    <div id="supportWrap" class="support-wrap">
                      <div class="support-service">
                        <a id="support_email" class="support-mail">
                          <LazyLoadImage
                            src={
                              process.env.REACT_APP_IMAGE_URL +
                              "/assets/images/home/transparent.gif"
                            }
                            title="Email"
                          />
                        </a>
                        <a id="support_whatsapp" class="support-whatsapp open">
                          <LazyLoadImage
                            src={
                              process.env.REACT_APP_IMAGE_URL +
                              "/assets/images/home/transparent.gif"
                            }
                            title="WhatsApp"
                          />
                        </a>
                        <a id="support_telegram" class="support-telegram">
                          <LazyLoadImage
                            src={
                              process.env.REACT_APP_IMAGE_URL +
                              "/assets/images/home/transparent.gif"
                            }
                            title="Telegram"
                          />
                        </a>
                        <a id="support_skype" class="support-skype">
                          <LazyLoadImage
                            src={
                              process.env.REACT_APP_IMAGE_URL +
                              "/assets/images/home/transparent.gif"
                            }
                            title="Skype"
                          />
                        </a>
                        <a id="support_instagram" class="support-ig">
                          <LazyLoadImage
                            src={
                              process.env.REACT_APP_IMAGE_URL +
                              "/assets/images/home/transparent.gif"
                            }
                            title="Instagram"
                          />
                        </a>
                        <a id="support_facebook" class="support-fb">
                          <LazyLoadImage
                            src={
                              process.env.REACT_APP_IMAGE_URL +
                              "/assets/images/home/transparent.gif"
                            }
                            title="Facebook"
                          />
                        </a>
                      </div>
                      <div class="support-info">
                        <div id="supportDetail_email" class="support-detail">
                          <a
                            id="emailContent"
                            target="_blank"
                            href="mailto:"
                          ></a>
                        </div>
                        <div
                          id="supportDetail_whatsapp"
                          class="support-detail open"
                        >
                          <a
                            id="whatsappContent"
                            target="_blank"
                            href="http://wa.me/"
                          ></a>
                        </div>
                        <div id="supportDetail_telegram" class="support-detail">
                          <a
                            id="telegramContent"
                            target="_blank"
                            href="https://t.me/"
                          ></a>
                        </div>
                        <div id="supportDetail_skype" class="support-detail">
                          <a
                            id="skypeContent"
                            target="_blank"
                            href="skype:?chat"
                          ></a>
                        </div>
                        <div
                          id="supportDetail_instagram"
                          class="support-detail"
                        >
                          <a
                            id="intagramContent"
                            class="ui-link"
                            target="_blank"
                          >
                            <span></span>
                          </a>
                        </div>
                        <div id="supportDetail_facebook" class="support-detail">
                          <a
                            id="facebookContent"
                            class="ui-link"
                            target="_blank"
                            href="https://www.facebook.com/"
                          ></a>
                        </div>
                      </div>
                    </div>

                    <h6 class="jnHeading">Join Now</h6>
                    <div class="joinNowList">
                      <div class="joinNowItem">
                        <LazyLoadImage
                          src={
                            process.env.REACT_APP_IMAGE_URL +
                            "/assets/images/withdrawInstant.svg"
                          }
                          alt=""
                        />
                        <span>
                          Instant Withdrawals <br /> &amp; Deposits
                        </span>
                      </div>
                      <div class="joinNowItem">
                        <LazyLoadImage
                          src={
                            process.env.REACT_APP_IMAGE_URL +
                            "/assets/images/customerSupport.svg"
                          }
                          alt=""
                        />
                        <span>
                          24/7 Customer <br /> Service
                        </span>
                      </div>
                      <div class="joinNowItem">
                        <LazyLoadImage
                          src={
                            process.env.REACT_APP_IMAGE_URL +
                            "/assets/images/p2p.svg"
                          }
                          alt=""
                        />
                        <span>
                          Player to Player <br /> Transfer
                        </span>
                      </div>
                      <div class="joinNowItem">
                        <LazyLoadImage
                          src={
                            process.env.REACT_APP_IMAGE_URL +
                            "/assets/images/liveStream.svg"
                          }
                          alt=""
                        />
                        <span>
                          Live Stream of <br /> Events
                        </span>
                      </div>
                      <div class="joinNowItem">
                        <LazyLoadImage
                          src={
                            process.env.REACT_APP_IMAGE_URL +
                            "/assets/images/referralBonus.svg"
                          }
                          alt=""
                        />
                        <span>
                          300 Rupees <br /> Referral Bonus
                        </span>
                      </div>
                    </div>
                  </section>
                  <p></p>
                  <div class="footercolumn policy-link">
                    <p class="footer-links footer-policy">
                      <a href="javascript:void(0)" class="ui-link">
                        Privacy Policy
                      </a>
                    </p>
                    <p class="footer-links footer-rar">
                      <a class="ui-link">Rules and Regulations</a>
                    </p>
                    <p class="footer-links footer-kyc">
                      <a
                        href="javascript:MobileLoginHandler.showOverlayInfo('#footer-kyc');c"
                        class="ui-link"
                      >
                        KYC
                      </a>
                    </p>
                    <p class="footer-links footer-rg">
                      <a class="ui-link">Responsible Gaming</a>
                    </p>
                    <p class="footer-links footer-gtc">
                      <a class="ui-link">Terms and Conditions</a>
                    </p>
                    <p class="footer-links">
                      <a class="ui-link">About Us</a>
                    </p>
                    <p class="footer-links">
                      <a class="ui-link">Self-Exclusion Policy</a>
                    </p>
                    <p class="footer-links">
                      <a class="ui-link">Underage Policy</a>
                    </p>
                  </div>
                </div>

                <p class="footer-text">
                  <i></i> velkiix.bet is the world's leading online sports
                  betting and casino gaming platform that aims at offering an
                  authentic, immersive and seamless gaming experience to its
                  players. There are more than 30 sports to choose from in total
                  for EXCHANGE, which include cricket (IPL, CPL, PSL, World
                  Cup), soccer, tennis and many more. Players can play LIVE
                  CASINO on velkiix.bet with LIVE DEALERS dealing all games
                  including Roulette, Blackjack, Teen Patti, Andar Bahar, Poker,
                  Slots and more. velkiix.bet is a trusted casino and offers
                  fast deposits and withdrawals with round the clock support on
                  email and live chat. All new players at velkiix.bet are
                  offered a generous welcome bonus on their first deposit!
                  Welcome to the The King of Online Betting &amp; Casino,
                  bazz247.com !
                </p>
                <hr />
                <marquee class="marquee">
                  <ul class="marquee-content">
                    <li class="marquee-item">
                      <LazyLoadImage
                        class="marquee-image"
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          "/assets/images/bkash.png"
                        }
                        alt="payment option"
                      />
                    </li>
                    <li class="marquee-item">
                      <LazyLoadImage
                        class="marquee-image"
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          "/assets/images/nagad.png"
                        }
                        alt="payment option"
                      />
                    </li>
                    <li class="marquee-item">
                      <LazyLoadImage
                        class="marquee-image"
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          "/assets/images/upay.png"
                        }
                        alt="payment option"
                      />
                    </li>
                    <li class="marquee-item">
                      <LazyLoadImage
                        class="marquee-image"
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          "/assets/images/rocket.png"
                        }
                        alt="payment option"
                      />
                    </li>
                    <li class="marquee-item">
                      <LazyLoadImage
                        class="marquee-image"
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          "/assets/images/bkash.png"
                        }
                        alt="payment option"
                      />
                    </li>
                    <li class="marquee-item">
                      <LazyLoadImage
                        class="marquee-image"
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          "/assets/images/nagad.png"
                        }
                        alt="payment option"
                      />
                    </li>
                    <li class="marquee-item">
                      <LazyLoadImage
                        class="marquee-image"
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          "/assets/images/upay.png"
                        }
                        alt="payment option"
                      />
                    </li>
                    <li class="marquee-item">
                      <LazyLoadImage
                        class="marquee-image"
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          "/assets/images/rocket.png"
                        }
                        alt="payment option"
                      />
                    </li>
                    <li class="marquee-item">
                      <LazyLoadImage
                        class="marquee-image"
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          "/assets/images/bkash.png"
                        }
                        alt="payment option"
                      />
                    </li>
                    <li class="marquee-item">
                      <LazyLoadImage
                        class="marquee-image"
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          "/assets/images/nagad.png"
                        }
                        alt="payment option"
                      />
                    </li>
                    <li class="marquee-item">
                      <LazyLoadImage
                        class="marquee-image"
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          "/assets/images/upay.png"
                        }
                        alt="payment option"
                      />
                    </li>
                    <li class="marquee-item">
                      <LazyLoadImage
                        class="marquee-image"
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          "/assets/images/rocket.png"
                        }
                        alt="payment option"
                      />
                    </li>
                    <li class="marquee-item">
                      <LazyLoadImage
                        class="marquee-image"
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          "/assets/images/bkash.png"
                        }
                        alt="payment option"
                      />
                    </li>
                    <li class="marquee-item">
                      <LazyLoadImage
                        class="marquee-image"
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          "/assets/images/nagad.png"
                        }
                        alt="payment option"
                      />
                    </li>
                    <li class="marquee-item">
                      <LazyLoadImage
                        class="marquee-image"
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          "/assets/images/upay.png"
                        }
                        alt="payment option"
                      />
                    </li>
                    <li class="marquee-item">
                      <LazyLoadImage
                        class="marquee-image"
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          "/assets/images/rocket.png"
                        }
                        alt="payment option"
                      />
                    </li>
                    <li class="marquee-item">
                      <LazyLoadImage
                        class="marquee-image"
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          "/assets/images/bkash.png"
                        }
                        alt="payment option"
                      />
                    </li>
                    <li class="marquee-item">
                      <LazyLoadImage
                        class="marquee-image"
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          "/assets/images/nagad.png"
                        }
                        alt="payment option"
                      />
                    </li>
                    <li class="marquee-item">
                      <LazyLoadImage
                        class="marquee-image"
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          "/assets/images/upay.png"
                        }
                        alt="payment option"
                      />
                    </li>
                    <li class="marquee-item">
                      <LazyLoadImage
                        class="marquee-image"
                        src={
                          process.env.REACT_APP_IMAGE_URL +
                          "/assets/images/rocket.png"
                        }
                        alt="payment option"
                      />
                    </li>
                  </ul>
                </marquee>
              </div>
            </div>
          </div>
        </footer>
        </Layout>
  );
}

export default Home;
